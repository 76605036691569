
@import url("https://use.typekit.net/xum8unz.css");

@font-face {
  font-family: 'feral-icons';
  src: url('/css/font/feral-icons.eot?37613535');
  src: url('/css/font/feral-icons.eot?37613535#iefix') format('embedded-opentype'),
       url('/css/font/feral-icons.woff2?37613535') format('woff2'),
       url('/css/font/feral-icons.woff?37613535') format('woff'),
       url('/css/font/feral-icons.ttf?37613535') format('truetype'),
       url('/css/font/feral-icons.svg?37613535#feral-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'feral-icons';
    src: url('../font/feral-icons.svg?9682103#feral-icons') format('svg');
  }
}
*/
 
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "feral-icons";
  font-style: normal;
  font-weight: 400;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .15em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .15em;
 
  /* you can be more comfortable with increased icons size */
  font-size: 120%;
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-settings:before { content: '\e800'; } /* '' */
.icon-basket:before { content: '\e803'; } /* '' */
.icon-search:before { content: '\e802'; } /* '' */
.icon-linux:before { content: '\e806'; } /* '' */
.icon-switch:before { content: '\e808'; } /* '' */
.icon-ios:before { content: '\e809'; } /* '' */
.icon-ipad:before { content: '\e80a'; } /* '' */
.icon-iphone:before { content: '\e80b'; } /* '' */
//.icon-linux-reverse:before { content: '\e80c'; } /* '' */ this should be css specified rather then different html class
.icon-mac:before { content: '\e80d'; } /* '' */
.icon-android:before { content: '\e80e'; } /* '' */
.icon-windows:before { content: '\e80f'; } /* '' */
.icon-tvos:before { content: '\e810'; } /* '' */
.icon-sound-on:before { content: '\e811'; } /* '' */
.icon-sound-off:before { content: '\e812'; } /* '' */
.icon-close:before { content: '\e813'; } /* '' */
.icon-menu:before { content: '\e814'; } /* '' */
.icon-menu-thin-smaller:before { content: '\e816'; } /* '' */
.icon-menu-thin:before { content: '\e817'; } /* '' */
.icon-meta-quest:before { content: '\e829'; } /* '' */





@import url('https://fonts.googleapis.com/css?family=Michroma');

/* CoH iOS device list */

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap&subset=cyrillic");

@font-face {
  font-family: 'Gotham';
  src: url("/minisite/companyofheroes-mobile/fonts/Gotham-Bold.woff2") format("woff2"), url("/minisite/companyofheroes-mobile/fonts/Gotham-Bold.woff") format("woff"), url("/minisite/companyofheroes-mobile/fonts/Gotham-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Gotham';
  src: url("/minisite/companyofheroes-mobile/fonts/Gotham-Book.woff2") format("woff2"), url("/minisite/companyofheroes-mobile/fonts/Gotham-Book.woff") format("woff"), url("/minisite/companyofheroes-mobile/fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
/* end */

/* XCOM2 iOS device list */
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700&subset=latin,cyrillic,cyrillic-ext');
@font-face {
    font-family: 'Dharma Gothic E';
    src: url('/minisite/xcom2collection-mobile/fonts/DharmaGothicE-Regular.woff2') format('woff2'),
        url('/minisite/xcom2collection-mobile/fonts/DharmaGothicE-Regular.woff') format('woff'),
        url('/minisite/xcom2collection-mobile/fonts/DharmaGothicE-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
/* end */

/* tropico device news font */
@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700|Pangolin|Reenie+Beanie|Special+Elite&subset=cyrillic');
/* end */