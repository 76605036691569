
#top-header {
	min-height: 60vh;
}
@include media-breakpoint-up(md) {
	#top-header {
		min-height: 67.8vh;
	}
}
#top-feature {
	.price {
		display: none;
	}
}
section {
	header {
		padding: 10px;
	
		h3 {
			margin: 0px;
			text-align: center;
			text-transform: uppercase;
			font-size: 1.3rem;
			text-shadow: $small-black-text-shadow;
		}
	}
	
	footer {
		padding: 10px;
		width: 100%;
		text-align: center;
		position: relative;
		z-index: 9;
		
		.btn {
			font-size: 1.2rem;
			font-weight: 500;
			padding-left: 30px;
		}
	}
}

@include media-breakpoint-up(md) {
	section {
		header {
			h3 {
				margin: 1.6rem 0 1rem;
				text-align: center;
				text-transform: uppercase;
				font-size: 1.8rem;
			}
		}
		footer {
			margin-left: auto;
			margin-top: 3vw;
			margin-bottom: 2vw;
			width: auto;
			background-image: url("/images/backgrounds/triangles/side-button.svg");
			background-repeat: no-repeat;
			background-position: center left;
			background-size: cover;
			padding: 10px 30px 10px 10px;
			.btn {
				font-size: 1.6vw;
			}
		}
		.no-gutters {
			footer {
				margin-right: -30px;
			}
		}
	}
	
	#top-feature-details h5 {
// 		padding-right: 3vw;
	}
}

@include media-breakpoint-up(xl) {
	section {
		footer {
			margin-top: 2vw;
			margin-bottom: 1vw;
		}
	}
}

#highlighted-games {
	
	.row {
		@include fade-in-up(1.5s, 0);
	}
	
	// Removing annoying listings from stallman — remove the game code from here if we ever want to list these
	
	.gridautosport-tvos, .lisbeforethestorm {
		display: none;
	}
	
	//
	
	.dlc, .expansion {
		.panel {
			.dlc-note {
				position: absolute;
				left: 0;
				top: 7%;
				width: auto;
				height: auto;
				padding: .5em 1.1em .45em .65em;
				font-weight: 700;
				background-image: url('/images/backgrounds/triangles/dlc-frame.svg');
				background-position: right center;
				background-size: cover;
				background-repeat: no-repeat;
				z-index: 1;
				text-transform: uppercase;
				text-align: left;
				span {
					display: inline-block;
// 					transition: font-size .2s linear, opacity .2s linear;
					font-size: .8rem;
					position: relative;
					top: -.1em;
				}
				em, strong {
					opacity: 0;
					font-size: .05rem;
					width: 0;
					height: 0;
					display: inline-block;
					overflow: hidden;
					transition: font-size .2s linear, opacity .2s linear;
				}
				em {
// 					font-size: .4em;
					font-weight: 400;
					font-style: normal;
				}
			}
			
			&:hover {
				.dlc-note {
					span {
						opacity: 0;
/*
						font-size: .5rem;
						width: 0;
						height: 0;
*/
						overflow: hidden;
						display: none;
					}
					em, strong {
						opacity: 1;
						font-size: .8rem;
						width: auto;
						height: auto;
						display: inline-block;
					}
					em {
						display: block;
						font-size: .6rem;
					}
				}
			}
/*
			&::before {
				content: 'DLC';
				position: absolute;
				right: 0;
				top: 7%;
				width: auto;
				height: auto;
				padding: .5em .55em .45em 1.2em;
				font-weight: 700;
				background-image: url('/images/backgrounds/triangles/dlc-frame.svg');
				background-position: left center;
				background-size: cover;
				background-repeat: no-repeat;
				z-index: 1;
				text-transform: uppercase;
			}
			
			&:hover::before {
				content: attr(data-basegame);
				padding-left: 2em;
				max-width: 95%;
			}
*/
		}
	}
	
	.expansion {
		.panel {
			&::before {
				content: 'Expansion';
			}
		}
	}
	
	.panel-holder:nth-of-type(1)  {
		
	}
	
	.panel-holder:nth-of-type(2) {
		
	}
	
	.panel-holder:nth-of-type(3) {
		
	}
	
	.panel-holder:nth-of-type(4) {
				
		display: none;
		@include media-breakpoint-up(xl) {
			display: block;
		}
		
		
	}
	
	.panel {
		overflow: hidden;
		position: relative;
	}
	
	.panel-body {
		position: relative;
		display: flex;
		align-items: flex-end;
		min-height: 25vh;
		padding: 10px 10px 0px;
		background-size: cover;
		background-position: center center;
		
		&:before {
			display: block;
			content: '';
			position: absolute;
			bottom: 0px; left: 0px;
			background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.6) 20%, rgba(0,0,0,.85) 50%, rgba(0,0,0,1));
			background-repeat: no-repeat;
			width: 100%; height: 39%;
			z-index: 0;
		}
		
		h4 {
			position: relative;
			font-size: 1rem;
			font-weight: normal;
			margin-top: auto;
			margin-right: auto;
			text-shadow: $small-black-text-shadow;
		}
		
		h5 {
			position: relative;
			white-space: nowrap;
			text-shadow: $small-black-text-shadow;
			span {
				display: inline-block;
				&::before {
					font-size: 1.4em;
					margin: 0 .1em;
				}
				&.icon-ios::before, &.icon-android::before {
					position: relative;
					bottom: -.12em;
				}
				&:first-child {
					display: none;
				}
				&.icon-mac {
					margin-right: 3px;
				}
			}
		}
	}
	
	.panel-buttons {
		display: flex;
		padding: 5px 10px 10px;
		background-color: #000;
		
		.btn {
			display: flex;
			flex: 1 1 50%;
			justify-content: center;
			align-items: center;
			&:nth-child(2) {
				margin-left: 4px;
			}
		}
	}
}

		

@include media-breakpoint-up(md) {
	#highlighted-games {
		.row {
			@include fade-in-up(2s, 2s);
		}
		.panel {
			margin: 10px;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			background-color: rgba(0,0,0,0.7);
			
				
			&:after {
				position: absolute;
				display: block;
				content: '';
				background-color: #181818;
				background-color: #414042;
				top: 0px; left: 0px;
				bottom: 0px; right: 0px;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				z-index: -1;
			}
			
			.panel-body {
				flex-direction: column;
				align-items: flex-start;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
// 				min-height: 25vw;
				min-height: calc(25vw - 17px);
				padding: 10px 15px 5px;
				box-shadow: inset 0px 0px 15px 7px rgba(0, 0, 0, .9);
				h4 {
					color: $feral-orange;
					font-size: 2vw;
				}
				
				h5 {
					font-size: 1.6vw;
					span {
						display: inline-block;
						font-weight: normal;
						font-size: 1.2em;
						&:not(:last-child) {
							font-size: 1em;
							margin-right: .3em;
						}
						&.icon-mac {
							margin-right: .15em;
						}
					}
				}
				
				&:after {
					position: absolute;
					display: block;
					content: '';
					background-color: #000;
					background-color: #1a1a1a;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
					clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				}
			}
			
			.panel-buttons {
				background: none;
				padding-top: 9px;
				.btn {
					font-size: 1.4vw;
					&:nth-child(2) {
// 						margin-left: .5vw;
					}
				}
			}
			
		}
			
	}
}

@include media-breakpoint-up(xl) {
	#highlighted-games .panel {
		
		.panel-body {
			min-height: 19vw;
			h4 {
				font-size: 1.5vw;
			}
			h5 {
				font-size: 1.2vw;
			}
		}
		.panel-buttons {
			.btn {
				font-size: 1.2vw;
			}
		}
	}
}

#latest-news {
	.panel {
		margin: 0px 10px 10px;
		border: solid 1px #414042;
		
		.panel-body {
			position: relative;
			display: block;
			background-size: cover;
			color: #FFF;
			padding: 1.5em .2em;
			display: flex;
			font-size: 3vw;
			text-shadow: $small-black-text-shadow;
			overflow: hidden;
			box-shadow: inset 0px 0px 30px 10px rgba(0, 0, 0, 1);
			border: 1px solid black;
			transition: border .2s linear;
			z-index: 0;
			
			&:before {
				display: block;
				content: '';
				position: absolute;
				top: 0px; left: 0;
//				@include gradient-x(rgba(0,0,0,1), rgba(0,0,0,.7), 26%, 50%);
//				@include gradient-x(white, white, 55%, 100%); // remove
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0.8+38,0.7+42,0.5+60,0.1+83 */
background: -moz-linear-gradient(-45deg,  rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 38%, rgba(0,0,0,0.7) 42%, rgba(0,0,0,0.5) 60%, rgba(0,0,0,0.1) 83%, rgba(0,0,0,0.1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.8) 38%,rgba(0,0,0,0.7) 42%,rgba(0,0,0,0.5) 60%,rgba(0,0,0,0.1) 83%,rgba(0,0,0,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  rgba(0,0,0,1) 0%,rgba(0,0,0,0.8) 38%,rgba(0,0,0,0.7) 42%,rgba(0,0,0,0.5) 60%,rgba(0,0,0,0.1) 83%,rgba(0,0,0,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#1a000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */



				width: 200%; height: 100%;
				z-index: 0;
				transition: opacity .2s linear, left .2s linear, box-shadow .2s linear;
				opacity: .8;
				pointer-events: none;
			}
			
			&:hover {
				text-decoration: none;
// 				box-shadow: inset 0px 0px 20px 10px rgba(0,0,0,1);
				border: 1px solid $feral-orange;
				&:before {
					opacity: 1;
					left: -30%;
				}
			}
			
			.home-article-image {
				position: absolute;
				width: 100%;
// 				top: 50%;
				height: auto;
				margin: -21% auto -100%;
				z-index: -1;
			}
			
			h4, aside {
				position: relative;
			}
			
			h4 {
				font-size: 1.7em;
				font-weight: 300;
				margin: 0px;
				align-self: center;
			}
			
			aside {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				text-align: right;
// 				margin-left: 10px;
				
				.platforms {
					width: 100%;
					margin-bottom: auto;
					font-size: 1.8em;
					display: flex;
					justify-content: flex-end;
					flex-wrap: wrap;
					> span {
// 						margin-left: 5px;
// 						&:last-child {
							&::before {
								margin-right: 0;
								margin-left: .3em;
							}
// 						}
						&.icon-linux:first-child:not(:last-child), &.icon-linux:last-child:nth-child(2) {
							&::before {
								margin-right: -.2em;
							}
						}
						
						// icons in sets of 4
						&:nth-child(1):nth-last-of-type(4), 
// 						&:nth-child(1):nth-last-of-type(3), // enable for sets of 3 and 4
						&:nth-child(2):nth-last-of-type(3), 
// 						&:nth-child(2):nth-last-of-type(2), // enable for sets of 3 and 4
						&:nth-child(3), 					
						&:nth-child(4) { 
							font-size: 0.75em;
							margin-left: -.2em;
// 							margin-top: .2em;
// 							margin-bottom: .2em;

							// reduce size of specific platform icons in sets of 4
							&.icon-windows, &.icon-switch {
								font-size: .60em;
								margin-left: .15em;
								margin-right: .15em;
								&::before {
									margin-left: .20em;
									margin-top: .15em;
								}
							}
	
						}
						
						// icons in sets of 5 or 6
						&:nth-child(1):nth-last-of-type(5), 
						&:nth-child(1):nth-last-of-type(6), 
						&:nth-child(2):nth-last-of-type(4), 
						&:nth-child(2):nth-last-of-type(5), 
						&:nth-child(3):nth-last-of-type(3), 
						&:nth-child(3):nth-last-of-type(4), 
						&:nth-child(4):nth-last-of-type(2), 
						&:nth-child(4):nth-last-of-type(3), 
						&:nth-child(5), 
						&:nth-child(6) { 
							font-size: 0.9em;
// 							margin-top: .1em;
							margin-bottom: .2em;
							
							// reduce size of specific platform icons in sets of 5 or 6
							&.icon-switch, &.icon-windows {
								font-size: .80em;
								margin-left: .15em;
								margin-right: .15em;
								&::before {
									margin-left: .20em;
									margin-top: .15em;
								}
							}
						}
						
					}
				}
				
				time {
					white-space: nowrap;
					width: 100%;
					text-transform: uppercase;
				}
			}
		}
	}
	
	footer {
		padding-top: 0px;
	}
}

@include media-breakpoint-up(md) {
	#latest-news {
		article {
			display: flex;
			.panel {
				border: none;
				display: flex;
				padding: 0;
				flex: 1 1 100%;
				.panel-body {
					-webkit-clip-path: polygon(14px 0px, 100% 0px, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0px 100%, 0px 14px);
					clip-path: polygon(14px 0px, 100% 0px, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0px 100%, 0px 14px);
					padding: 2.2vw 0;
					font-size: 1.5vw;
					border: none;
					flex: 1 1 100%;
//					background-image: url('') !important; // remove
					
					&:after {
						position: absolute;
						display: block;
						content: '';
						background-color: #000;
						top: 0px; left: 0px;
						bottom: 0px; right: 0px;
						transition: background .2s linear;
						-webkit-clip-path: polygon( 10px 0px, 
													100% 0px, 
													100% calc(100% - 10px), 
													calc(100% - 10px) 100%, 
													0px 100%, 
													0px 10px, 
													7px 10px, 
													7px calc(100% - 7px), 
													calc(100% - 17px) calc(100% - 7px), 
													calc(100% - 7px) calc(100% - 17px), 
													calc(100% - 7px) 7px, 
													17px 7px, 
													7px 17px, 
													0px 10px);
						
						clip-path: polygon(			10px 0px, 
													100% 0px, 
													100% calc(100% - 10px), 
													calc(100% - 10px) 100%, 
													0px 100%, 
													0px 10px, 
													7px 10px, 
													7px calc(100% - 7px), 
													calc(100% - 17px) calc(100% - 7px), 
													calc(100% - 7px) calc(100% - 17px), 
													calc(100% - 7px) 7px, 
													17px 7px, 
													7px 17px, 
													0px 10px);
					}
					
					&:hover {
						border: none;
						
						&::after {
							background-color: $feral-orange;
// 							-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 15px) calc(100% - 2px), calc(100% - 2px) calc(100% - 15px), calc(100% - 2px) 2px, 15px 2px, 2px 15px, 0px 10px);							
// 							clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 15px) calc(100% - 2px), calc(100% - 2px) calc(100% - 15px), calc(100% - 2px) 2px, 15px 2px, 2px 15px, 0px 10px);
						}
					}
					
					h4 {
						font-size: 1.4em;
						font-weight: normal;
						padding-right: 2.2vw;
						padding-left: 2.2vw;
					}
					
					aside {
						margin-left: auto;
						
						padding-right: 2.2vw;
						padding-left: 0;
						.platforms {
							font-size: 1.3em;
							padding-bottom: 1em
						}
						time {
							text-transform: auto;
							font-size: .7em;
							line-height: 1.4em;
							span {
								display: block;
								font-size: 2.5em;
								margin: 0 -.08em .3em 0;
							}
						}
					}
				}
			}
		}
	
		footer {
			padding-top: 10px;
		}
		
	}
}

#videos-activity {
	.panel {
		margin: 1rem 10px;
		
		h4 {
			font-size: 1.2rem;
			text-align: center;
			font-weight: normal;
			text-shadow: $small-black-text-shadow;
			
		}
		
		.panel-video {
			background-color: #000;
			-webkit-clip-path: polygon(14px 0px, 100% 0px, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0px 100%, 0px 14px);
			clip-path: polygon(14px 0px, 100% 0px, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0px 100%, 0px 14px);
			
			.panel-video-holder {
				position: relative;
// 				clip-path: polygon(12px 2px, calc(100% - 2px) 2px, calc(100% - 2px) calc(100% - 12px), calc(100% - 12px) calc(100% - 2px), 2px calc(100% - 2px), 2px 12px);
				padding-bottom: 56.25%; /* 16:9 */
				&::after {
					position: absolute;
					display: block;
					content: '';
					background-color: #000;
					top: 0px; left: 0px;
					bottom: 0px; right: -1px;
					transition: background .2s linear;
/*
					-webkit-clip-path: polygon( 10px 0px, 
												100% 0px, 
												100% calc(100% - 10px), 
												calc(100% - 10px) 100%, 
												0px 100%, 
												0px 10px, 
												2px 10px, 
												2px calc(100% - 2px), 
												calc(100% - 12px) calc(100% - 2px), 
												calc(100% - 2px) calc(100% - 12px), 
												calc(100% - 2px) 2px, 
												12px 2px, 
												2px 12px, 
												0px 10px);
					
					clip-path: polygon(			10px 0px, 
												100% 0px, 
												100% calc(100% - 10px), 
												calc(100% - 10px) 100%, 
												0px 100%, 
												0px 10px, 
												2px 10px, 
												2px calc(100% - 2px), 
												calc(100% - 12px) calc(100% - 2px), 
												calc(100% - 2px) calc(100% - 12px), 
												calc(100% - 2px) 2px, 
												12px 2px, 
												2px 12px, 
												0px 10px);
*/
					-webkit-clip-path: polygon( 10px 0px, 
												100% 0px, 
												100% calc(100% - 10px), 
												calc(100% - 10px) 100%, 
												0px 100%, 
												0px 10px, 
												7px 10px, 
												7px calc(100% - 7px), 
												calc(100% - 17px) calc(100% - 7px), 
												calc(100% - 7px) calc(100% - 17px), 
												calc(100% - 7px) 7px, 
												17px 7px, 
												7px 17px, 
												0px 10px);
					
					clip-path: polygon(			10px 0px, 
												100% 0px, 
												100% calc(100% - 10px), 
												calc(100% - 10px) 100%, 
												0px 100%, 
												0px 10px, 
												7px 10px, 
												7px calc(100% - 7px), 
												calc(100% - 17px) calc(100% - 7px), 
												calc(100% - 7px) calc(100% - 17px), 
												calc(100% - 7px) 7px, 
												17px 7px, 
												7px 17px, 
												0px 10px);
				}
				&:hover {
					&::after {
						
						background-color: $feral-orange;
					}
				}
				&.youtube { /* Doug! we could use this ratio thing for both youtube and non-youtube embeds */
					padding-bottom: 56.25%; /* 16:9 */
					
					iframe {
						position: absolute;
						top: 0px; left: 0px;
						bottom: 0px; right: 0px;
						width: 100%; height: 100%;
					}
				}
				
				
				video {
					
					position: absolute;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					width: 100%; height: 100%;
					
				}
				
				.access-restricted {
// 					padding-bottom: 56.25%;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					position: absolute;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					width: 100%; height: 100%;
					padding: 2em;
				}
				
				.video-age-gate {
					position: absolute;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					background-color: rgba(0, 0, 0, 0.8);
					-webkit-backdrop-filter: blur(4px);
					backdrop-filter: blur(4px);
					z-index: 50;
					display: flex;
					flex-wrap: wrap;
					align-content: center;
					justify-content: center;
					margin-bottom: 0;
					h2 {
						font-size: 3vw;
						display: flex;
						flex: 0 0 95%;
						justify-content: center;
						margin: 0;
						@include media-breakpoint-up(md) {
							font-size: 2vw;
						}
						@include media-breakpoint-up(xl) {
							font-size: 1.5em;
						}
					}
					#date-options{
						flex: 0 0 95%;
						justify-content: center;
						display: flex;
						label {
							display: flex;
							align-content: center;
							
							select {
								margin: 0 .5em;
							}
						}
					}
					#date-options {
						display: flex;
						flex: 0 0 90%;
						align-content: center;
						align-items: center;
						justify-content: center;
						flex-wrap: wrap;
						justify-content: center;
						padding: .5em 0;
						margin: 0 auto;
						@include media-breakpoint-up(xl) {
							padding: 1em 0;
							flex: 0 0 95%;
						}
						label {
							display: flex;
							align-content: center;
							align-items: center;
// 							margin-left: .5em;
							font-weight: bold;
							text-shadow: 0px 0px .3em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6);
							font-weight: normal;
							font-size: 2.5vw;
							padding: .5em;
							@include media-breakpoint-up(md) {
								margin-bottom: .5vw;
								font-size: 2vw;
								flex: 0 1 100%;
								justify-content: space-between;
								padding: 0;
							}
							@include media-breakpoint-up(xl) {
								flex: 0 1 20%;
								justify-content: center;
								padding: .5em;
								font-size: 1.3vw;
								margin-bottom: .5rem;
							}
							
							select {
								display: flex;
// 								flex: 0 0 65%;
								
								font-weight: normal;
								border-radius: 3px;
								margin: 0 0 0 .5em;
								padding: 0 calc(1em + 17px) 0 1em;
								background-image: url('/images/down-arrow.svg');
								background-repeat: no-repeat;
								background-position: calc(100% - 17px) center;
								background-size: 12px auto;
								background-color: rgba(255, 255, 255, 0.6);
								position: relative;
								-moz-appearance: none;
							    -webkit-appearance: none;
							    appearance: none;
								height: 5.7vw;
								border: 2px solid black;	
								transition: border .2s linear;
								cursor: pointer;
								will-change: background-color;
								transition: background-color .2s linear;
								text-transform: uppercase;
								&:hover {
				// 					border-color: $feral-orange;
				// 					background-image: url('/images/down-arrow-orange.svg')/*,  url('/images/line-orange.svg') */;
									background-color: white;
								}
								@include media-breakpoint-up(md) {
									height: 3.7vw;
									
								}
								@include media-breakpoint-up(xl) {
									flex: 1 1 50%;
									height: 2.7vw;
								}
							}
						}
					}
					button {
						display: flex;
						flex: 0 1 auto;
						padding: 0 1em;
						margin: 0;
						height: 5.7vw;
						@include media-breakpoint-up(md) {
							height: 3.7vw;
							
						}
						@include media-breakpoint-up(xl) {
							margin: 1em auto 0;
							
							height: 2.7vw;
						}
						background: rgba(255, 255, 255, 0.6);
						-moz-appearance: none;
					    -webkit-appearance: none;
					    appearance: none;
						font-size: 1.1em;
						border: 2px solid black;	
						border-radius: 3px;
						color: black;
						will-change: background-color, transform;
						transition: background-color .2s linear, transform .2s linear;
						text-transform: uppercase;
					    align-items: center;
						&:hover {
							background: white;
							transform: scale(1.05);
						}
						
						font-size: 2.5vw;
						
							@include media-breakpoint-up(md) {
								font-size: 2vw;
							}
							@include media-breakpoint-up(xl) {
								font-size: 1.5vw;
							}
					}
				}
			}
			
			video, iframe {
				width: 100%; height: auto;
			}
		}
		
		p {
			text-align: center;
			padding-top: 1rem;
			text-shadow: $small-black-text-shadow;
			span:first-child {
				margin-left: calc(10px + .5vw);
			}
		}
		
		.panel-buttons {
			display: flex;
			
			.btn {
				width: 100%;
				font-weight: 500;
				text-transform: uppercase;
				
				&:nth-child(2) {
					margin-left: 1vw;
					font-weight: normal;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	
	#videos-activity {
		.panel {
			
			h4 {
				text-align: left;
				padding-left: 12px;
				margin-bottom: 1rem;
			}
			p {
// 				font-size: 1.6vw;
			}
			.panel-buttons {
				
// 				padding: 0 5vw;
				.btn {
					
					
				}
			}
		}
	}
}


@include media-breakpoint-up(lg) {
	#videos-activity {
		.panel {
			
			h4 {
				font-size: 1.8vw;
			}
			p {
				font-size: 1.6vw;
			}
			.panel-buttons {
				
				padding: 0 5vw;
				.btn {
					
					
				}
			}
		}
	}
}


@include media-breakpoint-up(xl) {
	#videos-activity {
		.panel {
			
			h4 {
				font-size: 1.5rem;
			}
			p {
				font-size: 1.3rem;
			}
			.panel-buttons {
				
// 				padding: 0 5vw;
				.btn {
					
					
				}
			}
		}
	}
}

#feral-radar {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 250px;
	
	header {
		position: relative;
		padding: 30px 19px;
		text-shadow: $small-black-text-shadow;
		&, h3 {
			text-align: left;
		}
		p {
			padding-top: 1rem;
			width: 9rem;
		}
	}
	
	footer {
		margin-top: auto;
	}
	
	#radar-holder {
		position: absolute;
		top: 0px;
		width: 100%; height: 100%;
		overflow: hidden;
	}
	
	#radar-render {
		position: absolute;
		top: 50px; right: -200px;
		width: 400px; padding-top: 400px;
		
		.radar-render-bg {
			position: absolute;
			border-radius: 100%;
		}
		
		.radar-render-blip {
			position: absolute;
			width: 4%; height: 4%;
			border: solid 3px $radarGreyBorder;
			border-radius: 100%;
		}
	}
		
	$radarCurrentStep: 0% !global;
	#radar-render-1 {
		@include radar-step(-10%);
		background-color: rgba(72,102,124, .7);
	}
	
	#radar-render-2 {
		@include radar-step(15%);
		@include radar-gradient(#515961, 55%, #2f3236, 70%);
	}
	
	#radar-contents-notSoon {
		@include radar-blip(-110deg, 1%);
		background-color: blip-color(notSoon);
	}
		
	#radar-entry.radar-entry-notSoon > div > div:before {
		background-color: blip-color(notSoon);
	}
	
	 /* Doug! I couldn't work out how to do the borders so they work automatically (too many tangens and sinuses to get it working)... when you're done fiddling just add a &:before to each radar-handle and do the border and clipping manually */
	#radar-handle.radar-handle-notSoon {
		@include radar-handle(-110deg, blip-color(notSoon), $radarGreyBorder, 1%);
	}
	
	#radar-render-3 {
		@include radar-step(10%);
		background-color: #bbbcbe;
	}
	
	#radar-render-4 {
		@include radar-step(.5%);
		background-color: #626365;
	}
	
	#radar-render-5 {
		@include radar-step(2%);
		@include radar-gradient(#c58d55, 55%, #e0dad6, 70%);
	}
	
	#radar-contents-quiteSoon {
		@include radar-blip(-160deg);
		background-color: blip-color(quiteSoon);
		
		@include media-breakpoint-up(md) {
			@include radar-blip(-80deg);
		}
	}
	
	#radar-handle.radar-handle-quiteSoon {
		@include radar-handle(-80deg, blip-color(quiteSoon), $radarGreyBorder);
	}
		
	#radar-entry.radar-entry-quiteSoon > div > div:before {
		background-color: blip-color(quiteSoon);
	}
	
	#radar-render-6 {
		@include radar-step(7%);
		background-color: #948b7a;
	}
	
	#radar-render-7 {
		@include radar-step(2%);
		@include radar-gradient(#c58d55, 50%, #e0dad6, 70%);
	}
	
	#radar-contents-soon {
		@include radar-blip(-135deg);
		background-color: blip-color(soon);
	}
	
	#radar-handle.radar-handle-soon {
		@include radar-handle(-135deg, blip-color(soon), $radarGreyBorder);
	}
	
	#radar-entry.radar-entry-soon > div > div:before {
		background-color: blip-color(soon);
	}
	
	#radar-render-8 {
		@include radar-step(7%);
		background-color: #9d8c7c;
	}
	
	#radar-render-9 {
		@include radar-step(2%);
		@include radar-gradient(#c58d55, 40%, #e0dad6, 70%);
	}
	
	#radar-contents-verySoon {
		@include radar-blip(-110deg);
		background-color: blip-color(verySoon);
		
		@include media-breakpoint-up(md) {
			@include radar-blip(-55deg);
		}
	}
	
	#radar-handle.radar-handle-verySoon {
		@include radar-handle(-55deg, blip-color(verySoon), $radarGreyBorder);
	}
		
	#radar-entry.radar-entry-verySoon > div > div:before {
		background-color: blip-color(verySoon);
	}
	
	#radar-render-10 {
		@include radar-step(7%);
		background-color: $radarOutNow;
	}
	
	#radar-handle {
		display: none;
		position: absolute;
		right: 196px;
		z-index: 2;
	}
	
	#radar-entry {
		display: none;
		position: absolute;
		top: 5%; right: 0px;
		
		> div {
			position: relative;
			background-color: #1b1b1b;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			
			&:after {
				position: absolute;
				display: block;
				content: '';
				background-color: $radarGreyBorder;
				top: 0px; left: 0px;
				bottom: 0px; right: 0px;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
			}
			
			> div {
				position: relative;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
				width: 200px;
				height: 148px;
				background-size: 100%;
				&:before {
					position: absolute;
					display: block;
					content: '';
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 5px 10px, 5px calc(100% - 5px), calc(100% - 15px) calc(100% - 5px), calc(100% - 5px) calc(100% - 15px), calc(100% - 5px) 5px, 15px 5px, 5px 15px, 0px 10px);
					clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 5px 10px, 5px calc(100% - 5px), calc(100% - 15px) calc(100% - 5px), calc(100% - 5px) calc(100% - 15px), calc(100% - 5px) 5px, 15px 5px, 5px 15px, 0px 10px);
					z-index: 1;
				}
				
				&:after {
					position: absolute;
					display: block;
					content: '';
					background-color: $radarGreyBorder;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
					clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
					z-index: 2;
				}
			}
			
			.platforms {
				position: absolute;
				display: flex;
				justify-content: center;
				bottom: 0px; 
				padding: 2.5em 0 .7em;
				width: 100%;
				/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
	background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
	
				span {
					margin-right: 0px;
					text-shadow: $small-black-text-shadow, $small-black-text-shadow, $small-black-text-shadow;
					font-size: 1.5em;
					&:last-child {
						margin-right: 0px;
					}
				}
			}
		}
		
		p {
			padding: 3px 10px;
			width: 200px;
			font-weight: 300;
			text-align: center;
// 			-webkit-font-smoothing: subpixel-antialiased;
		}
	}
}

@include media-breakpoint-up(md) {
	#feral-radar {
		height: 400px;
		
		header {
			padding: 10px 40px;
			
			p {
				font-size: 1.7rem;
				width: 9em;
				
				[lang="ru"] & {
					width: 13em;
				}
				
				[lang="es"] & {
					width: 11em;
				}
			}
		}
		
		#radar-render {
			position: absolute;
			top: 0px; left: calc(50% - 400px); right: auto;
			width: 800px; padding-top: 800px;
			
			.radar-render-blip {
				border-width: 7px;
			}
		}
	
		#radar-handle {
			display: block;
		}
	
		#radar-entry {
			display: block;
		}
	}
}

#join-and-stay {
	display: flex;
	flex-direction: column;
	padding: 0px;
	
	&:before {
		display: none;
	}
	
	h3 {
		margin: 15px 30px;
	}
	
	.panel {
		margin: .5vw 2.9vw; 
		padding: 10px 15px;
		border: solid 1px #AAA;
		background-color: rgba(0,0,0,0.8);
		border-radius: 5px;
	}
	
	&.svg-divided {
		#stay-up-to-date, #join-feral, #calico {
			padding-top: 35px;
		}
	}
	
	&.svg-divider-after {
		#stay-up-to-date, #join-feral, #calico {
			padding-bottom: 50px;
		}
	}
}

#calico {
	display: flex;
	flex-direction: column;
	width: 100%;
	
	position: relative;
	margin-bottom: 10px;
	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0px; right: 0px;
		@include gradient-x(#fd7401, #e4ac59);
		height: 10px;
	}
	
	&::before {
		content: ' ';
		position: absolute;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+50,0.65+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
		
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
	}
	
	#jarallax-container-5 {
		div {
// 				height: 90% !important;
			background-position: 35% top !important;
		}
	}
	
	h3, .panel {
		position: relative;
		z-index: 1;
	}
	.panel {
		padding: 1em 1.2em;
		font-size: 6vw;
		font-style: italic;
		font-weight: 300;
		margin-top: 3em;
		text-align: center;
		img {
			width: 30vw;
			display: block;
			margin: 0 auto .5em;
		}
		
		strong {
			display: inline;
// 			padding-right: .4em;
			font-weight: 700;
		}
		
		p {
			padding-right: .5em;
			display: inline;
		}
		
		> *:last-child {
			margin-bottom: 0px;
		}
	}
}

#join-feral {
	display: flex;
	flex-direction: column;
	width: 100%;
	
	h3, .panel {
		position: relative;
		z-index: 1;
	}
	.panel {
		padding: 1em 1.2em;
		font-size: 6vw;
		font-style: italic;
		font-weight: 300;
		margin-top: 3em;
		text-align: center;
		> *:last-child {
			margin-bottom: 0px;
		}
	}
}

@include media-breakpoint-up(md) {
	#join-and-stay {
		flex-direction: row;
		.panel {
			position: relative;
			border-radius: 0px;
			border: none;
			padding: 11px 16px;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			
			&:after {
				position: absolute;
				display: block;
				content: '';
				background-color: #AAA;
				top: 0px; left: 0px;
				bottom: 0px; right: 0px;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 1px 10px, 1px calc(100% - 1px), calc(100% - 11px) calc(100% - 1px), calc(100% - 1px) calc(100% - 11px), calc(100% - 1px) 1px, 11px 1px, 1px 11px, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 1px 10px, 1px calc(100% - 1px), calc(100% - 11px) calc(100% - 1px), calc(100% - 1px) calc(100% - 11px), calc(100% - 1px) 1px, 11px 1px, 1px 11px, 0px 10px);
				z-index: -1;
			}
		}
	}	
	#calico {
		margin-bottom: 0px;
		
		#jarallax-container-5 {
			div {
				background-position: right top !important;
				
			}
		}

		header {
			align-self: flex-end;
			text-align: left;
			margin-top: 1em;
			margin-bottom: 1em;
			min-width: 47%;
			max-width: 47%;
			padding-right: 2.9vw;
			margin-right: 4.9vw;
			h3 {
				min-width: 100%;
				text-align: left;
				align-self: flex-start;
			}
		}
		
		.panel {
			margin-top: 0em;
			margin-bottom: 1.5em;
			padding: 2.5vw 2.9vw;
			font-size: 2.1vw;
			font-style: italic;
			font-weight: 300;
			text-align: left;
			max-width: 47%;
			min-width: 47%;
			align-self: flex-end;
			strong {
				display: block;
			}
			
			p {
				padding-right: 0;
				display: block;
			}
			img {
				width: 35%;
				margin-left: 0;
				margin-right: 2.5vw;
				margin-bottom: 0;
				float: left;
			}
		}
		
		footer {
			align-self: flex-end;
			margin-top: 0;
			margin-bottom: 2em;
		}
	}
	#join-feral {
		
		h3 {
			align-self: flex-start;
		}
		.panel {
			margin-top: 5em;
			padding: 2.5vw 2.9vw;
			font-size: 2.1vw;
			font-style: italic;
			font-weight: 300;
			text-align: left;
			p:last-child {
				padding-right: 2vw;
			}
		}
		
		footer {
			align-self: flex-end;
		}
	}
}