@function pow($number, $exp) {
	$value: 1;
	@if $exp > 0 {
		@for $i from 1 through $exp {
			$value: $value * $number;
		}
	} @else if $exp < 0 {
		@for $i from 1 through -$exp {
			$value: $value / $number;
		}
	}
	@return $value;
}

@function fact($number) {
	$value: 1;
	@if $number > 0 {
		@for $i from 1 through $number {
			$value: $value * $i;
		}
	}
	@return $value;
}

@function pi() {
	@return 3.14159265359;
}

@function rad($angle) {
	$unit: unit($angle);
	$unitless: $angle / ($angle * 0 + 1);
	// If the angle has 'deg' as unit, convert to radians.
	@if $unit == deg {
		$unitless: $unitless / 180 * pi();
	}
	@return $unitless;
}

@function sin($angle) {
	$sin: 0;
	$angle: rad($angle);
	// Iterate a bunch of times.
	@for $i from 0 through 10 {
		$sin: $sin + pow(-1, $i) * pow($angle, (2 * $i + 1)) / fact(2 * $i + 1);
	}
	@return $sin;
}

@function cos($angle) {
	$cos: 0;
	$angle: rad($angle);
	// Iterate a bunch of times.
	@for $i from 0 through 10 {
		$cos: $cos + pow(-1, $i) * pow($angle, 2 * $i) / fact(2 * $i);
	}
	@return $cos;
}

@mixin prefix($declarations, $prefixes: (webkit moz o ms)) {
	@each $property, $value in $declarations {
		@each $prefix in $prefixes {
			#{'-' + $prefix + '-' + $property}: $value;
		}

		// Output standard non-prefixed declaration
		#{$property}: $value;
	}
}

@mixin fa-icon($icon) {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	content: $icon;
}

@mixin radar-gradient($startColor, $start, $endColor, $end) {
	background-color: $startColor;
	background: -moz-radial-gradient(center, ellipse cover, $startColor $start, $endColor $end);
	background: -webkit-radial-gradient(center, ellipse cover, $startColor $start, $endColor $end);
	background: radial-gradient(ellipse at center, $startColor $start, $endColor $end);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$startColor', endColorstr='$endColor', GradientType=1);
}

@mixin gradient-y-four-colors($start-color: $blue, $mid-color: $purple, $color-stop: 50%, $mid2-color: $purple, $color2-stop: 50%, $end-color: $red) {
  background-image: linear-gradient($start-color, $mid-color $color-stop, $mid2-color $color2-stop, $end-color);
  background-repeat: no-repeat;
}

$radarCurrentStep: 0%;
@mixin radar-step($step) {
	$radarCurrentStep: $radarCurrentStep + $step !global;
	top: $radarCurrentStep; left: $radarCurrentStep;
	width: #{100% - $radarCurrentStep * 2}; height: #{100% - $radarCurrentStep * 2};
}

@mixin radar-blip($angle, $correct: 0%) {
	top: #{((51% - $correct - $radarCurrentStep)*sin($angle))+48%}; left: #{((51% - $correct - $radarCurrentStep)*cos($angle))+48%};
}

@mixin radar-handle($angle, $color, $borderColor, $correct: 0%) {
	$top: ((51% - $correct - $radarCurrentStep)*sin($angle))+50%;
	@if(($top / 100%) < .15) {
		top: $top;
		height: #{(20% - $top)};
		
		&:after {
			-webkit-clip-path: polygon(0px 0px, 100% calc(100% - 30px), 100% 100%);
			clip-path: polygon(0px 0px, 100% calc(100% - 30px), 100% 100%);
		}
	} @else if(($top / 100%) > .25) {
		top: 15%;
		height: #{($top - 15%)};
		
		&:after {
			-webkit-clip-path: polygon(0px 100%, 100% 0%, 100% 30px);
			clip-path: polygon(0px 100%, 100% 0%, 100% 30px);
		}
	} @else {
		top: calc(#{$top} - 15px);
		height: 30px;
		
		&:after {
			-webkit-clip-path: polygon(0px 15px, 100% 0%, 100% 100%);
			clip-path: polygon(0px 15px, 100% 0%, 100% 100%);
		}
	}
	left: #{((51% - $correct - $radarCurrentStep)*cos($angle))+50%};
	
	&:after {
		position: absolute;
		display: block;
		content: '';
		top: 0px; left: 0px;
		background-color: $color;
		width: 100%; height: 100%;
	}
}

@mixin panel($backgroundColor: rgba(0,0,0,.5), $borderColor: #333, $size: 10px, $borderWidth: 1px) {
	position: relative;
	background-color: $backgroundColor;
	border: none;
	-webkit-clip-path: polygon($size 0px, 100% 0px, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, 0px 100%, 0px $size);
	clip-path: polygon($size 0px, 100% 0px, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, 0px 100%, 0px $size);
	border-radius: 0px;
	
	&:before {
		position: absolute;
		display: block;
		content: '';
		top: 0px; left: 0px;
		bottom: 0px; right: 0px;
		background-color: $borderColor;
		$borderWidthSpace: $size + $borderWidth;
		-webkit-clip-path: polygon($size 0px, 100% 0px, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, 0px 100%, 0px $size, $borderWidth $size, $borderWidth calc(100% - #{$borderWidth}), calc(100% - #{$borderWidthSpace}) calc(100% - #{$borderWidth}), calc(100% - #{$borderWidth}) calc(100% - #{$borderWidthSpace}), calc(100% - #{$borderWidth}) $borderWidth, $borderWidthSpace $borderWidth, $borderWidth $borderWidthSpace, 0px $size);
		clip-path: polygon($size 0px, 100% 0px, 100% calc(100% - #{$size}), calc(100% - #{$size}) 100%, 0px 100%, 0px $size, $borderWidth $size, $borderWidth calc(100% - #{$borderWidth}), calc(100% - #{$borderWidthSpace}) calc(100% - #{$borderWidth}), calc(100% - #{$borderWidth}) calc(100% - #{$borderWidthSpace}), calc(100% - #{$borderWidth}) $borderWidth, $borderWidthSpace $borderWidth, $borderWidth $borderWidthSpace, 0px $size);
		z-index: -1;
	}
}