@include media-breakpoint-down(sm) {
	#primary-nav {
		order: 1;
		flex-wrap: wrap;
		#primary-nav-pages {
			z-index: 10;
		}
		#primary-breadcrumbs {
			order: 10;
		}
		#primary-buttons {
			order: 3;
			justify-content: center;
			flex: 0 0 100%;
			flex-wrap: nowrap;
			margin-right: 0;
			text-align: center;
		}
		#primary-nav-controls {
			order: 2;
			z-index: 10;
		}

	}
}

#primary-nav {
	flex-wrap: wrap;
	position: fixed;
	pointer-events: none;
	@include media-breakpoint-down(sm) {
		padding: 0 0 2px;
	}
	#primary-nav-pages, #primary-breadcrumbs, #primary-buttons, #primary-nav-controls {
		pointer-events: auto;

	}
}

#primary-nav-pages {
	margin-right: 0px;
	ul {
		display: none;
	}
}

#primary-breadcrumbs {
	display: none;
	align-items: center;
	margin: 7px auto 7px -15px;
	background-color: rgba(255, 255, 255, 0.85);
	border: solid 1px #b3b3b3;
	border-radius: 5px;
	font-size: 85%;
	-webkit-clip-path: polygon(26px 0px, 100% 0px, 100% 100%, 0px 100%);
			clip-path: polygon(26px 0px, 100% 0px, 100% 100%, 0px 100%);


	a {
		display: block;
		color: $body-color;
		margin: 0px 10px;

		&:first-child {
			margin-left: 30px;
		}
	}

	span {
		font-size: 85%;
	}
}

#primary-buttons {

	margin-top: .15em;
	margin-right: -24px;


	a {
		margin-right: .15em;
		padding: 4px 20px 3px 8px;
// 		min-width: 150px;


		&:not(:last-child) {
			-webkit-clip-path: none;
					clip-path: none;
			padding: 2px 8px 1px;
			background-color: #333;
			border: 2px solid black;
			will-change: background-color, border-color;
			transition: border-color .2s linear, background-color .2s linear;

			&:hover {
				border-color: white;
			}

			&::before {
				-webkit-clip-path: none;
						clip-path: none;
			}
		}
		@include media-breakpoint-down(sm) {

			display: inline-block;
			margin-right: 0;
			width: 32%;


			&:last-child {
				-webkit-clip-path: none;
						clip-path: none;
				padding: 2px 8px 1px;
				background-color: #F15A24;
				border: 2px solid black;
				will-change: background-color, border-color;
				transition: border-color .2s linear, background-color .2s linear;

				&:hover {
					border-color: white;
				}

				&::before {
					-webkit-clip-path: none;
							clip-path: none;
				}
			}
		}
	}
}

#minisite-content {
	overflow: hidden;
	overflow-x: hidden;

	#minisite-background {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background-position: center top;
		background-size: cover;
		z-index: -1;
	}

	&:before {
		display: none;
	}

	.tabs-content > li {
		display: none;

		&.active {
			display: block;
		}
	}


	footer {
		padding: 14em 0 2em;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
		background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

		#minisite-legal {
			font-size: .7em;
			color: #d0d0d0;
			margin: 0 auto ;
			padding-bottom: 2em;
	// 		width: 1200px;
			max-width: 90%;
			display: flex;
			flex-direction: column;
			text-align: center;
			position: relative;
			
			img {
				padding-bottom: 2em;
				width: 200px;
				margin: 0 auto;
				
				@include media-breakpoint-down(sm) {
					flex: 0 0 auto !important;
				}
			}
			p {
				align-self: flex-end;
			}
			a {
				position: absolute;
				bottom: 1em;
				display: inline-block;
				width: auto;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.nav-expanded {
		justify-content: space-between;
		#primary-buttons {
			display: none;
		}
	}
	#primary-nav {
		h1 {
			width: 28px;
			margin: 0px 5px;
			height: 35px;

			a {
/* 				background-image: url('/images/logo-head.svg'); */
				height: 26px;
				margin-top: 4px;
				margin-left: 1px;
				background-size: cover;
				background-position: left center;
			}
		}
	}
	#primary-breadcrumbs {
		display: flex;
		width: auto;
		height: auto;
	}
	#primary-buttons {
		margin-top: .3em;
		a {
			min-width: 140px;
			margin-right: .15em;
			padding: 4px 33px 3px 10px;
// 			min-width: 150px;
			-webkit-clip-path: polygon(0px 0px, calc(100% - 29px) 0px, 100% 100%, 0px 100%, 0px 0px);
					clip-path: polygon(0px 0px, calc(100% - 29px) 0px, 100% 100%, 0px 100%, 0px 0px);

			&::before {
				-webkit-clip-path: polygon(0px 0px, calc(100% - 28px) 0px, calc(100% - 2px) 100%, 0px 100%, 0px 0px);
						clip-path: polygon(0px 0px, calc(100% - 28px) 0px, calc(100% - 2px) 100%, 0px 100%, 0px 0px);
			}

			&:not(:last-child) {
				-webkit-clip-path: none;
						clip-path: none;
				padding: 2px 10px 1px;
				background-color: #333;
				border: 2px solid black;
				will-change: background-color, border-color;
				transition: border-color .2s linear, background-color .2s linear;

				&:hover {
					border-color: white;
				}

				&::before {
					-webkit-clip-path: none;
							clip-path: none;
				}
			}


		}
	}


	#minisite-content {

		footer {

			align-self: flex-end;
			#minisite-legal {
				text-align: left;
				flex-direction: row;
				align-items: center;
				img {
					flex: 0 0 calc(200px + 2em);
					align-self: center;
					padding-right: 2em;
				}
				p {
					text-align: justify;
					padding-bottom: .5em;
				}
			}
		}
	}

}

@include media-breakpoint-between(md, lg) {


/*
	.nav-expanded {
		justify-content: flex-end;
		#primary-buttons {
			display: flex;
		}
	}
*/

	#primary-breadcrumbs {

		a:nth-of-type(1) {
// 			display: none;
			+ span {
				display: none;
			}
		}

		a:nth-of-type(2), a:nth-of-type(3) {
			display: none;
			+ span {
				display: none;
			}
		}
	}

}


@include media-breakpoint-up(xl) {
	#primary-breadcrumbs {
		a:nth-of-type(2) {
			display: none;
			+ span {
				display: none;
			}
		}
	}
}

@media screen and (min-width:1400px) {
	#primary-breadcrumbs {
		a:nth-of-type(2) {
			display: block;
			+ span {
				display: inline-block;
			}
		}
	}

}
