#videos-content {
	display: flex;
	flex-wrap: wrap;
	.row {
		@include fade-in-up(2s, 0s);
	}
	header {
		position: relative;
		width: 100%;
		h3 {
			margin: 1.6rem 0 1rem;
			text-align: center;
			text-transform: uppercase;
			font-size: 1.3rem;
			text-shadow: $small-black-text-shadow;
		}
	}
	
	.panel {
		margin: 1rem 10px;
		
		h4 {
			font-size: 1.2rem;
			text-align: center;
			font-weight: normal;
			text-shadow: $small-black-text-shadow;
			
		}
		
		.panel-video {
			background-color: #000;
			-webkit-clip-path: polygon(14px 0px, 100% 0px, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0px 100%, 0px 14px);
			clip-path: polygon(14px 0px, 100% 0px, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0px 100%, 0px 14px);
			
			.panel-video-holder {
// 				clip-path: polygon(12px 2px, calc(100% - 2px) 2px, calc(100% - 2px) calc(100% - 12px), calc(100% - 12px) calc(100% - 2px), 2px calc(100% - 2px), 2px 12px);
				position: relative;
				padding-bottom: 56.25%; /* 16:9 */
				&::after {
					position: absolute;
					display: block;
					content: '';
					background-color: #000;
					top: 0px; left: 0px;
					bottom: 0px; right: -1px;
					transition: background .2s linear;
/*
					-webkit-clip-path: polygon( 10px 0px, 
												100% 0px, 
												100% calc(100% - 10px), 
												calc(100% - 10px) 100%, 
												0px 100%, 
												0px 10px, 
												2px 10px, 
												2px calc(100% - 2px), 
												calc(100% - 12px) calc(100% - 2px), 
												calc(100% - 2px) calc(100% - 12px), 
												calc(100% - 2px) 2px, 
												12px 2px, 
												2px 12px, 
												0px 10px);
					
					clip-path: polygon(			10px 0px, 
												100% 0px, 
												100% calc(100% - 10px), 
												calc(100% - 10px) 100%, 
												0px 100%, 
												0px 10px, 
												2px 10px, 
												2px calc(100% - 2px), 
												calc(100% - 12px) calc(100% - 2px), 
												calc(100% - 2px) calc(100% - 12px), 
												calc(100% - 2px) 2px, 
												12px 2px, 
												2px 12px, 
												0px 10px);
*/
					-webkit-clip-path: polygon( 10px 0px, 
												100% 0px, 
												100% calc(100% - 10px), 
												calc(100% - 10px) 100%, 
												0px 100%, 
												0px 10px, 
												7px 10px, 
												7px calc(100% - 7px), 
												calc(100% - 17px) calc(100% - 7px), 
												calc(100% - 7px) calc(100% - 17px), 
												calc(100% - 7px) 7px, 
												17px 7px, 
												7px 17px, 
												0px 10px);
					
					clip-path: polygon(			10px 0px, 
												100% 0px, 
												100% calc(100% - 10px), 
												calc(100% - 10px) 100%, 
												0px 100%, 
												0px 10px, 
												7px 10px, 
												7px calc(100% - 7px), 
												calc(100% - 17px) calc(100% - 7px), 
												calc(100% - 7px) calc(100% - 17px), 
												calc(100% - 7px) 7px, 
												17px 7px, 
												7px 17px, 
												0px 10px);
				}
				&:hover {
					&::after {
						
						background-color: $feral-orange;
					}
				}
				&.youtube { /* Doug! we could use this ratio thing for both youtube and non-youtube embeds */
					
					padding-bottom: 56.25%; /* 16:9 */
					
					iframe {
						position: absolute;
						top: 0px; left: 0px;
						bottom: 0px; right: 0px;
						width: 100%; height: 100%;
					}
				}
				
				video {
					
					position: absolute;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					width: 100%; height: 100%;
					
				}
				
				.access-restricted {
// 					padding-bottom: 56.25%;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					position: absolute;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					width: 100%; height: 100%;
					padding: 2em;
				}
				
				.video-age-gate {
					position: absolute;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					background-color: rgba(0, 0, 0, 0.8);
					-webkit-backdrop-filter: blur(4px);
					backdrop-filter: blur(4px);
					z-index: 50;
					display: flex;
					flex-wrap: wrap;
					align-content: center;
					justify-content: center;
					margin-bottom: 0;
					h2 {
						font-size: 3vw;
						display: flex;
						flex: 0 0 95%;
						justify-content: center;
						margin: 0;
						@include media-breakpoint-up(md) {
							font-size: 2vw;
						}
						@include media-breakpoint-up(xl) {
							font-size: 1.5em;
						}
					}
					#date-options{
						flex: 0 0 95%;
						justify-content: center;
						display: flex;
						label {
							display: flex;
							align-content: center;
							
							select {
								margin: 0 .5em;
							}
						}
					}
					#date-options {
						display: flex;
						flex: 0 0 90%;
						align-content: center;
						align-items: center;
						justify-content: center;
						flex-wrap: wrap;
						justify-content: center;
						padding: .5em 0;
						margin: 0 auto;
						@include media-breakpoint-up(xl) {
							padding: 1em 0;
							flex: 0 0 95%;
						}
						label {
							display: flex;
							align-content: center;
							align-items: center;
// 							margin-left: .5em;
							font-weight: bold;
							text-shadow: 0px 0px .3em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6);
							font-weight: normal;
							font-size: 2.5vw;
							padding: .5em;
							@include media-breakpoint-up(md) {
								margin-bottom: .5vw;
								font-size: 2vw;
								flex: 0 1 100%;
								justify-content: space-between;
								padding: 0;
							}
							@include media-breakpoint-up(xl) {
								flex: 0 1 20%;
								justify-content: center;
								padding: .5em;
								font-size: 1.3vw;
								margin-bottom: .5rem;
							}
							
							select {
								display: flex;
// 								flex: 0 0 65%;
								
								font-weight: normal;
								border-radius: 3px;
								margin: 0 0 0 .5em;
								padding: 0 calc(1em + 17px) 0 1em;
								background-image: url('/images/down-arrow.svg');
								background-repeat: no-repeat;
								background-position: calc(100% - 17px) center;
								background-size: 12px auto;
								background-color: rgba(255, 255, 255, 0.6);
								position: relative;
								-moz-appearance: none;
							    -webkit-appearance: none;
							    appearance: none;
								height: 5.7vw;
								border: 2px solid black;	
								transition: border .2s linear;
								cursor: pointer;
								will-change: background-color;
								transition: background-color .2s linear;
								text-transform: uppercase;
								&:hover {
				// 					border-color: $feral-orange;
				// 					background-image: url('/images/down-arrow-orange.svg')/*,  url('/images/line-orange.svg') */;
									background-color: white;
								}
								@include media-breakpoint-up(md) {
									height: 3.7vw;
									
								}
								@include media-breakpoint-up(xl) {
									flex: 1 1 50%;
									height: 2.7vw;
								}
							}
						}
					}
					button {
						display: flex;
						flex: 0 1 auto;
						padding: 0 1em;
						margin: 0;
						height: 5.7vw;
						@include media-breakpoint-up(md) {
							height: 3.7vw;
							
						}
						@include media-breakpoint-up(xl) {
							margin: 1em auto 0;
							
							height: 2.7vw;
						}
						background: rgba(255, 255, 255, 0.6);
						-moz-appearance: none;
					    -webkit-appearance: none;
					    appearance: none;
						font-size: 1.1em;
						border: 2px solid black;	
						border-radius: 3px;
						color: black;
						will-change: background-color, transform;
						transition: background-color .2s linear, transform .2s linear;
						text-transform: uppercase;
					    align-items: center;
						&:hover {
							background: white;
							transform: scale(1.05);
						}
						
						font-size: 2.5vw;
						
							@include media-breakpoint-up(md) {
								font-size: 2vw;
							}
							@include media-breakpoint-up(xl) {
								font-size: 1.5vw;
							}
					}
				}
			}
			
			video, iframe {
				width: 100%; height: auto;
			}
		}
		
		p {
			text-align: center;
			padding-top: 1rem;
			text-shadow: $small-black-text-shadow;
			span:first-child {
				margin-left: calc(10px + .5vw);
			}
		}
		
		.panel-buttons {
			display: flex;
			
			.btn {
				width: 100%;
				
				&:nth-child(2) {
					margin-left: 1vw;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	
	#videos-content {
		header {
			h3 {
// 				margin: 1.6rem 0 1rem;
// 				text-align: center;
// 				text-transform: uppercase;
				font-size: 1.8rem;
			}
		}
		.panel {
			padding-bottom: 3rem;
			h4 {
				text-align: left;
				padding-left: 12px;
				margin-bottom: 1rem;
			}
			p {
// 				font-size: 1.6vw;
			}
			.panel-buttons {
				
// 				padding: 0 5vw;
				.btn {
					
					
				}
			}
		}
	}
}


@include media-breakpoint-up(lg) {
	#videos-content {
		.panel {
			
			h4 {
				font-size: 1.8vw;
			}
			p {
				font-size: 1.6vw;
			}
			.panel-buttons {
				
				padding: 0 5vw;
				.btn {
					
					
				}
			}
		}
	}
}


@include media-breakpoint-up(xl) {
	#videos-content {
		.panel {
			
			h4 {
				font-size: 1.5rem;
			}
			p {
				font-size: 1.3rem;
			}
			.panel-buttons {
				
// 				padding: 0 5vw;
				.btn {
					
	
		
			// FIXING BUTTON CORNERS FOR LARGE SCREENS
				
					padding-left: 23px;
					-webkit-clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
				    clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
				    &:before {
					    -webkit-clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
					    clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
					}				
				}
			}
		}
	}
}
