& {
	background-size: cover;
	position: relative;
	display: flex;
	align-self: flex-start;
	background-position: center top;
}

#minisite-content {
	text-align: center;
	overflow: scroll !important;
	h1 {
		margin: 110px auto 1em;
		text-align: center;
		img {
			max-width: 90%;
			margin: 0 auto;
		}
	}

	h2 {
		filter: drop-shadow(0 0 .2em rgba(0,0,0,1));
		text-align: center;
// 		text-transform: uppercase;
		margin: 0 auto 1em;
		max-width: 84%;
		strong {
			font-weight: 500;
			white-space: nowrap;
			.lowercase-mac {
				text-transform: lowercase;
			}
		}
	}

	h3 {
		text-align: center;
		margin: 1.5em auto .7em;
		font-size: 2em;
		text-transform: uppercase;
		max-width: 84%;

	}

	p, ul, #minisite-dlcs h3 {
		text-shadow: 0px 0px .3em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6);
	}

	abbr {
		text-decoration: none;
	}

	.buy-button {
		display: inline-block;
		font-size: 2em;
		margin: 1em auto;
		padding: .4em 1.5em;
		box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
		background-color: rgba(0, 0, 0, 0.6);
		text-transform: uppercase;
		font-weight: bold;
		text-shadow: 0px 0px .3em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6);
		will-change: background-color, color;
		transition: background-color .2s linear, color .2s linear;
		&:hover {
			color: white;
			text-decoration: none;
			background-color: black;
		}
	}

	#minisite-video {
		margin: 1em auto;
		text-align: center;
		width: 768px;
		max-width: 95%;
		position: relative;
		padding: calc(10px + 51%) 10px 10px;
		box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
	//		background-color: rgba(58, 39, 0, 0.7);
		background-color: rgba(0, 0, 0, 0.4);
		iframe, video {
			position: absolute;
			top: 10px; left: 10px;
			bottom: 10px; right: 10px;
			height: calc(100% - 20px);
			width: calc(100% - 20px);
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
		}
		video {
			box-shadow: none;
			filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.7));
		}
	}

	#minisite-header {

	}

	#minisite-description {
		font-size: 1.3em;
		margin: 0 auto;
		width: 800px;
		max-width: 95%;
		padding: 8%;


		p:last-child {
			margin-bottom: 0;
		}
	}

	.minisite-screenshots {
		text-align: center;
		margin: 3em 0;
		a {
			overflow: hidden;
			padding: 10px 10px 10px;
			box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
			background-color: rgba(0, 0, 0, 0.4);
			display: inline-block;
			max-width: 48vw;
			margin: 1vw .5vw;
			will-change: background-color;
			transition: background-color .2s linear;
			img {
				box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
				will-change: transform;
				transition: transform .2s linear;
				width: 100%;
	// 				max-width: 150px;
			}
			&:hover {
				img {
					transform: scale(1.02);
				}
				background-color: rgba(0, 0, 0, 1);
			}
			&:active {
				img {
					transform: scale(.97);
				}
			}
		}
	}

	#minisite-features {
		font-size: 1.3em;
		margin: 0 auto;
		width: 800px;
		max-width: 100%;

		ul {
			list-style: none;
			padding: 0;
			max-width: 95%;
			padding: 8%;
			margin: 0 auto;
			li {
				padding-top: 1em;
				strong {
					display: block;
				}
			}
		}
		p {
			margin-left: auto;
			margin-right: auto;
			max-width: 95%;
			padding: 8%;
			+ ul {
				margin-top: -.5em;
				margin-bottom: 1em;
				width: 100%;
				padding: 0;
				li {
					padding: 0;
					margin-top: .5em;
				}
			}
		}

	}

	#minisite-dlcs {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
		h3 {
			flex: 0 0 100%;
		}
		ul {
			padding: 0;
			justify-content: center;
			display: flex;
			flex-direction: row;
			list-style: none;
			flex: 0 0 98%;
			max-width: 100%;
			flex-wrap: wrap;
			li {
				max-width: 90%;
				flex: 0 1 768px;
				margin: 1em auto;
				padding: 1.5em;
				box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
				background-color: rgba(0, 0, 0, 0.4);
				> div {
					margin-bottom: 1em;
					flex-direction: row;
					flex-wrap: wrap;
					display: flex;
					align-items: center;
					> img {
						flex: 0 0 100px;
						margin: 0 auto 1em;
					}
					h4 {
						flex: 0 0 100%;
// 						text-align: left;
						margin: 0;
						font-weight: bold;
					}
					.buy-button {
						font-size: 1.5em;
					}
				}
				p {
					font-size: 1.1em;
				}
				.minisite-screenshots {
					margin: 0 -1em;
					a {
						margin: 1% 1%;
						padding: 2%;
						max-width: 48%;
					}
				}
				@media screen and (min-width: 506px) {
					> div h4 {
						padding: 0 20px;
						flex: 1 0 calc(100% - 100px);
						text-align: left;
					}
				}
				@include media-breakpoint-up(md) {
					text-align: left;
					> div {
						flex-wrap: nowrap;
						h4 {
							padding: 0 30px;
							flex: 1 1 100%;
						}
					}
					.minisite-screenshots {
						a {
							margin: .5%;
							max-width: 24%;
							padding: 1.5%;
						}
					}
				}
			}
		}
	}

	#minisite-news {
		h3 {
			text-shadow: 0px 0px .3em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6);
		}
		ul {
			list-style: none;
			padding: 0;
			margin: 1em auto;
			width: 1000px;
			max-width: 90%;
			li {
				article {
					position: relative;
					margin: 1em auto;
					padding: 1.5em;
					box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
					background-color: rgba(0, 0, 0, 0.4);
					will-change: background-color, transform;
					transition: background-color .2s linear, transform .2s linear;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;

					&:hover {
						background-color: rgba(0, 0, 0, .8);

						a.stretched-link {
							border-color: #7c7c7c;
							text-decoration: none;
						}
					}

					a.stretched-link {
						display: flex;
						margin: 1em auto 0;
						align-self: flex-end;
						flex: 0 0 auto;
						padding: .5em .7em;
						border: 1px solid black;
						box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
						background-color: rgba(0, 0, 0, 0.4);
						will-change: border-color;
						transition: border-color .2s linear;
					}


					&:active {
						transform: scale(.98);
					}

					time {
						font-style: italic;
						font-size: 1em;
						flex: 0 0 100%;
					}

					h4 {
						margin: .5em 0 .8em;
						font-size: 1.6em;
						font-weight: bold;
						flex: 0 0 100%;
					}

					p {
						margin-bottom: 0;
						font-size: 1.3em;
						flex: 0 0 100%;
					}
				}
			}
		}
	}


	@include media-breakpoint-up(md) {
		h1 {
			margin-top: 120px;
		}

		#minisite-video {
			padding-top: 0;
			height: 440px;
		}

		#minisite-header {

		}

		#minisite-description {
			padding: 1.2em 1.5em;
		}

		.minisite-screenshots {
			a {
				max-width: 300px;
				margin: .5em .6em;
			}
		}

		#minisite-features {
			ul {
				padding: 1.5em;
			}
			p {
				padding: 1.5em;
			}
		}

		#minisite-news {
			ul {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				li {
					display: flex;
					flex: 0 1 48%;
					&:nth-of-type(odd):last-of-type {
						flex: 1 1 100%;
						margin: 0;
					}
				}
			}
		}
	}
}
