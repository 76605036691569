#search-form {
	background-color: #000;
	color: #FFF;
	text-align: center;
	
	form {
		display: flex;
		margin: .35em auto .8em;
		width: 610px;
		font-size: 1.3rem;
// 		background: white;
// 		border-radius: 3px;
	}
	
	input {
		padding: .5em .7em;
		width: 100%;
		
		border: none;
		border-right: solid 2px #000;
		color: $feral-orange;
		border-radius: 4px 0px 0px 4px;
	}
	
	button {
		padding: .5em 1.8em;
		border: solid 2px #fff;
		border-left: none;
		background-color: $feral-orange;
		color: #fff;
		transition: background .2s linear;
		border-radius: 0px 4px 4px 0px;
		&:hover {
			background-color: black;
			border: solid 2px $feral-orange;
			border-left: none;
		}
	}
	
	&:before {
		display: none;
	}
}

#search-results {
	color: $body-color;
	padding-bottom: 6rem;
	&:before {
		@include gradient-x(rgba(255,255,255,.9) 10%, rgba(255,255,255,.7));
		height: 100%;
	}
	
	h3 {
		position: relative;
		text-align: center;
		padding: 1em;
		margin: 0;
		
		em {
			color: $feral-orange;
			white-space: nowrap;
			padding-right: .1em;
		}
	}
	
	nav {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		border-bottom: solid 4px #000;
		
		
		
		&:after {
			position: absolute;
			display: block;
			content: '';
			bottom: 0px; left: 0px; right: 0px;
			background-color: #000;
			height: 1px;
		}
		
		a.btn.btn-dark {
			margin-left: 1vw;
			margin-right: 1vw;
// 			padding: 7px 2vw;
// 			width: 30vw;
			background-color: transparent;
			
			&:hover:before {
				background-color: $feral-orange;
			}
		}
		
		a.btn.btn-dark.active {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
			padding-bottom: 12px;
			pointer-events: none;
			margin-bottom: -1px;
			&:before {
				background-color: #000;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}
			
			&:hover {
				background-color: transparent;
			}
		}
	}
	
	.btn {
		
		// FIXING BUTTON CORNERS FOR LARGE SCREENS
		@include media-breakpoint-up(xl) {
			padding-left: 23px;
			-webkit-clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
		    clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
		    &:before {
			    -webkit-clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
			    clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
			}
			
		}
	}
}

#search-results-tabs {
	margin: 1rem auto;
	width: 100%;
	max-width: 1200px;
	ul {
		list-style: none;
		padding: 0px; margin-bottom: 0px;
		display: none;
		
		li {
			width: 100%;
			border-bottom: solid 2px #000;
			
			&:last-child {
// 				border-bottom: none;
			}
		}
		
		&.active {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	
	.panel {
		position: relative;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		
		&:before {
			position: absolute;
			content: '';
			top: 0px; left: 0px;
			bottom: 0px; right: 0px;
background: -moz-linear-gradient(45deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 56%, rgba(255,255,255,0.7) 74%, rgba(255,255,255,0.9) 94%, rgba(255,255,255,0.9) 100%);
background: -webkit-linear-gradient(45deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 56%, rgba(255,255,255,0.7) 74%, rgba(255,255,255,0.9) 94%,rgba(255,255,255,0.9) 100%);
background: linear-gradient(45deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 56%, rgba(255,255,255,0.7) 74%, rgba(255,255,255,0.9) 94%,rgba(255,255,255,0.9) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6ffffff', endColorstr='#e6ffffff',GradientType=1 );
 

			z-index: 0;
		}
	}
	
	.panel-body {
		position: relative;
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		padding: 10px;
		min-height: 5.2em;
		margin-bottom: .5em;
		img {
			height: 64px;
			width: 64px;
			vertical-align: top;
		}
		p {
			margin: 0px 10px;
			width: 100%;
			font-size: 1rem;
			line-height: 1.4em;
			align-self: center;
			text-shadow: 0px 0px 1px white, 0px 0px 10px white, 0px 0px 2px white, 0px 0px 5px white, 0px 0px 7px white, 0px 0px 1px white, 0px 0px 10px white, 0px 0px 2px white, 0px 0px 5px white, 0px 0px 7px white;
			em {
				color: #F15A24;
				font-style: normal;
				font-weight: 500;
			}
		}
		aside {
// 			align-self: right;
			align-items: start;
			color: #000;
			font-size: 1.4rem;
			opacity: 1;
			min-width: 50px;
			width: 50px;
			text-shadow: $small-white-text-shadow;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			span {
				display: flex;
				align-self: center;
				margin-top: .125em;
				margin-bottom: .125em;
				
				&.icon-linux::before {
					content: '\e80c';
				}
				
				// icons in sets of 3 or 4
				&:nth-child(1):nth-last-of-type(4), 
				&:nth-child(1):nth-last-of-type(3), 
				&:nth-child(2):nth-last-of-type(3), 
				&:nth-child(2):nth-last-of-type(2), 
				&:nth-child(3), 
				&:nth-child(4) { 
					font-size: 0.7em;
					margin-top: .2em;
					margin-bottom: .2em;
				}
				// icons in sets of 5 or 6
				&:nth-child(1):nth-last-of-type(5), 
				&:nth-child(1):nth-last-of-type(6), 
				&:nth-child(2):nth-last-of-type(4), 
				&:nth-child(2):nth-last-of-type(5), 
				&:nth-child(3):nth-last-of-type(3), 
				&:nth-child(3):nth-last-of-type(4), 
				&:nth-child(4):nth-last-of-type(2), 
				&:nth-child(4):nth-last-of-type(3), 
				&:nth-child(5), 
				&:nth-child(6) { 
					font-size: 0.7em;
					margin-top: .1em;
					margin-bottom: .1em;
				}
				// reduce size of specific platform icons in sets of 6
				&.icon-switch, &.icon-windows { 
					&:nth-child(1):nth-last-of-type(5), 
					&:nth-child(1):nth-last-of-type(6), 
					&:nth-child(2):nth-last-of-type(4), 
					&:nth-child(2):nth-last-of-type(5), 
					&:nth-child(3):nth-last-of-type(3), 
					&:nth-child(3):nth-last-of-type(4), 
					&:nth-child(4):nth-last-of-type(2), 
					&:nth-child(4):nth-last-of-type(3), 
					&:nth-child(5), 
					&:nth-child(6) {
						font-size: .6em;
						margin-left: .1em;
						margin-right: .1em;
					}
				}
			}
		}
	}
	
	.panel-buttons {
		position: relative;
		display: flex;
		padding: 0px 10px 10px;
		justify-content: center;
		
		.btn {
			margin-right: 5px;
			width: calc(50% - 2px);
			&:last-child {
				margin-right: 0px;
			}
			&:first-child:last-child {
				width: 100%;
			}

		}
	}
}

#search-results {
	&.loading-results {
		nav#search-pagination {
		    pointer-events: none;
		    a {
			    opacity: .5;
		    }
		}
	}
	nav#search-pagination {
	    max-width: 1200px;
	    margin: 1rem auto 0;
	    justify-content: center;
	    border: none;
	    &::after {
		    background-color: transparent;
	    }
    
	    a {
		    width: auto;
		    font-size: 1.2em;
		    line-height: 1.2em;
		    padding:  .15em .1em 0em;
		    min-width: 1.47em;
		    text-align: center;
		    margin: 0 .1em 1.5rem;
		    transition: background .2s linear, color .2s linear;
		    border-bottom: 2px solid transparent;
//		    display: none;
		    position: relative;
		    background-color: transparent;
		    &:hover {
			    border-bottom: 2px solid $feral-orange;
			    text-decoration: none;
			    background-color: transparent;
			    border-bottom: 2px solid transparent;
			    background-color: $white;
			    border-radius: 3px;
		    }
		    
		    &.active {
			    color: $white;
			    border-bottom: 2px solid transparent;
			    background-color: $body-color;
			    border-radius: 3px;
			    pointer-events: none;
		    }
		    span {
			    height: 1.2em;
			    padding:  .05em .0em 0em;
		    }
		    
		    &.loading-results {
			    color: transparent;
			    background-color: $white;
			    &::after {
				    @include fa-icon("\f2f1");
				    color: $feral-orange;
				    font-size: 1em;
				    display: block;
				    position: absolute;
				    top: .2em;
				    left: .25em;
				    -webkit-animation: fa-spin 2s linear infinite;
				    animation:fa-spin 2s linear infinite;
				    transform-origin: 49%;
			    }
		    }
			
			/*
		    
		    &:nth-child(-n+10), &.previous-page, &.next-page {
			    display: inline-block;
		    }
		    
		    &:nth-child(11):not(.next-page) {
			    color: transparent;
			    display: inline-block;
			    pointer-events: none;
			    padding: 0;
			    &::after {
				    content: "...";
				    color: $feral-orange;
				    font-size: 1em;
				    display: block;
				    position: absolute;
				    top: .6em;
				    left: 0em;
			    }
		    }
			
			*/
	    }
	    
	    .ellipsis {
		    font-size: 1.2em;
		    padding:  .4em .2em 0em;
	    }
	    
	    .previous-page {
// 		    padding-right: 0;
	    }
	    
	    .next-page {
// 		    padding-left: 0;
	    }
    }
}

@include media-breakpoint-up(md) {
	
	
	#search-results {
		
		nav {
			
			a {
				width: 15vw;
// 				margin-left: 1vw;
// 				margin-right: 1vw;
				padding: 7px 2em;
			}
		}
	}
	#search-results-tabs {
		width: 100%;
		.panel {
			display: flex;
			flex-direction: column;
			margin: 10px;
			padding: .2vw;
			border-bottom: none;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			width: calc(50% - 20px);
			
			&:before {
				z-index: -1;
			}
			
			&:after {
				position: absolute;
				display: block;
				content: '';
				background-color: #333;
				top: 0px; left: 0px;
				bottom: 0px; right: 0px;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				z-index: -1;
			}
			
			.panel-body {
				padding: .8vw;
				p {
					
					font-size: 1.8vw;
				}
			}
			
			.panel-buttons {
				margin-top: auto;
				.btn {
					width: 170px;
					&:first-child:last-child {
						width: 345px;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	#search-results-tabs .panel {

	}
	#search-results-tabs {
		.panel {
// 		width: calc(33.333333% - 20px);
			padding: 2px;
						
			.panel-body {
				padding: 10px;
				p {
					
					font-size: 22px;
				}
			}
		}
	}
}