// enabled only those functionalities of bootstrap we want to use
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
//@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
//@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
//@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/media";
//@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";

@import "colors";
@import "mixins";
@import "./_animations";


$small-black-text-shadow: 0px 0px 3px black, 0px 0px 1px black, 0px 0px 5px black;
$small-white-text-shadow: 0px 0px 3px white, 0px 0px 1px white, 0px 0px 5px white;
$medium-whte-text-shadow: 0px 0px 1px white, 0px 0px 20px white, 0px 0px 15px white, 0px 0px 10px white, 0px 0px 10px white;
$medium-whte-text-shadow: none;
$largecornerclip: .9rem;


///////////////////////////////////////////////////////////////
// remove! ////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

#top-header, #highlighted-games, #latest-news {
// 	display: none !important;
}

body, html {
	overscroll-behavior-y: none;
}

#vpsize {
	position: fixed;
	height: 20px;
	z-index: 900;
	padding: 5px 10px;
	pointer-events: none;
	top: 35px; // when nav guides are on
	top: 14px;
	left: 0;
	color: white;
	font-size: 9px;
	font-weight: bold;
	text-shadow: 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black;
	
	@include media-breakpoint-up(xs) {
		color: #ff0000;
	}
	@include media-breakpoint-up(sm) {
		color: blue;
	}
	@include media-breakpoint-up(md) {
		color: magenta;
	}
	@include media-breakpoint-up(lg) {
		color: cyan;
	}
	@include media-breakpoint-up(xl) {
		color: yellow;
	}
	
	&::before {
// 		content: 'iMac 27" 2560x1440px';
		position: fixed;
		top: 0;
		left: 0;
		width: 2560px;
		height: 1440px;
		z-index: 900;
// 		border: 1px solid magenta;
		padding: 100px 10px;
		color: magenta;
	}
	
	&::after {
		content: 'size';
		display: block;
		position: fixed;
		text-transform: uppercase;
		z-index: 900;
		top: 0;
		left: 0;
		padding: 7px 10px;
		font-size: 9px;
		font-weight: bold;
		text-shadow: 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black, 0px 0px 4px black;
		pointer-events: none;
		
		@include media-breakpoint-up(xs) {
			content: 'extra small';
	// 		color: #ff0000;
		}
		@include media-breakpoint-up(sm) {
			content: 'small';
	// 		color: blue;
		}
		@include media-breakpoint-up(md) {
			content: 'medium';
	// 		color: magenta;
			
			// nav guides
		
	// 		border: 1px solid grey;
	// 		border-left: 0;
	// 		border-right: 0;
	// 		width: 100vw;
	// 		top: 19px;
	// 		height: 36px;
	
		}
		@include media-breakpoint-up(lg) {
			content: 'large';
	// 		color: cyan;
		}
		@include media-breakpoint-up(xl) {
			content: 'extra large';
	// 		color: yellow;
		}
	
	}
}

// eek
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

body {
	max-width: 100vw;
	overflow: auto;
	overflow-x: hidden;
	overflow-y: auto;
	font-family: Interstate, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

// https://keithclark.co.uk/articles/pure-css-parallax-websites/demo3-webkit-overflow-fix/ TODO: consider using this
//.jarallax {
//	background-attachment: fixed;
//	background-position: center;
//	background-repeat: no-repeat;
//	background-size: cover;
//}

#growlNotifications {
	position: fixed;
	top: 1em; right: 1em;
	z-index: 5000;
	padding: 0;
	list-style: none;
	width: calc(100% - 2em);
	max-width: 500px;
	&:empty {
		display: none;
	}
	
	li {
		border-radius: 4px;
		margin-bottom: .5em;
		color: white;
		padding: 1em 2.8em 1em 1.2em;
		border: solid 2px rgba(255, 255, 255, 0.7);
		outline: solid 2px #C00;
		background: rgba(208, 32, 32, 0.9);
		cursor: pointer; 
		width: 100%;
		position: relative;
		transition: border-color .2s linear, background-color .2s linear, outline-color .2s linear;
		&::after {
			content: "\e813";
			font-family: "feral-icons";
		    font-style: normal;
		    position: absolute;
		    top: .95em; 
		    right: .95em;
		}
		&:hover {
			background: rgba(176, 20, 20, 1);
			border-color: white;
			outline-color: rgba(176, 20, 20, 1);
		}
	}
}

.icon-mac, a[href$="mac"], a[href$="/mac/"], a.MAC {
	&:not(.btn) {
		order: 1;
		
		#primary-breadcrumbs & {
			order: 0;
		}
	}
}
.icon-linux, a[href$="linux"], a[href$="/linux/"], a.LINUX {
	&:not(.btn) {
		order: 2;
		
		#primary-breadcrumbs & {
			order: 0;
		}
	}
}
.icon-ios, a[href$="ios"], a[href$="/ios/"], a.IOS {
	&:not(.btn) {
		order: 3;
		
		#primary-breadcrumbs & {
			order: 0;
		}
	}
}
.icon-android, a[href$="android"], a[href$="/android/"], a.ANDROID {
	&:not(.btn) {
		order: 4;
		
		#primary-breadcrumbs & {
			order: 0;
		}
	}
}
.icon-switch, a[href$="switch"], a[href$="/switch/"], a.SWITCH {
	&:not(.btn) {
		order: 5;
		
		#primary-breadcrumbs & {
			order: 0;
		}
	}
}
.icon-windows, a[href$="windows"], a[href$="/windows/"], a.WINDOWS {
	&:not(.btn) {
		order: 6;
		
		#primary-breadcrumbs & {
			order: 0;
		}
	}
}
.icon-tvos, a[href$="tvos"], a[href$="/tvos/"], a.TVOS, a.tvos {
	display: none !important; // remove if we ever release an apple tv product
	&:not(.btn) {
		order: 7;
		
		#primary-breadcrumbs & {
			order: 0;
		}
	}
}
.icon-meta-quest, a[href$="mquest"], a[href$="/mquest/"], a.MQUEST {
	&:not(.btn) {
		order: 8;
		
		#primary-breadcrumbs & {
			order: 0;
		}
	}
}


.btn {
	position: relative;
	display: inline-block;
	padding: 7px 20px;
    background-color: #000;
    position: relative;
    -webkit-clip-path: polygon(15px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 15px);
    clip-path: polygon(15px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 15px);
    transition: .2s background-color ease-out, .2s color ease-out;
	border: none;
    border-radius: 5px;
	text-align: center;
	text-transform: uppercase;
	font-weight: normal;
// 	white-space: nowrap;
// 	min-width: 120px;
	
	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 2px; left: 2px;
		bottom: 2px; right: 2px;
	    display: block;
	    -webkit-clip-path: polygon(16px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 16px);
	    clip-path: polygon(16px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 16px);
	    border-radius: 3px;
	    font-size: 1.3em;
	    transition: .2s background-color ease-out, .1s color ease-out;
		z-index: -1;
	}
	
	&:hover {
		background-color: #FFF;
		text-decoration: none;
	}
	
	&.btn-success {
		color: #000;
		font-weight: 500;
		&:before {
			background-color: $feral-orange;
		}
		
		&[disabled] {
			cursor: not-allowed;
			color: #555;
			background-color: #555;
			
			&:before {
				background-color: #999;
			}
		}
	}
	
	&.btn-info {
		color: #FFF;
		
		&:before {
			
			background-color: #5c5c5c;
			background-color: #808080;
			background-color: #0074b5;
			background-color: #1d6e9c;
		}
	}
	
	&.btn-light {
		color: #000;
		font-weight: 500;
		
		&:before {
			background-color: #FFF;
		}
		
		&:hover:before {
			background-color: $feral-orange;
		}
	}
	
	&.btn-dark {
		color: #FFF;
		
		&:before {
			background-color: #333;
		}
				
		&.support::before {
			background-color: #43772b;
		}
	}
	
	&.btn-glass {
		color: #FFF;
		background-color: rgba(0,0,0,.2);
		
		&:before, &:hover {
			background-color: rgba(255,255,255,.2);
		}
	}
	
	&.loading {
		&.btn-success {
			color: $feral-orange;
		}
		
		&:hover {
			background-color: #000;
		}
		
		&:after {
			position: absolute;
			top: calc(50% - 12px);
			left: calc(50% - 12px);
			content: 'test';
			color: #000;
			@include fa-icon('\f110');
			animation: fa-spin 1s steps(8) infinite;
		}
	}
}

@include media-breakpoint-up(sm) {
	.btn {
		-webkit-clip-path: polygon(1.25vw 0px, 100% 0px, 100% 100%, 0px 100%, 0px 1.25vw);
	    clip-path: polygon(1.25vw 0px, 100% 0px, 100% 100%, 0px 100%, 0px 1.25vw);
	    &:before {
		    -webkit-clip-path: polygon(1.25vw 0px, 100% 0px, 100% 100%, 0px 100%, 0px 1.25vw);
		    clip-path: polygon(1.25vw 0px, 100% 0px, 100% 100%, 0px 100%, 0px 1.25vw);
		}
	}
}

@include media-breakpoint-up(md) {
	
}

@include media-breakpoint-up(lg) {
	
}

@include media-breakpoint-up(xl) {
	.btn {
		-webkit-clip-path: polygon(1.10vw 0px, 100% 0px, 100% 100%, 0px 100%, 0px 1.10vw);
	    clip-path: polygon(1.10vw 0px, 100% 0px, 100% 100%, 0px 100%, 0px 1.10vw);
	    &:before {
		    -webkit-clip-path: polygon(1.10vw 0px, 100% 0px, 100% 100%, 0px 100%, 0px 1.10vw);
		    clip-path: polygon(1.10vw 0px, 100% 0px, 100% 100%, 0px 100%, 0px 1.10vw);
		}
	}
}

label.checkable {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	
	&:before {
		@include fa-icon('\f00c');
		display: block;
		border: solid 1px #FFF;
		padding: 2px; margin-right: 8px;
		color: transparent;
	}
	
	&.checked:before {
		color: $feral-orange;
	}
	
	input[type=checkbox] {
		display: none;
	}
}

*[data-opener-for] {
	cursor: pointer;
	
	&:hover {
		text-decoration: underline;
	}
	
	&:before {
		@include fa-icon("\f0da");
		margin-right: 10px;
	}
	
	&.open {
		&:hover {
			text-decoration: none;
		}
		
		&:before {
			content: "\f0d7";
		}
	}
}

.opener {
	display: none;
	
	&.open {
		display: block;
	}
}

.divider-svg {
	display: block !important;
	position: absolute;
	top: -50px; left: 0px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	width: 100% !important; height: 80px !important;
	max-width: none !important;
}

.svg-divider-after {
	padding-bottom: 50px;
}

.svg-divided {
	padding-top: 35px;
}

span.price {
	position: relative;
	margin: 0px;
	display: inline-block;
	white-space: nowrap;

	del {
		position: absolute;
		font-size: 70%;
		text-decoration: none;
		color: #9e9e9e;
		top: -.9em;
		right: 0;
		
		&:before {
			content: '';
			position: absolute;
			top: calc(50% - 1px); left: -9%;
			width: 118%; height: 3px;
			background-color: $danger;
			transform: rotate(-5deg);
			opacity: .7;
		}
	}
	
	ins {
		display: block;
		color: $success;
		font-size: 90%;
		text-decoration: none;
		font-weight: 500;
	}
}
	
a, a:hover {
	color: $feral-orange;
}

#primary-nav {
	position: fixed;
	top: 0px; left: 0px;
	display: flex;
	width: 100%;
	background-color: rgba(0,0,0,0.8);
	z-index: 100;
	
	&.open-menu {
		position: fixed;
		top: 0;
	}
	
	h1 {
		margin: 0px 5px;
		width: 147px;
		height: 26px;
		transition: width .5s linear, margin-right .5s linear;
		z-index: 0;
		a {
			display: block;
			background-image: url('/images/logo-horizontal.svg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: -33px center;
			color: transparent;
			width: 100%; height: 100%;
			overflow: hidden;
		}
		@media only screen and (max-width: 969px) and (min-width: 768px) {
			&.shrink {
				width: 30px;
				background-position: left center;
				margin-right: 3px;
			}
		}
	}
	
	.page-controls, .account-controls {
		display: flex;
		background-color: rgba(0,0,0,0.9);
		margin: 3px; padding: 3px;
		@include border-radius(3px);
		
		&:first-child {
			margin-top: 0px;
		}
		
		label {
			margin: 0px;
			width: 30%;
			color: #FFF;
			align-self: center;
		}
		
		select, button, input {
			margin-left: 5px;
			flex: 1 1 auto;
			align-self: center;
		}
		
		select {
			max-width: calc(70% - 5px );
		}
		
		a {
			width: auto;
			align-self: center;
			margin: 0px 3px;
		}
	}
	.account-controls {
		@include media-breakpoint-down(lg) {
			[lang="de"] & {
				flex-wrap: wrap;
				height: 115px;
				label {
					width: 100%;
					padding-top: .5em;
					padding-bottom: .5em;
				}
				a {
					margin-bottom: .5em;
					height: 50px;
				}
			}
		}
	}
}
	
#primary-nav-pages {
	align-self: center;
	flex: 1 1 auto;
	
	ul {
		list-style: none;
		padding: 0px; margin: 0px;
		position: absolute;
		top: 100%;
		background-color: rgba(0,0,0,0.8);
		width: 100%;
		
		a {
			display: block;
			padding: 3px 5px;
// 			width: calc(100% -6px);
			color: #FFF;
			background-color: rgba(0,0,0,0.9);
			margin: 3px; padding: 3px;
			@include border-radius(3px)
		}
	}
}

@include media-breakpoint-down(sm) {
	#primary-nav-pages {
		ul {
			position: fixed;
// 			top: 50px;
			top: 36px;
			bottom: 0;
			right: 0;
			left: 0;
// 			height: 100%;
// 			align-content: stretch;
// 			justify-content: stretch;
// 			flex-direction: column;
// 			height: 100%;
// 			flex-direction: row;
			li {
				display: flex;
				align-content: stretch;
				flex-grow: 1;
				flex-direction: row;
				&.page-controls, &.account-controls, a {
					display: flex;
					flex-grow: 1;
					align-items: center;
					justify-content: center;
					border: 1px solid #303030;
					height: 10vh;
					padding: 0 .5em;
					box-sizing: border-box;
					font-size: 3vh;
					margin: 2px 4px;
// 					flex-direction: row;
					a {
						border: none;
						height: 70%;
						padding: 0 .8em 0 1em;
						white-space: nowrap;
						color: black;
						font-size: .75em;
					}
				}
				
				&:first-child {
					a { 
						margin-top: 1px;
					}
				}
				
				a {
					justify-content: center;
					align-content: center;
					font-weight: bold;
					text-transform: uppercase;
					&:hover, &:active {
						background-color: $feral-orange;
						text-decoration: none;
					}
					
					&.mobile-menu-user-link {
						height: 48px;
						transition: color .15s linear, background-color .15s linear, border-color .15s linear;
						border-radius: 3px;
						padding: 0;
						background-color: black;
						border: 2px solid transparent;
						text-transform: none;
						margin-left: 5px;
						color: $feral-orange;
						font-weight: 400;
						text-align: left;
						justify-content: left;
						img {
							
	// 						margin: 1px;
	// 						border-radius: 3px;
	// 						box-sizing: content-box;
	// 						transition: border-color .15s linear;
						}
						
						span {
							margin: 0 7px 0 10px;
							word-break: break-word; 
							white-space: normal;
							line-height: 1em;
						}
						
						&:hover {
							color: white;
							border-color: $feral-orange;
							img {
	// 							border-color: #1a1a1a;
// 								margin-left: 2px;
							}
							span {
// 								margin-left: 8px;
							}
						}
						
						+ .btn {
							-webkit-clip-path: none;
							clip-path: none; 
							background: white;
							height: 48px;
							margin-right: 0;							
							transition: border .2s linear;
							border: 2px solid black;	
							&:hover {
								border-color: $feral-orange;
								background: white;
							}
							&::before {
								content: none;
							}
						}
					}
					
				}
				
				select {
					border: none;
					border-radius: 3px;
					padding: 0 40px 0 3vw;
					background-image: url('/images/down-arrow.svg')/* , url('/images/line-black.svg') */;
					background-repeat: no-repeat;
					background-position: calc(100% - 17px) center/* , calc(100% - 46px) center */;
					background-size: 12px auto/* , 1px auto */;
					background-color: #FFF;
					position: relative;
					-moz-appearance: none;
				    -webkit-appearance: none;
				    appearance: none;
					height: 48px;
					font-size: .8em;
					font-weight: 500;
					border: 2px solid black;	
					transition: border .2s linear;
					cursor: pointer;
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					&:hover {
						border-color: $feral-orange;
						background-image: url('/images/down-arrow-orange.svg')/*,  url('/images/line-orange.svg') */;
					}
					
					
				}
/*
				
				&.page-controls, &.account-controls, a {
					display: flex;
					flex-grow: 1;
					align-content: center;
					border: 1px solid #4f4f4f;
					height: 10vh;
				}
*/
			}
		}
	}
	
/*
	#primary-nav {
		height: 50px;
		
		#primary-nav-controls {
			.nav-expand, a {
				
				width: 40px;
				height: 40px;
				a {
					width: 40px;
					height: 40px;
					
					&::before {
						font-size: 1.5em;
					}
				}
				&::before {
					font-size: 1.5em;
				}
			}
		}
	}
*/
}

#primary-nav-controls {
	display: flex;
	align-self: center;
	padding: 3px;
	transition: width .2s linear;
	
	a {
		color: #FFF;
		text-decoration: none;
		align-self: center;
		cursor: pointer;
	
		display: inline-block;
		width: 28px;
		height: 28px;
// 			position: absolute;
		top: 0px;
		left: 0px;
		&::before {
			margin: 0;
			font-size: 100%;
			padding: 2px 0;
		}
		&.icon-basket {
			
			position: relative;
			span {
				display: block;
				position: absolute;
				color: $feral-orange;
				font-weight: bold;
				top: .4em;
				left: 0;
				width: 28px;
				text-align: center;
				font-size: 0.55em;
			}
		
			&::before {
				font-size: 90%;
				padding-top: .15em;
			}
		}
		&.icon-menu::before {
			font-size: 85%;
			padding-top: .2em;
		}
		
	}
	
	.nav-expand, > a {
		display: inline-block;
		background-color: rgba(0, 0, 0, 0.9);
		padding: 0;
		margin-left: 3px;
		@include border-radius(3px);
		font-size: 24px;
		width: 30px;
		height: 30px;
		text-align: center;
		overflow: hidden;
		// This breaks it!
// 		position: relative;
		// 
		border: 1px solid transparent;
		transition: border-color .2s linear;
		&:hover {
			border: 1px solid white;
			text-decoration: none;
		}
		&:active {
			border: 1px solid $feral-orange;
			color: $feral-orange;
		}
	}
	
	
	
	ul {
		display: none;
		list-style: none;
		padding: 0px 4px 4px; margin: 0px;
		position: fixed;
		top: 36px; 
		left: 0px;
		right: 0;
		bottom: 0;
	
		overflow: auto;
		overflow-x: hidden;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.8+0,1+100;Neutral+Density */
background: -moz-linear-gradient(top,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
		border-radius: 0px 0px 0px 5px;
		width: 100%;
		
		li {
			font-size: .9rem;
			background-color: #1a1a1a;
			margin-bottom: 4px;
			border-radius: 3px;
			border: 1px solid rgba(66, 65, 66, 0.5);
			
			&:last-child {
				margin-bottom: 0px;
			}
			
			p {
				margin: 0px 10px;
				align-self: center;
				color: #FFF;
				overflow: hidden;
				line-height: 21.333333px;
				max-height: 64px;
				width: 100%;
			}
			
			.search-header {
				text-align: left;
				font-size: 1.2em;
				padding-left: .3em;
				max-width: calc(100% - 15px);
			}
			
			img + .search-header {
				max-width: calc(100% - 132px);
				padding-left: 0;
			}
			
			.search-more {
				align-self: center;
				padding-left: 0;
				display: none;
				margin: 0px 10px;
				max-width: calc(100% - 22px);
				width: 100%;
			}
			
			.search-header em, .search-more em {
				color: $feral-orange;
				font-style: normal;
				font-weight: 500;
			}
			
			img + p + .search-more {
				max-width: calc(100% - 132px);
			}
			
			&:hover {
// 				background: black;
				
				.search-header {
					display: none;
				}
				
				.search-more {
					display: block;
				}
			}
		}
		
		.icon {
			width: 64px; height: 64px;
			flex-grow: 0;
			flex-shrink: 0;
		}
	}
}

#primary-nav.nav-expanded {
	position: fixed;
	
	
}

#primary-nav-pages ul, .nav-header, .nav-expand > ul, #primary-nav-controls #primary-nav-settings, .nav-expand > a span, .nav-expand > a.icon-close {
	display: none;
}

#primary-nav.nav-expanded {
	#primary-nav-pages {
		flex-grow: 0;
		flex-shrink: 0;
		width: 0px;
	}
	
	#primary-nav-controls {
		position: relative;
		width: 100%;
	}
	
	.nav-expand {
		&:hover, &:active {
			border-color: transparent;
		}
		
	}
	
	&.nav-expand-search #primary-nav-search {
		border-color: white;
	}
	
	&.nav-expand-search #primary-nav-search, &.nav-expand-basket #primary-nav-basket, &.nav-expand-settings #primary-nav-settings {
		display: flex;
		margin-left: 0px;
		flex: 1 1 auto;
// 		z-index: 10;
		a.icon-close, .nav-header {
			display: block;
			align-self: center;
		}
		
		a.icon-close {
			width: 26px;
		}
		
		.btn {
			margin: 0px;
			display: inline-block;
			width: calc(50% - 2px);
			font-size: .85em;
			padding: .4em 17px;

			&:nth-child(n+3) {
				margin-top: 2px;
			}
			
			&:first-child:last-child {
				width: calc(100% - 40px);
			}
			&.btn-success {
				color: #000;
			}
		}
		
		ul {
			display: block;
		
			&:empty {
				display: none;
			}
		}
		
		.nav-header {
			display: block;
			margin: 0px 5px;
			flex: 1 1 auto;
			color: $feral-orange;
			font-size: 16px;
			align-self: center;
			text-transform: uppercase;
			
			input {
				width: 100%;
				border: none;
				background: none;
				color: $feral-orange;
				-webkit-appearance: none;
				padding: 0px;
				outline: none;
				
				&::-webkit-search-decoration,
				&::-webkit-search-cancel-button,
				&::-webkit-search-results-button,
				&::-webkit-search-results-decoration { display: none; }
			}
		}
	}
	
	&.nav-expand-search {
		#primary-nav-basket, #primary-nav-settings {
			display: none;
		}
	}
	
	&.nav-expand-basket {
		#primary-nav-search, #primary-nav-settings {
			display: none;
		}
		a.icon-basket {
			pointer-events: none;
		}
	}
	
	&.nav-expand-settings {
		#primary-nav-search, #primary-nav-basket {
			display: none;
		}
	}
	
	a.icon-menu {
		display: none;
	}
	
	#primary-nav-search {
		li {
			padding: 5px;
			display: flex;
			height: 76px;
		}
		
		aside {
			align-self: center;
			color: #FFF;
			font-size: 1.4rem;
			opacity: .7;
			min-width: 50px;
			width: 50px;
			text-shadow: $small-black-text-shadow;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			span {
				display: inline-block;
				align-self: center;
				margin-top: .125em;
				margin-bottom: .125em;
				
				// icons in sets of 3 or 4
				&:nth-child(1):nth-last-of-type(4), 
				&:nth-child(1):nth-last-of-type(3), 
				&:nth-child(2):nth-last-of-type(3), 
				&:nth-child(2):nth-last-of-type(2), 
				&:nth-child(3), 
				&:nth-child(4) { 
					font-size: 0.7em;
					margin-top: .2em;
					margin-bottom: .2em;
				}
				// icons in sets of 5 or 6
				&:nth-child(1):nth-last-of-type(5), 
				&:nth-child(1):nth-last-of-type(6), 
				&:nth-child(2):nth-last-of-type(4), 
				&:nth-child(2):nth-last-of-type(5), 
				&:nth-child(3):nth-last-of-type(3), 
				&:nth-child(3):nth-last-of-type(4), 
				&:nth-child(4):nth-last-of-type(2), 
				&:nth-child(4):nth-last-of-type(3), 
				&:nth-child(5), 
				&:nth-child(6) { 
					font-size: 0.7em;
					margin-top: .1em;
					margin-bottom: .1em;
				}
				// reduce size of specific platform icons in sets of 6
				&.icon-switch, &.icon-windows { 
					&:nth-child(1):nth-last-of-type(5), 
					&:nth-child(1):nth-last-of-type(6), 
					&:nth-child(2):nth-last-of-type(4), 
					&:nth-child(2):nth-last-of-type(5), 
					&:nth-child(3):nth-last-of-type(3), 
					&:nth-child(3):nth-last-of-type(4), 
					&:nth-child(4):nth-last-of-type(2), 
					&:nth-child(4):nth-last-of-type(3), 
					&:nth-child(5), 
					&:nth-child(6) {
						font-size: .6em;
						margin-left: .1em;
						margin-right: .1em;
					}
				}
			}
		}
	}
	
	#primary-nav-basket {
		ul li {
			display: flex;
			margin: 0px 0px 5px; padding: 5px;
			height: 76px;
			
			form {
				margin: 0px;
			}
			
			.img {
				width: 64px; height: 64px;
				transition: opacity .2s linear;
				
				> span {
					opacity: 0;
					pointer-events: none;
					position: absolute;
					padding-top: 13px;
					top: 0px; left: 0px;
					background-color: rgba(0,0,0,.7);
					width: 100%; height: 100%;
					color: #FFF;
					text-transform: uppercase;
					font-size: .7rem;
					transition: opacity .2s linear;
					span {
						color: $feral-orange;
						border: solid 2px;
						border-radius: 4px;
						font-size: 1.2rem;
						width: 1.5rem; height: 1.5rem;
						margin-bottom: 5px;
						line-height: 1.1em;
					}
				}
				
				/*
				
				&:before {
					top: 10px;
					left: calc(50% - 13px);
					display: block;
					font-family: "feral-icons";
					content: '\e813';
					position: absolute;
					text-align: center;
					width: 26px;
					height: 26px;
					border-radius: 2px;
					line-height: 1.6em;
					color: $feral-orange;
					background: black;
					border: 2px solid $feral-orange;
					box-shadow: 0px 0px 3px black;
					z-index: 1;
					opacity: 0;
					transition: opacity .2s linear;
				}
				
				&:after {
					bottom: 6px; left: 0px;
					display: block;
					content: 'Remove';
					position: absolute;
					text-align: center;
					font-size: 12px; 
					font-weight: bold;
					width: 100%;
					color: #FFF;
					text-transform: uppercase;
					text-shadow: $small-black-text-shadow;
					opacity: 0;
					transition: opacity .2s linear;
				}
				
				*/
			}
			
			&:hover .img {
				img {opacity: .5;}
				> span {
					opacity: 1;
				}
			}
			
			p {
				text-align: left;
				font-size: 1.2em;
				padding-left: .3em;
				max-width: calc(100% - 15px);
			}
			
			
			&:last-child {
				display: flex;
				height: auto;
				margin-bottom: 0px;
				
				p {
					text-align: left;
					width: auto;
					margin-right: 40px;
					margin-left: 0;
					text-transform: uppercase;
					font-size: .7rem;
					
					span {
						display: block;
						font-size: 1rem;
						text-align: left;
						del {
// 							transform: translateY(5px);
// 							width: 100%;
							right: auto;
							margin-left: 1px;
							&::before {
								left: -5%;
								width: 110%;
								height: 2px;
							}
						}
						ins {
							transform: translateY(1px);
						}
					}
				}
				
				.btn {
					flex: 1 1 auto;
					margin-right: 10px;
										
					// FIXING BUTTON CORNERS FOR LARGE SCREENS
					$largecornercheckoutclip: .7rem;
					@include media-breakpoint-up(lg) {
						color: pink;
						padding-left: 22px;
						-webkit-clip-path: polygon($largecornercheckoutclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornercheckoutclip);
					    clip-path: polygon($largecornercheckoutclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornercheckoutclip);
					    &:before {
						    -webkit-clip-path: polygon($largecornercheckoutclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornercheckoutclip);
						    clip-path: polygon($largecornercheckoutclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornercheckoutclip);
						}
						
					}
					
					// FIXING BUTTON CORNERS FOR XLARGE SCREENS
/*
					$xlargecornercheckoutclip: .7rem;
					@include media-breakpoint-up(xl) {
						color: pink;
						padding-left: 22px;
						-webkit-clip-path: polygon($xlargecornercheckoutclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $xlargecornercheckoutclip);
					    clip-path: polygon($xlargecornercheckoutclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $xlargecornercheckoutclip);
					    &:before {
						    -webkit-clip-path: polygon($xlargecornercheckoutclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $xlargecornercheckoutclip);
						    clip-path: polygon($xlargecornercheckoutclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $xlargecornercheckoutclip);
						}
						
					}
*/

				}
			}
		}
		
		.img {
			position: relative;
			padding: 0px;
			border: none;
			background: transparent;
			
			img {
				width: 100%; height: 100%;
			}
		}
		
		aside {
			align-self: center;
			color: #EEE;
			width: 64px;
			text-align: center;
			flex-grow: 0;
			flex-shrink: 0;
			font-size: 1.5rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			
			[class^="icon-"] {
				font-size: 1.3rem;
				margin-left: 0;
				margin-top: .2em;
				&::before {
					margin: 0;
				}
			}
			
			span:nth-child(2):not(.price) {
				margin-left: 5px;
			}
			
			span.price {
				display: block;
				font-size: 1rem;
				width: 100%;
				margin-top: .3em;
				margin-bottom: -.2em;
				order: 8;
// 				flex-grow: 1;
// 				align-self: flex-end;
				del, ins {
					transform: translateY(5px);
					width: 100%;
					&::before {
						left: 15%;
						width: 70%;
						height: 2px;
					}
				}
			}
		}
	}
	
	#primary-nav-settings {
		@include media-breakpoint-down(lg) {
			[lang="de"] & {
				ul li:nth-child(3) {
					flex-wrap: wrap;
					height: 104px;
					label {
						width: 100%;
					}
					a {
						max-width: 47%;
					}
				}
			}
		}
		
		li {
			display: flex;
			align-items: center;
			text-align: left;
			padding: 0 10px;
			height: 68px;
			font-size: 1rem;
			
			a {
				font-size: 1.4rem;
			}
			
			> input, > select, &:last-child > a {
				width: 100%;
			}
			
			&:last-child > a {
				color: $feral-orange;
				font-size: .9rem;
		
								
				&.btn {
					padding: 0px 7px 0;
					height: 48px;
					text-transform: none;
					-webkit-clip-path: none;
						    clip-path: none;
// 					box-sizing: content-box;		
					background-color: white;			
					transition: border .2s linear;
					border: 2px solid black;
					border-radius: 3px;
					display: flex;
					align-content: center;
					align-items: center;
					justify-content: center;
					line-height: 1.2em;
					&:hover {
						border-color: white;
					}
					
					&::before {
						content: none;
					}
					
					
					&:last-child {
						margin-left: .5em;
					}
/*
					
					@include media-breakpoint-up(md) {
						padding: 1em 17px 1em;
					}
*/
				}
				&.btn-light, &.btn-success {
					margin-top: 0px !important;
					color: $body-color;
					
					&:hover {
						border-color: $feral-orange;
					}
				}
				&.btn-success {
					background-color: $feral-orange;
					&:hover {
						border-color: white;
					}
				}
		
				&.btn-info {
					margin-top: 0px !important;
					color: #FFF;
				}


				&.menu-user-link {
					height: 50px;
					transition: color .15s linear, background-color .15s linear, border-color .15s linear;
					border-radius: 3px;
					padding: 3px;
					background-color: black;
					border: 2px solid #1a1a1a;
					display: flex;
					align-items: center;
					img {
						
// 						margin: 1px;
// 						border-radius: 3px;
// 						box-sizing: content-box;
// 						transition: border-color .15s linear;
					}
					span {
						word-break: break-word; 
						white-space: normal;
						line-height: 1em;
						display: flex;
						width: auto;
// 						height: 100%;
						max-width: calc(100% - 55px);
					}
					
					&:hover {
						color: white;
						border-color: $feral-orange;
						img {
// 							border-color: #1a1a1a;
						}
					}
				}
				
				
				span {
					margin-left: 10px;
				}
			}
			
			input[type=range] {
				margin: 0px 10px;
			}
			
			select {
				border: none;
				border-radius: 3px;
				padding: 5px 20px;
				background-image: url('/images/down-arrow.svg')/* , url('/images/line-black.svg') */;
				background-repeat: no-repeat;
				background-position: calc(100% - 17px) center/* , calc(100% - 46px) center */;
				background-size: 12px auto/* , 1px auto */;
				background-color: #FFF;
				position: relative;
				-moz-appearance: none;
			    -webkit-appearance: none;
			    appearance: none;
				height: 48px;
// 				font-size: 1.1em;
				font-weight: 500;
				border: 2px solid black;	
				transition: border .2s linear;
				cursor: pointer;
				&:hover {
					border-color: $feral-orange;
					background-image: url('/images/down-arrow-orange.svg')/*,  url('/images/line-orange.svg') */;
				}
				
				
			}
		}
		
		label {
			flex-shrink: 0;
			margin: 0px;
			margin-right: 10px;
			width: 100px;
			color: #FFF;
			font-weight: bold;
		}
	}
}
@include media-breakpoint-up(sm) {
	#primary-nav.nav-expanded {
		&.nav-expand-search #primary-nav-search, &.nav-expand-basket #primary-nav-basket, &.nav-expand-settings #primary-nav-settings {
			
			.btn {
				font-size: 1.1em;
				padding: .2em 20px;
					
				&:first-child:last-child {
					width: calc(100% - 40px);
				}
			}
		}
	}
	#primary-nav-controls {
		ul {
			
			li {
				.search-header {
					padding-left: .5em;
				}
				.search-more {
					padding-left: .3em;
				}
			}
		}
	}
	
	.nav-expand-search #primary-nav-search .search-more .btn {
		font-size: 1.1em;
		padding: .5em 1em;
	}
}
@include media-breakpoint-up(md) {
	
	.row.no-gutters {
		padding-left: 30px;
		padding-right: 30px;
	}
	
	#primary-nav-controls {
		#primary-nav-settings {
			display: inline-block;
		}
		
		> a.icon-menu {
			display: none;
		}
		
		ul {
			position: absolute;
			bottom: auto;
			right: auto;
			max-height: calc(100vh - 100px);
			overflow: auto;
			overflow-x: hidden;
			border-bottom: 4px solid black;
			padding-bottom: 0;
			li:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	#primary-nav {
		margin-top: 17px;
		background-color: transparent;
	}
	
	#primary-nav.nav-expanded {
		position: fixed;
	}
	
	#primary-nav-pages {
		position: relative;
		display: flex;
		align-items: center;
		background-image: url("/images/backgrounds/triangles/navigation.svg");
		background-size: cover;
		background-position: center right;
		background-repeat: no-repeat;
		margin-right: auto;
		padding: 2px 35px 2px 0px;
		flex: 0 0 auto;
		
		h1 {
			margin: 0 30px 0 6px;
		}
		
		ul {
			position: static;
			top: auto;
			display: flex;
			width: auto;
			background-color: transparent;
			
			li {
				border-radius: 4px;
				
				a {
					padding: 3px 9px 1px;
					text-transform: uppercase;
					border: 1px solid transparent;
					transition: border-color .2s linear;
					margin: 3px 2px;
					&:hover {
						border: 1px solid white;
						text-decoration: none;
					}
					&:active {
						border: 1px solid $feral-orange;
						color: $feral-orange;
					}
				}
				
				&.active a {
					color: $feral-orange;
// 					pointer-events: none;
				}
				
				&:first-child {
					&.active a {
						pointer-events: none;
					}
				}
			}
	
			.page-controls, .account-controls {
				display: none;
			}
		}
	}
	
	#primary-nav-controls {
		background-image: url("/images/backgrounds/triangles/settings.svg");
		background-size: cover;
		background-position: center left;
		background-repeat: no-repeat;
		padding: 5px 7px 5px 35px; margin: 0px;
	}
	
	#primary-nav.nav-expanded {
		#primary-nav-pages {
			width: auto;
		}
	
		#primary-nav-controls {
			width: 500px;
		}
		
		&.nav-expand-search #primary-nav-search, &.nav-expand-basket #primary-nav-basket, &.nav-expand-settings #primary-nav-settings {
			ul {
				top: 40px; left: auto;
				right: 0px;
				width: calc(100% - 50px);
			}
		}
		&.nav-expand-settings #primary-nav-settings {
			.btn {
				font-size: .9em;
// 				padding-top: 11px !important;
			}
			select {
				font-size: .9em;
			}
		}
		&.nav-expand-search #primary-nav-search .search-more .btn {
			font-size: .85em;
			padding: .5em 1em;
		}
	}
}

@include media-breakpoint-up(lg) {
	#primary-nav.nav-expanded {
		
		&.nav-expand-settings #primary-nav-settings {
			.btn {
				font-size: 1.1em;
// 				padding-top: 9px !important;
			}
			select {
				font-size: 1.1em;
			}
		}
		
		&.nav-expand-search #primary-nav-search .search-more .btn {
			font-size: 1.1em;
			padding: .2em 1em;
		}
	}
}	
	
		
// FIXING BUTTON CORNERS FOR LARGE SCREENS
@include media-breakpoint-up(xl) {
	#primary-nav.nav-expanded.nav-expand-search #primary-nav-search .search-more .btn, .modal-body button.btn {
	
		padding-left: 23px;
		-webkit-clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
	    clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
	    &:before {
		    -webkit-clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
		    clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
		}
	}
}
	

#top-header {
	min-height: 30vh;
	color: #FFF;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
	h2 {
		max-width: 60%;
		margin-top: 60px;
		padding: 10px 40px 8px 12px;
		background-image: url("/images/backgrounds/triangles/navigation.svg");
		background-size: cover;
		background-position: center right;
		background-repeat: no-repeat;
		font-size: 1.2rem;
		color: $feral-orange;
		@include media-breakpoint-down(sm) {
			@include bounce-in-leftSmall(2s, 0s);
		}
		span {
			display: none;
			font-weight: normal;
			color: #FFF;
			font-size: 90%;
		}
	}
	
}

@include media-breakpoint-down(xs) {
	#jarallax-container-0 {
		div {
			height: 105% !important;
			background-position: 50% 35% !important;
		}
	}	
}

body:not(.home-page):not(.games-page) {
	#jarallax-container-0 {
		div {
			height: 30vh !important;
			background-position: 50% 35% !important;
		}
	}	
}

/*
body.home-page, body.games-page {
	#jarallax-container-1 {
		div {
			height: 105% !important;
			background-position: 50% 35% !important;
		}
	}	
}
*/

@include media-breakpoint-up(md) {
	#top-header {
		min-height: 35vh;
		h2 {
			margin-top: 75px;
			padding: 15px 75px 15px 30px;
			background-image: url("/images/backgrounds/triangles/page-header.svg");
// 			@include bounce-in-left(2s, 0s);
			opacity: 0 !important;
			
			span {
				display: block;
				margin-right: -20px;
			}
		}
	}
		

	body:not(.home-page):not(.games-page) {
		#jarallax-container-0 {
			div {
				height: 35vh !important;
				min-height: 205px;
// 				background-position: 50% 35% !important;
			}
		}	
	}


}
	
#top-feature {
	@include gradient-y-three-colors(transparent, #000, 80%, #000);
	margin-top: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 100vw; 
	width: 100%; 
// 	min-height: 40vh;
	padding: 65px 10px 10px;
	@include media-breakpoint-up(md) {
		padding: 10px;
	}
    overflow: visible;
//     overflow-x: hidden;
    position: relative;
    &.dlc, &.expansion {
		#top-feature-controls .dlc-note {
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
			height: auto;
			padding: .5em .55em .45em 1.3em;
			z-index: 1;
// 				padding: .5em 1.1em .45em .65em;
			font-weight: 700;
			background-image: url('/images/backgrounds/triangles/dlc-frame.svg');
			background-position: left center;
			background-size: cover;
			background-repeat: no-repeat;
			z-index: 1;
			text-transform: uppercase;
			text-align: left;
			@include media-breakpoint-up(md) {
				padding-right: calc(100px + .55em);
				top: -4em;
				white-space: nowrap;
			}
			span {
				display: inline-block;
// 					transition: font-size .2s linear, opacity .2s linear;
				font-size: .8rem;
				position: relative;
				top: -.1em;
			}
			em, strong {
				opacity: 0;
				font-size: .05rem;
				width: 0;
				height: 0;
				display: inline-block;
				overflow: hidden;
				transition: font-size .2s linear, opacity .2s linear;
				position: relative;
				top: .2em;
			}
			em {
// 					font-size: .4em;
				font-weight: 400;
				font-style: normal;
// 				padding-left: .8rem;
			}
		}
		
		#top-feature-controls:hover {
			.dlc-note {
				span {
					opacity: 0;
/*
					font-size: .5rem;
					width: 0;
					height: 0;
*/
					overflow: hidden;
					display: none;
				}
				em, strong {
					opacity: 1;
					font-size: .8rem;
					width: auto;
					height: auto;
					display: inline-block;
				}
				em {
// 					display: block;
// 					font-size: .6rem;
				}
			}
		}
/*
		#top-feature-controls::before {
			content: 'DLC';
			position: absolute;
			right: 0;
			top: 0;
			width: auto;
			height: auto;
			padding: .5em .55em .45em 1.2em;
			font-weight: 700;
			background-image: url('/images/backgrounds/triangles/dlc-frame.svg');
			background-position: left center;
			background-size: cover;
			background-repeat: no-repeat;
			z-index: 1;
			text-transform: uppercase;
			text-align: right;
			@include media-breakpoint-up(md) {
				padding-right: calc(100px + .55em);
				top: -4em;
				white-space: nowrap;
			}
		}
			
		#top-feature-controls:hover::before {
			content: attr(data-basegame);
			padding-left: 2em;
			max-width: 95%;
			@include media-breakpoint-up(md) {
				max-width: 60vw;
			}
		}
*/
		
		
		
		
	}
}

#top-feature-details {
	width: 100%;
	margin-top: auto;
	text-shadow: $small-black-text-shadow;
	
	> em {
		@include bounce-in-leftSmall(2s, 1s);
		padding: 2px 34px 3px 51px;
		margin-bottom: 12px;
		font-style: normal;
		font-weight: bold;
		font-size: 1.3rem;
		text-shadow: 0px 0px 1px #882020, 0px 0px 2px #b51212, 0px 0px 3px #b51212, 0px 0px 5px #621b1b;
		background-color: $danger;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ba4018+0,bd3d19+6,c6351c+11,a91e10+42,8e0804+78,840000+100 */
background: rgb(186,64,24); /* Old browsers */
background: -moz-linear-gradient(-45deg,  rgba(186,64,24,1) 0%, rgba(189,61,25,1) 6.51%, rgba(198,53,28,1) 11.33%, rgba(169,30,16,1) 42.82%, rgba(142,8,4,1) 78.83%, rgba(132,0,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  rgba(186,64,24,1) 0%,rgba(189,61,25,1) 6.51%,rgba(198,53,28,1) 11.33%,rgba(169,30,16,1) 42.82%,rgba(142,8,4,1) 78.83%,rgba(132,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  rgba(186,64,24,1) 0%,rgba(189,61,25,1) 6.51%,rgba(198,53,28,1) 11.33%,rgba(169,30,16,1) 42.82%,rgba(142,8,4,1) 78.83%,rgba(132,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ba4018', endColorstr='#840000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		
		display: inline-block;
		background-image: url("/images/backgrounds/triangles/featured-discount.svg");
		background-size: cover;
		background-position: center right;
		background-repeat: no-repeat;
		max-width: 60%;
		-webkit-font-smoothing: antialiased;
	}
	
	h3 {
// 		font-weight: normal;
		color: white;
	}
	
	h5 {
		display: flex;
		width: 100%;
		font-weight: normal;
		
		> * {
			margin-left: 5px;
			align-self: center;
			font-size: 1.3em;
			&:first-child {
				margin-left: 0px;
				margin-right: calc(10px + .5vw);
				margin-right: 0;
				font-size: 1em;
			}
			
			&:nth-child(3):not(.price) {
				margin-left: calc(10px + .5vw);
				margin-left: 0;
			}
			
		}
		
		.price {
			margin-left: auto;
			order: 8;
			
			del {
// 				right: 3vw;
			}
			
			ins {
				margin-bottom: -1vw;
			}
		}
		
		.price ~ .price {
			margin-left: 5px;
		}
	}
}

#top-feature-controls {
	display: flex;
	width: 100%;
// 	flex-wrap: wrap;
	a {
		flex: 1 1 50%;
		text-transform: uppercase;
		font-weight: 500;
// 		margin: .2em .4vw;
		&:nth-child(1):nth-last-child(2), &:nth-child(2):nth-last-child(2) {
			margin-left: 2px;		
		}
		&:nth-child(2):nth-last-child(1), &:nth-child(3):nth-last-child(1) {
			margin-left: 4px;
			font-weight: normal;
		}
	}
}

h3 {
	font-size: 1.3rem;
}
@include media-breakpoint-up(md) {
	
	h3 {
		font-size: 2.4vw;
	}
	
	#top-feature {
		padding: 0 0px calc( 20vh - 12vw );
// 		padding: 0 0px 9vh;
		background-image: none;
		
		&.laracroftguardianoflightmobile-mobile {
			#top-feature-details h3 {max-width: 100%;}
		}
		
	}
	
	#top-feature-details {
		position: relative;
		width: auto;
		background-image: url("/images/backgrounds/triangles/featured-game.svg");
		background-size: cover;
		background-position: center right;
		background-repeat: no-repeat;
		padding: 1.8vw 7vw 1vw 30px;;
		@include bounce-in-left(2s, 1s);
		
		> em {
			position: absolute;
			top: -5.5vw;
			@include bounce-in-left(2s, 2s);
			padding: 2px 4vw 3px 99px;
			margin-bottom: 0;
			font-size: 1.3rem;
			font-size: 2.5vw;
		}
		
		h3 {
			color: $feral-orange;
			font-weight: 300;
			max-width: 30vw;
		}
		
		h5 {
			font-weight: 300;
			font-size: 1.6vw;
			padding: .5vh 2vw .3vh 0;
			
			span:first-child {
				margin-right: 5px;
			}
			
			.price {
				margin-left: calc(20px + .5vw);
				margin-left: 25px;
			}
		}
	}
	
	#top-feature-controls {
		margin-left: auto;
		margin-top: 1vh;
		margin-top: calc( 1vh - 1vw * 4 );
		width: auto;
		background-image: url("/images/backgrounds/triangles/featured-buttons.svg");
		background-size: cover;
		background-position: center left;
		background-repeat: no-repeat;
		padding: 1.2vw 30px 1.2vw 3.9vw;
		@include bounce-in-right(2s, 2s);
		
		a.btn {
			min-width: 12vw;
			font-size: 1.6vw;
		}
	}
}


.discount-holder {
	position: absolute;
	top: 0px; left: 0px;
// 	border: 1px solid cyan;
	box-sizing: border-box;
	overflow: hidden;
	height: 100px;
	width: 300px;
	z-index: 1;

	.discount {
		position: absolute;
		display: block;
		top: 18px;
		left: -112px;
		width: 100%;
		text-align: center;
		font-size: 1.2rem;
		font-style: normal;
		font-weight: bold;
		transform: rotate(-30deg);
		background-color: $danger;
		padding: 2px 30px 2px 40px;
			border: 1px solid black;
		overflow: hidden;
		z-index: 4;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ba4018+0,bd3d19+6,c6351c+11,a91e10+42,8e0804+78,840000+100 */
background: rgb(186,64,24); /* Old browsers */
background: -moz-linear-gradient(top,  rgba(186,64,24,1) 0%, rgba(189,61,25,1) 6.51%, rgba(198,53,28,1) 11.33%, rgba(169,30,16,1) 42.82%, rgba(142,8,4,1) 78.83%, rgba(132,0,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(186,64,24,1) 0%,rgba(189,61,25,1) 6.51%,rgba(198,53,28,1) 11.33%,rgba(169,30,16,1) 42.82%,rgba(142,8,4,1) 78.83%,rgba(132,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(186,64,24,1) 0%,rgba(189,61,25,1) 6.51%,rgba(198,53,28,1) 11.33%,rgba(169,30,16,1) 42.82%,rgba(142,8,4,1) 78.83%,rgba(132,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ba4018', endColorstr='#840000',GradientType=0 ); /* IE6-9 */
// 		-webkit-clip-path: polygon(26% 0%, 74% 0%, 100% 100%, 0% 100%);
// 		clip-path: polygon(26% 0%, 74% 0%, 100% 100%, 0% 100%);
		box-shadow: inset 0px 0px 15px rgba($danger, .8);
		text-shadow: 0px 0px 1px #882020, 0px 0px 2px #b51212, 0px 0px 3px #b51212, 0px 0px 5px #621b1b;
		
		&::before, &::after {
			content: ' ';
			display: block;
			width: 40%;
			height: 100%;
			background: pink;
			position: absolute;
			top: -0%;
			left: 0;
// 			transform: rotate(-45deg);
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#260000+0,260000+100&0.65+0,0+100 */
background: -moz-linear-gradient(left,  rgba(38,0,0,0.65) 0%, rgba(38,0,0,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(38,0,0,0.65) 0%,rgba(38,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(38,0,0,0.65) 0%,rgba(38,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6260000', endColorstr='#00260000',GradientType=1 ); /* IE6-9 */


// 			background: cyan;
// 			border-top: black 1px solid;
		}
		
		&::after {
			right: 0;
			left: auto;
// 			transform: rotate(-45deg);
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#260000+0,260000+100&0+0,0.65+100 */
background: -moz-linear-gradient(left,  rgba(38,0,0,0) 0%, rgba(38,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(38,0,0,0) 0%,rgba(38,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(38,0,0,0) 0%,rgba(38,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00260000', endColorstr='#a6260000',GradientType=1 ); /* IE6-9 */


		}
		
		
	}

}

@include media-breakpoint-up(md) {
	.discount-holder {
		top: 6px; left: 6px;
		height: 10vw;
		width: 10vw;
		&::before, &::after {
			content: ' ';
			display: block;
			position: absolute;
			background: black;
			width: 4px;
			height: 10%;
// 			transform: rotate(45deg);
			bottom: 0;
			left: 0;
			z-index: 1;
		}
		&::after {
			bottom: auto;
			top: 0;
			left: auto;
			right: 0;
			z-index: 1;
			width: 10%;
			height: 4px;
		}
		.discount {
			top: 2vw;
			left: -5vw;
			width: 171%;
			transform: rotate(-45deg);
			font-size: 2vw;
			padding: 2% 30%;
			border: 2px solid black;
	    }
	}				
	
}

@include media-breakpoint-up(xl) {
	.discount-holder {
		
		&::before, &::after {	
			border-radius: 0px 25px;
		}
	}				
	
}
	
body > section {
	position: relative;
	color: #FFF;
	
	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 0px; left: 0px;
		@include gradient-y(rgba(0,0,0,0.8), transparent);
		width: 100%; height: 80%;
	}
}

#social-media {
	&:before {
		display: none;
	}
	
	ul {
		list-style: none;
		padding: 0px 0; margin: 0px;
		box-sizing: border-box;
		text-align: center;
// 		display: flex;
	}
	
	li {
		display: inline-block;
		box-sizing: border-box;
		margin: 30px 15px;
		text-align: center;
		min-width: 20vw;
		img {
			max-width: 15vw;
		}

		span {
			display: none;
			color: $body-color;
	// 		font-size: 1.05rem;
			box-sizing: border-box;
		}
	}
}

@include media-breakpoint-up(md) {
	#social-media { 
		ul {
			padding: 1.8rem 0 1.2rem;
		}
		li {
			min-width: 200px;
			margin: 15px;
			img {
				max-width: 8vw;
			}
			span {
				display: block;
				font-size: .95rem;
				padding-top: 1.8vw;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	#social-media { 
		li {
			min-width: 250px;
			margin: 12px 1.2vw;			
			img {
				max-width: 6.5vw;
			}
			span {
	// 			display: block;
				font-size: 1.05rem;
	// 			padding-top: 1.8vw;
			}
		}
	}
}

#basic-site-map {
	background-color: #1a1a1a;
	text-align: center;
	padding-top: 0px;
	
	> img {
		width: 70%;
	}
	
	#basic-site-map-toggle {
		position: relative;
		display: block;
		margin: 0px 20px; padding: 5px 10px;
		background-color: #FFF;
		color: #1a1a1a;
		border-top-left-radius: 14px;
		border-top-right-radius: 14px;
		font-size: .9rem;
		
		&:before {
			display: block;
			content: '';
			position: absolute;
			top: 8px; left: -8px;
			bottom: 0px;
			background-color: #FFF;
			width: 9px;
			-webkit-clip-path: polygon(0px 100%, 100% 0px, 100% 100%);
			clip-path: polygon(0px 100%, 100% 0px, 100% 100%);
		}
		
		&:after {
			display: block;
			content: '';
			position: absolute;
			top: 8px; right: -8px;
			bottom: 0px;
			background-color: #FFF;
			width: 9px;
			-webkit-clip-path: polygon(0px 0px, 100% 100%, 0px 100%);
			clip-path: polygon(0px 0px, 100% 100%, 0px 100%);
		}
	}
	
	ul {
		list-style: none;	
		padding-left: 20px;	
		ul {
			padding-left: 10px;
			margin-bottom: 10px;
		}
	}
	
	> ul, > p {
		display: none;
		text-align: left;
		padding-top: 10px;
		color: #FFF;
	}
	
	> ul {
		font-size: .9rem;
			
		> li > a {
			font-weight: bold;
			text-transform: uppercase;
		}
	}
	
	a {
		color: #FFF;
	}
	
	p {
		padding: 10px; margin: 0px;
		font-size: .7rem;
	}
}

@include media-breakpoint-up(md) {
	#basic-site-map {
		background-color: #FFF;
		
		> img {
			width: 200px;
			padding-top: 15px;
		}
		
		#basic-site-map-toggle {
			display: none;
		}
		
		ul {
			
			padding: 0px; margin: 0px;
			li {
				padding: 0px 7px; 
				ul {
					padding-left: 0;
					
					li {
						padding-left: 0;
						padding-right: 0;
						margin-bottom: .4em;
						line-height: 1.2em;
					}
				}
			}
		}
		
		a {
			color: $body-color;
		}
		
		> ul, > p {
			margin: 30px 15px 15px;
			display: block;
		}
		
		> p {
			color: #777;
			font-size: .8rem;
			text-align: center;
		}
		
		> ul {
			display: flex;
			justify-content: space-around;
			font-size: .9rem;
			color: $body-color;
			
			ul {
				margin-top: 10px;
			}
		}
	}
}

.shareable {
	.copy-link, .fa-x-twitter, .fa-facebook-f, .fa-reddit-alien, .fa-envelope {
		display: inline-block;
		padding: 3px 7px;
		border-radius: 4px;
		line-height: 1.6em;
		font-size: 1.0rem;
		 min-width: 1.989em;
		    height: 1.989em;
		min-height: 1.989em;
		text-align: center;
		text-decoration: none;
		transition: border-color .2s linear, background-color .2s linear, box-shadow .2s linear;
		border: 1px solid;
		&:hover {
			border-color: $feral-orange !important;	
			background-color: black;
			color: white !important;
			box-shadow: none;
		}
	}
	
	.fa-x-twitter, .fa-facebook-f, .fa-reddit-alien, .fa-envelope {
		color: #FFF !important;
	}
	
	.copy-link {
		background-color: #FFF;
		color: $feral-orange !important;
		margin-right: .7em;
		border-color: #d2d2d2;
		
		.fa-link {
			padding: 0 0 0 .4em;
		}
		
		.fa-clipboard-check {
			padding: 0 .1em 0 .5em;
		}
	}
	
	.fa-x-twitter {
		background-color: #000000;
		box-shadow: inset 0 0 2px #ffffff;
	}
	
	.fa-facebook-f {
		background-color: #4a72b6;
	}
	
	.fa-reddit-alien {
		background-color: #fc6400;
	}
	
	.fa-envelope {
		background-color: #8a8a8a;
	}
	
	br {
		display: none;
	}
}

.page-filters {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
    align-items: center;
	background-color: #000;
	
	nav {
		position: relative;
		display: flex;
	    justify-content: space-around;
	    align-items: center;
		margin: 10px;
		width: calc(100% - 20px);
		
		> span:first-child {
			display: none;
			font-size: 1.2rem;
		}
		
		a {
			display: flex;
			width: calc(2.6rem + 2px); height: calc(2.6rem + 2px);
			border: solid 2px #FFF;
			border-radius: 100%;
			color: #FFF;
			text-align: center;
			background-color: #333;
			font-size: 1.5rem;
			text-decoration: none;
			box-sizing: content-box;
			transition: border .2s linear;
			
			span {
				margin: auto;
				align-self: center;
				&::before {
					text-shadow: $small-black-text-shadow;
				}
			}
			
			&:hover {
				border-color: $feral-orange;
			}
			
			&:active {
				transform: scale(.93);
				box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 1);
				span::before {
					
				}
			}
			
			&.active {
				border-color: $feral-orange;
				border-width: 3px;
				background-color: #000;
				width: calc(2.6rem + 1px); height: calc(2.6rem + 1px);
// 				filter: drop-shadow(0 0 .3rem $feral-orange);;
			}
		}
	}
	
	select, input[type=text], input[type=search] {
		position: relative;
		display: block;
		width: calc(100% - 10px);
		margin: 0 10px 10px; padding: 3px 8px;
		border: solid 2px #FFF;
		border-radius: 4px;
		background-color: #333;
		color: #FFF;
		
		&:focus {
			outline: none;
			border-color: $feral-orange;
			&::placeholder {
				opacity: .3;
			}
		}
		
		&::-webkit-search-cancel-button {
			filter: invert(1);
		}
	}
	
	select {
		
		border-radius: 4px;
		background-color: #333;
		padding: 0 36px 0 8px;
		background-image: url('/images/down-arrow-white.svg')/* , url('/images/line-black.svg') */;
		background-repeat: no-repeat;
		background-position: calc(100% - 13px) center/* , calc(100% - 46px) center */;
		background-size: 12px auto/* , 1px auto */;
		position: relative;
		-moz-appearance: none;
	    -webkit-appearance: none;
	    appearance: none;
		height: 34px;
		font-size: 1em;
		font-weight: normal;
		border: 2px solid white;	
		transition: border .2s linear;
		cursor: pointer;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		color: darkgrey;
		&:hover {
			border-color: $feral-orange;
			background-image: url('/images/down-arrow-orange.svg')/*,  url('/images/line-orange.svg') */;
		}
		
		
	}
	
	
	
}

@include media-breakpoint-up(md) {
	.page-filters {
		flex-direction: row;
		
		nav {
			margin-left: 30px;
			margin-right: auto;
			width: auto;
			
			> span:first-child {
				display: block;
				margin-right: 10px;
			}
			
			a {
				margin-right: 5px;
			}
		}
		
		select, input[type=text], input[type=search] {
			width: 300px;
			margin: 10px 30px 10px 10px;
		}
	}
}

@import "./_modals";
@import "./_fonts";

body.home-page {
	@import "./views/_home";
}

body.games-page {
	@import "./views/_games";
}

body.minisite_simple-page, body.minisite_complex-page, body.minisite_gates-page {
	@import "./views/_minisite";
}

body.minisite_simple-page {
	@import "./views/_minisite_simple";
}

body.minisite_complex-page {
	@import "./views/_minisite_complex";
}

body.minisite_gates-page {
	@import "./views/_minisite_gates";
}

body.news-page {
	@import "./views/_news";
}

body.newsletters-page {
	@import "./views/_newsletters";
}

body.support-page {
	@import "./views/_support";
}

body.about-page, body.about_fulltext-page {
	@import "./views/_about";
}

body.sitemap-page {
	@import "./views/_sitemap";
}

body.careers-page {
	@import "./views/_careers";
}

body.privacy-page, body.about_fulltext-page {
	@import "./views/_privacy";
}

body.about_fulltext-page {
	@import "./views/_aboutfulltext";	
}

body.search-page {
	@import "./views/_search";
}

body.radar-page {
	@import "./views/_radar";
}

body.videos-page {
	@import "./views/_videos";
}

body.accounts-page {
	@import "./views/_accounts";
}

body.legal-page {
	@import "./views/_legal";
}

body.notfound-page {
	@import "./views/_notfound";
}