	
#top-header {	
	#jarallax-container-0 {
		div {
			height: 155% !important;
			background-position: 50% top !important;
		}
	}
}

@include media-breakpoint-up(md) {
	#top-header {	
		#jarallax-container-0 {
			div {
				height: 35vh !important;
				background-position: 50% 23% !important;
			}
		}
	}
}

#radar-content {
	display: flex;
	flex-direction: column;

	&::before {
		opacity: 1;
		height: 60%;
	}
	
	.row {
		overflow-x: hidden;
	}
	
	h3 {
		position: relative;
		margin: 2rem 15px;
		font-size: 1.3rem;
		font-weight: normal;
		z-index: 1;
		flex: 1 100%;
		text-align: center;
	}
	div.row > p {
		position: relative;
		margin: 3rem 15px 1rem;
		font-size: 1.3rem;
		font-weight: normal;
		z-index: 1;
		flex: 1 100%;
		text-align: center;
	}

	
	.game-image {
		background-size: 180% !important;
		background-position: center 18%;
	}
	
	.radar-render-blip-contents {
		position: absolute;
		top: -110px; left: 100px;
		display: none;
		width: 200px;
		
		.platforms {
			position: absolute;
			display: flex;
			justify-content: center;
			bottom: 0px; 
			padding: 2.5em 0 .7em;
			width: 100%;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

			span {
				margin-right: 0px;
				text-shadow: $small-black-text-shadow, $small-black-text-shadow, $small-black-text-shadow;
				font-size: 1.5em;
				&:last-child {
					margin-right: 0px;
				}
			}
		}
		
		&:before {
			position: absolute;
			display: block;
			content: '';
			background-color: $radarGreyBorder;
			top: 83px;
			left: -75px;
			width: 75px;
			height: 49px;
			z-index: 10;
			-webkit-clip-path: polygon(0px 32px, 100% 5px, 100% 32px, 0px 48px);
			clip-path: polygon(0px 32px, 100% 5px, 100% 32px, 0px 48px);
		}
		
		&:after {
			position: absolute;
			display: block;
			content: '';
			top: 90px; left: -92px;
			width: 96px; height: 45px;
			z-index: 10;
			-webkit-clip-path: polygon(0px 34px, 100% 0px, 100% 20px, 0px 41px);
			clip-path: polygon(0px 34px, 100% 0px, 100% 20px, 0px 41px);
		}
		
		> div {
			position: relative;
			background-color: #1b1b1b;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			
			&:after {
				position: absolute;
				display: block;
				content: '';
				background-color: $radarGreyBorder;
				top: 0px; left: 0px;
				bottom: 0px; right: 0px;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
			}
			
			> div {
				position: relative;
				
				&:before {
					position: absolute;
					display: block;
					content: '';
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 5px 10px, 5px calc(100% - 5px), calc(100% - 15px) calc(100% - 5px), calc(100% - 5px) calc(100% - 15px), calc(100% - 5px) 5px, 15px 5px, 5px 15px, 0px 10px);
					clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 5px 10px, 5px calc(100% - 5px), calc(100% - 15px) calc(100% - 5px), calc(100% - 5px) calc(100% - 15px), calc(100% - 5px) 5px, 15px 5px, 5px 15px, 0px 10px);
					z-index: 1;
				}
				
				&:after {
					position: absolute;
					display: block;
					content: '';
					background-color: $radarGreyBorder;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
					clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
					z-index: 2;
				}
				
				&.game-image {
					position: relative;
					width: 200px;
					height: 148px;
					z-index: 0;
					
				
					background-size: 100% !important;
					background-position: center 40%;
					
				}
			}
		}
		
		p {
			padding: 3px 5px 4px;
			text-align: center;
		}
	}
	
	@each $level, $color in $radarBlips {
		.radar-render-blip-#{$level} .radar-render-blip-contents > div > div:before, .radar-render-blip-#{$level} .radar-render-blip-contents:after {
			background-color: $color;
		}
	}
	
	.radar-render-blip.open {
		z-index: 10;
		
		.radar-render-blip-circle {
			-webkit-animation: none;
			animation: none;
		}
		
		.radar-render-blip-contents {
			display: block;
		}
	}
}

@-webkit-keyframes flash {
	0% { opacity: 1; } 
	50% { opacity: .1; } 
	100% { opacity: 1; }
}
@keyframes flash {
	0% { opacity: 1; } 
	50% { opacity: .1; } 
	100% { opacity: 1; }
}

@keyframes orbit-verySoon {
    from { transform: rotate(0deg) translateX(14.5vw) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(14.5vw) rotate(-360deg); }
}

@keyframes orbit-soon {
    from { transform: rotate(0deg) translateX(22.9vw) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(22.9vw) rotate(-360deg); }
}

@keyframes orbit-quiteSoon {
    from { transform: rotate(0deg) translateX(35.1vw) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(35.1vw) rotate(-360deg); }
}

@keyframes orbit-notSoon {
    from { transform: rotate(0deg) translateX(43vw) rotate(0deg); }
    to   { transform: rotate(360deg) translateX(43vw) rotate(-360deg); }
}

#radar-holder {
	position: relative;
	width: 110vw; height: 110vw;
	margin-left: -5vw;
	margin-right: -5vw;
	
	.radar-render-bg {
		position: absolute;
		border-radius: 100%;
	}
		
	.radar-render-blip {
		position: absolute;
		top: calc(50% - 7px); left: calc(50% - 7px);
		will-change: left, top;
		transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		-webkit-perspective: 1000;
		@each $level, $color in $radarBlips {
			&.radar-render-blip-#{$level} {
				animation-name: orbit-#{$level};
				animation-iteration-count: infinite;
				animation-timing-function: linear;
				
				.radar-render-blip-circle {
					background-color: $color;
				}
			}
		}
		&.clue .radar-render-blip-circle {			
			-webkit-animation: flash linear 3s infinite;
			animation: flash linear 3s infinite;
		}
	}
	
	.radar-render-blip-circle {
		display: block;
		width: 14px; height: 14px;
		border: solid 3px $radarGreyBorder;
		border-radius: 100%;
		will-change: opacity;
		transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		-webkit-perspective: 1000;
	}
}

#radar-render {
	position: absolute;
	top: 0px; left: 0px;
	width: 100%; height: 100%;
	filter: drop-shadow(0px 0px 70px #0b0b56);
	-webkit-filter: drop-shadow(0px 0px 70px #0b0b56);
	-moz-filter: drop-shadow(0px 0px 70px #0b0b56);
	
}

$radarCurrentStep: 0% !global;
#radar-render-1 {
	@include radar-step(0%); // -10
	background-color: rgba(72,102,124, .7);
	background-color: rgba(102, 102, 102, 0.7);
}

#radar-render-2 {
	@include radar-step(11%); // 15
	@include radar-gradient(#515961, 55%, #2f3236, 70%);
	filter: drop-shadow(0px 0px 20px rgba(14, 28, 67, 0.99));
	-webkit-filter: drop-shadow(0px 0px 20px rgba(14, 28, 67, 0.99));
	-moz-filter: drop-shadow(0px 0px 20px rgba(14, 28, 67, 0.99));
	
}

#radar-render-3 {
	@include radar-step(7%); // 10
	background-color: #bbbcbe;
}

#radar-render-4 {
	@include radar-step(.25%);
	background-color: #626365;
}

#radar-render-5 {
	@include radar-step(2%);
	@include radar-gradient(#c58d55, 55%, #e0dad6, 70%);
}

#radar-render-6 {
	@include radar-step(8%);
	background-color: #948b7a;
}

#radar-render-7 {
	@include radar-step(2%);
	@include radar-gradient(#c58d55, 50%, #e0dad6, 70%);
}

#radar-render-8 {
	@include radar-step(6%); // 7
	background-color: #9d8c7c;
}

#radar-render-9 {
	@include radar-step(1.5%);
	@include radar-gradient(#c58d55, 40%, #e0dad6, 70%);
}

#radar-render-10 {
	@include radar-step(4%); // 7
	background-color: #381e12;
}

#radar-center-holder {
// 	position: absolute;
	display: flex;
	flex-direction: column;
	transition: all 1s ease-in-out;
	transform: scale(0);
// 	top: 0px; left: calc(50% - 90px);
	margin: 0 auto;
	width: 180px;
	
	> a {
		margin-left: auto;
		margin-right: auto;
		padding: 2px 10px;
		text-transform: uppercase;
		border: solid 2px #404040;
		border-top: none;
		background-color: #1b1b1b;
		color: #EEE;
		font-size: 80%;
		
		&:hover {
			text-decoration: none;
			color: $feral-orange;
		}
		
		span {
			margin-right: .3em;
			margin-left: -.2em;
		}
	}
			
	.game-image {
		width: 176px; height: 176px;
	}
	
	.platforms {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		position: absolute;
		bottom: 10px; left: 0px;
		width: 100%;
			position: absolute;
			display: flex;
			justify-content: center;
			bottom: 0px; 
			padding: 2.5em 0 .7em;
			width: 100%;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

			span {
				margin-right: 0px;
				text-shadow: $small-black-text-shadow, $small-black-text-shadow, $small-black-text-shadow;
				font-size: 1.5em;
				&:last-child {
					margin-right: 0px;
				}
			}
		
	}
	
	p {
		position: relative;
		margin: 0px; padding: .7em 5px .7em;
		text-align: center;
		font-size: .9rem;
		
		&:after {
			position: absolute;
			display: block;
			content: '';
			top: 0px; left: 0px;
			bottom: 0px; right: 0px;
			background-color: $radarGreyBorder;
			-webkit-clip-path: polygon(0px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), 100% calc(100% - 14px), 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%);
			clip-path: polygon(0px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), 100% calc(100% - 14px), 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%);
			z-index: 1;
		}
	}
	
	nav {
		display: flex;
		flex-direction: column;
		padding: 10px;
		
		a:nth-child(2) {
			margin-top: 7px;
		}
	}
			
	> div {
		position: relative;
		background-color: #1b1b1b;
		-webkit-clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%);
		clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%);
		border-top-left-radius: 180px;
		border-top-right-radius: 180px;
		border: solid 2px $radarGreyBorder;
		overflow: hidden;
		
		&:after {
			position: absolute;
			display: block;
			content: '';
			background-color: $radarGreyBorder;
			bottom: -2px; right: -2px;
			width: 20px; height: 20px;
			-webkit-clip-path: polygon(calc(100% - 2px) 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px));
			clip-path: polygon(calc(100% - 2px) 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px));
		}
		
		> div {
			position: relative;
			background-color: $radarGreyBorder;
			width: 100%; padding-top: 100%;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0px calc(100% - 10px), 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0px calc(100% - 10px), 0px 10px);
			
			> div {
				position: absolute;
				top: 3px; left: 3px;
				bottom: 3px; right: 3px;
				overflow: hidden;
				border-top-left-radius: 180px;
				border-top-right-radius: 180px;
			}
			
			.game-image {
				position: absolute;
				top: 0px; left: 0px;
				width: 100%; height: 100%;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0px calc(100% - 10px), 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0px calc(100% - 10px), 0px 10px);
			}
		}
	}
}

#radar-center {
	position: absolute;
	top: 0px; left: 0px;
	width: 100%; height: 100%;
	display: flex;
	align-items: center;
	text-align: center;
	
	> .game-image {
		position: absolute;
		@include radar-step(0%);
		border-radius: 100%;
		cursor: pointer;
		
		border: 2px solid white;
		padding: 3px;
		
		box-shadow: inset 0px 0px 3vw black, 0px 0px 5vw #4e738a;
	}
	
	&.open {
		z-index: 10;
		top: 0px;
		
		#radar-center-holder {
			transform: scale(1);
		}
	}
}

#radar-legend {
	position: relative;
	list-style: none;
	padding: 10px 15px; margin: 0px;
	display: flex;
	flex-wrap: wrap;
	z-index: 1;
	justify-content: center;
	width: 100%;
	li {
		display: flex;
		align-items: center;
		position: relative;
		margin-right: .5em;
		
		&:before {
			display: block;
			content: '';
			margin-right: .3em;
			top: calc(50% - .5em); left: .3em;
			width: 1.2em; height: 1.2em;
			border: solid .3em $radarGreyBorder;
			border-radius: 100%;
			
		}
		
		@each $level, $color in $radarBlips {
			&.legend-#{$level}:before {
				background-color: $color;
			}
		}
		
		&.legend-outNow {
			&::before { 
				background-color: white;
				border-width: 0.2em;
			}
			&::after {
				background: $radarOutNow;
				z-index: 3;
				display: block;
				content: '';
				position: absolute;
				top: calc(50% - .3em);
				left: .3em;
				width: .6em;
				height: .6em;
				border-radius: 100%;
			}
		}
	}
}

#radar-popup {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 5%; left: calc(50% - 100px);
	width: 200px;
	z-index: 10;
	
	.game-image {
		width: 200px; height: 148px;
		
		background-size: 100% !important;
		background-position: center 40%;
		
	}
	
	> a {
		display: block;
		margin-left: auto;
		margin-right: auto;
		padding: 2px 10px;
		text-transform: uppercase;
		border: solid 2px #404040;
		border-top: none;
		background-color: #1b1b1b;
		color: #EEE;
		font-size: 80%;
		
		&:hover {
			text-decoration: none;
		}
	}
		
	@each $level, $color in $radarBlips {
		&.radar-popup-#{$level} > div > div:before {
			background-color: $color;
		}
	}
	
	.platforms {
		position: absolute;
		display: flex;
			justify-content: center;
			bottom: 0px; 
			padding: 2.5em 0 .7em;
			width: 100%;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */

			span {
				margin-right: 0px;
				text-shadow: $small-black-text-shadow, $small-black-text-shadow, $small-black-text-shadow;
				font-size: 1.5em;
				&:last-child {
					margin-right: 0px;
				}
			}
	}
	
	> div {
		position: relative;
		background-color: #1b1b1b;
		-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
		clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
		
		&:after {
			position: absolute;
			display: block;
			content: '';
			background-color: $radarGreyBorder;
			top: 0px; left: 0px;
			bottom: 0px; right: 0px;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
		}
		
		> div {
			position: relative;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			width: 200px; height: 148px;
			
			&:before {
				position: absolute;
				display: block;
				content: '';
				top: 0px; left: 0px;
				bottom: 0px; right: 0px;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 5px 10px, 5px calc(100% - 5px), calc(100% - 15px) calc(100% - 5px), calc(100% - 5px) calc(100% - 15px), calc(100% - 5px) 5px, 15px 5px, 5px 15px, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 5px 10px, 5px calc(100% - 5px), calc(100% - 15px) calc(100% - 5px), calc(100% - 5px) calc(100% - 15px), calc(100% - 5px) 5px, 15px 5px, 5px 15px, 0px 10px);
				z-index: 1;
			}
			
			&:after {
				position: absolute;
				display: block;
				content: '';
				background-color: #404040;
				top: 0px; left: 0px;
				bottom: 0px; right: 0px;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				z-index: 2;
			}
		}
	}
	
	p {
		margin: 0px; padding: 3px 5px;
		text-align: center;
	}
}

@include media-breakpoint-up(md) {
	
	
	
	@keyframes orbit-verySoon {
	    from { transform: rotate(0deg) translateX(10.45vw) rotate(0deg); }
	    to   { transform: rotate(360deg) translateX(10.45vw) rotate(-360deg); }
	}
	
	@keyframes orbit-soon {
	    from { transform: rotate(0deg) translateX(16.62vw) rotate(0deg); }
	    to   { transform: rotate(360deg) translateX(16.62vw) rotate(-360deg); }
	}
	
	@keyframes orbit-quiteSoon {
	    from { transform: rotate(0deg) translateX(25.5vw) rotate(0deg); }
	    to   { transform: rotate(360deg) translateX(25.5vw) rotate(-360deg); }
	}
	
	@keyframes orbit-notSoon {
	    from { transform: rotate(0deg) translateX(35vw) rotate(0deg); }
	    to   { transform: rotate(360deg) translateX(35vw) rotate(-360deg); }
	}
	
	@media (orientation: landscape) {
		
		@keyframes orbit-verySoon {
		    from { transform: rotate(0deg) translateX(12.35vh) rotate(0deg); }
		    to   { transform: rotate(360deg) translateX(12.35vh) rotate(-360deg); }
		}
		
		@keyframes orbit-soon {
		    from { transform: rotate(0deg) translateX(19.75vh) rotate(0deg); }
		    to   { transform: rotate(360deg) translateX(19.75vh) rotate(-360deg); }
		}
		
		@keyframes orbit-quiteSoon {
		    from { transform: rotate(0deg) translateX(30.3vh) rotate(0deg); }
		    to   { transform: rotate(360deg) translateX(30.3vh) rotate(-360deg); }
		}
		
		@keyframes orbit-notSoon {
		    from { transform: rotate(0deg) translateX(41vh) rotate(0deg); }
		    to   { transform: rotate(360deg) translateX(41vh) rotate(-360deg); }
		}
	}
	
	#radar-holder {
		margin: auto;
		position: relative;
		width: 80vw; height: 80vw;
		
		@media (orientation: landscape) {
			width: 95vh; height: 95vh;
			max-width: 95vh; max-height: 95vh;
		}
// 		margin-bottom: 30px;
		
		.radar-render-blip {
			top: calc(50% - 15px); left: calc(50% - 15px);
			.radar-render-blip-circle {
			
			
				width: 30px; height: 30px;
				border: solid 6px $radarGreyBorder;
			}

		}
		
		
	}
	
	#radar-render {
		@media (orientation: landscape) {
			width: 95vh; height: 95vh;
			max-width: 95vh; max-height: 95vh;
		}
	}
	
	#radar-content h3 {
		margin: 1em calc(70vw - 30px) -6vw 15px;
		text-align: left;
		font-size: 3vw;
	}
	
	#radar-center {
		> .game-image {
			border: .5vw solid white;
			padding: .9vw;
		}
	}
	
	#radar-legend {
// 		position: absolute;
		flex-direction: column;
		flex: 1 100%;
		margin-top: -15vw;
		margin-bottom: 2vw;
		pointer-events: none;
		li {
			font-size: 1.6vw;
			line-height: 2.2em;
			&:before {
				display: block;
				content: '';
				margin-right: .7em;
				top: calc(50% - .5em); left: .3em;
				width: 1.7em; height: 1.7em;
				border: solid .4em $radarGreyBorder;
			}
			

			&.legend-outNow {
				&::before { 
					border-width: 0.3em;
				}
				&::after {
					top: calc(50% - .45em);
					left: .4em;
					width: .9em;
					height: .9em;
				}
			}

		}
	}
}