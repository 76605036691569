#top-header {	
	#jarallax-container-0 {
		div {
			height: 155% !important;
			background-position: 50% top !important;
		}
	}
}

@include media-breakpoint-up(md) {
	#top-header {	
		#jarallax-container-0 {
			div {
				height: 35vh !important;
				background-position: 50% 23% !important;
			}
		}
	}
}


#faqs-filters-header {
	color: #FFF;
	background-color: #000;
	text-align: center;
	padding: 20px;
	
	&.svg-divided {
		@extend .svg-divided;
	}
	
	&.svg-divider-after {
		@extend .svg-divider-after;
	}
	
	h4 {
		position: relative;
// 		font-weight: normal;
		margin: 0px 0px 0px;
		text-transform: uppercase;
	}
}

#faqs-platforms {
	&:before {
		background-image: none;
		background-color: rgba(0,0,0,0.6);
		height: 100%;
	}
	
	p {
		position: relative;
		margin: 7vw 12vw 0vw;
		text-align: center;
		font-size: 6vw;
		text-shadow: $small-black-text-shadow, $small-black-text-shadow;
	}
	
	nav {
		position: relative;
		display: flex;
	    justify-content: center;
		padding: 7vh 2vw 7vh;
		flex-wrap: wrap;
		a {
			display: flex;
			width: 14vw; height: 14vw;
			margin: .8vw;
			border: solid 2px #FFF;
			border-radius: 100%;
			color: #FFF;
			text-align: center;
			background-color: #333;
			font-size: 9vw;
			text-decoration: none;
			transition: border .2s linear;
			
			span {
				margin: auto;
				align-self: center;
				&::before {
					font-size: 100%;
				}

				&.icon-linux::before {
					text-indent: -9999em;
					background: url('/images/platforms/linux-colour.svg') center center / 100% 100% no-repeat transparent;
				}

				&.icon-android::before {
					text-indent: -9999em;
					background: url('/images/platforms/android-colour.svg') center center / 100% 100% no-repeat transparent;
				}

				&.icon-switch::before {
					text-indent: -9999em;
					background: url('/images/platforms/switch-colour.svg') center center / 92% 92% no-repeat transparent;
				}

				&.icon-meta-quest::before {
					text-indent: -9999em;
					background: url('/images/platforms/meta-quest-colour.svg') center center / 92% 92% no-repeat transparent;
				}
			}
			
			&.active {
				color: $feral-orange;
				background-color: #FFF;
			}
			
			
			&:hover {
				border-color: $feral-orange;
			}
			
			&:active {
				transform: scale(.93);
				box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 1);
				span::before {
					
				}
			}
		}
	}
}

#faqs-filters {
	nav {
		a {
			&.mac {
					order: 1;
			}
			&.linux {
					order: 2;
			}
			&.ios {
					order: 3;
			}
			&.android {
					order: 4;
			}
			&.switch {
					order: 5;
			}
			&.windows {
					order: 6;
			}
			&.tvos {
					order: 7;
			}
		}
	}
	p {
		position: relative;
		text-align: center;
		padding: 0 10px;
		margin-bottom: 10px;
		@include media-breakpoint-up(md) {
			margin: 10px 20px;
		}
	}
}

#faqs-platforms {
	&:before {
		background-image: none;
		background-color: rgba(0,0,0,0.6);
		height: 100%;
	}
	
	
	div {
		justify-content: center;
		p {
			position: relative;
			color: #FFF !important;
			text-align: center;
			margin: 1.2em 15px 0;
			font-size: 4.5vw;
			@include media-breakpoint-up(sm) {font-size: 3vw;}
			@include media-breakpoint-up(md) {font-size: 1.4rem;}
			text-shadow: $small-black-text-shadow, $small-black-text-shadow;
			max-width: 750px;
			&:first-of-type {
	// 			margin-bottom: 2em;
			}
			&:last-of-type {
				margin-bottom: 1.2em;
			}
		}
	}
	
	h5 {
		position: relative;
		margin: 12vw 12vw 5vw;
		text-align: center;
		font-size: 6vw;
		text-shadow: $small-black-text-shadow, $small-black-text-shadow;
	}
	
	
}

@include media-breakpoint-up(md) {
	#faqs-filters-header h4 {
		margin: 13px 0px 18px;
	}
	
	#faqs-platforms {
		p {
			font-size: 1.8rem;
			margin: 2vh 0 0;
		}
		nav {
		    justify-content: center;
			margin: 10px auto;
			padding: 2vh 2vw;
			a {
				width: 12vw; height: 12vw;
				margin: .8vw;
/*
				font-size: 6rem;
				width: 9rem; height: 9rem;*/
				span {
					&::before {
						font-size: 90%; // DS edit
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	
	#faqs-platforms {
		nav {
		    
			
			a {
				width: 180px; height: 180px;
				width: 140px; height: 140px; // DS edit
				margin: 12px;
				
				span {
					&::before {
						font-size: 7.5rem;
						font-size: 6.2rem; // DS edit
					}
				}
			}
		}
	}
}

section > .row {
	@include fade-in-up(2s, 0s);
	margin: 0 auto;
	max-width: 1600px;
}

#faqs-games {
	text-align: center;
	h4 {
		position: relative;
		font-weight: normal;
		margin: -10px 15px 0px;
		opacity: 0;
	}
	
	p {
		position: relative;
		margin: 10px 15px;
		font-size: 9vw;
		@include media-breakpoint-up(md) {font-size: 1.4rem;}
	}
	
	div {
		justify-content: center;
		p {
			position: relative;
			color: #FFF !important;
	// 		margin: 1em 12vw 1em;
			text-align: center;
			font-size: 4.5vw;
			@include media-breakpoint-up(sm) {font-size: 3vw;}
			@include media-breakpoint-up(md) {font-size: 1.4rem;}
			text-shadow: $small-black-text-shadow, $small-black-text-shadow;
			max-width: 880px;
			&:first-of-type {
	// 			margin-bottom: 2em;
			}
		}
	}
	
	h4 {
		position: relative;
// 		margin: 12vw 12vw 5vw;
		text-align: center;
// 		font-size: 6vw;
		text-shadow: $small-black-text-shadow, $small-black-text-shadow;
	}
	
	nav {
		position: relative;
		list-style: none;
		margin: 0px auto; padding: 10px 5px;
		text-align: center;
		display: flex;
		align-items: stretch;
		justify-content: center;
		flex-wrap: wrap;
		
		width: 100%;
		a {
			display: flex;
			position: relative;
			margin: 5px; padding: 10px;
			width: calc(50% - 10px);
			max-width: 220px;
// 			border: solid 1px #444;
			border-radius: 2px;
			text-align: center;
			background-color: #111;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			transition: background-color .2s linear, border-color .2s linear, color .2s linear;
			
			&:after {
				position: absolute;
				display: block;
				content: '';
				background-color: #4c4c4c;
				top: 0px; left: 0px;
				bottom: 0px; right: 0px;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 1px 10px, 1px calc(100% - 1px), calc(100% - 11px) calc(100% - 1px), calc(100% - 1px) calc(100% - 11px), calc(100% - 1px) 1px, 11px 1px, 1px 11px, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 1px 10px, 1px calc(100% - 1px), calc(100% - 11px) calc(100% - 1px), calc(100% - 1px) calc(100% - 11px), calc(100% - 1px) 1px, 11px 1px, 1px 11px, 0px 10px);
				z-index: -1;
				transition: background-color .2s linear, border-color .2s linear, color .2s linear;
			}
			
/*
			div {
				position: relative;
				width: 100%; padding-top: calc(100% + 15px);
				background: url('') no-repeat transparent;
				background-size: contain;
				
			}
*/
			
			img {
/*
				position: absolute;
				top: 0px; left: 0px;
*/
				width: 100%; height: auto;
				padding-bottom: 15px;
				padding-bottom: 0; // hiding game name
			}
			
			span {
				display: table-cell;
				width: 100%; // height: 100%;
				vertical-align: middle;
				display: none; // hiding game name
			}
			
			&:hover {
				color: white;
				text-decoration: none;
				background-color: black;
				&:after {
					background-color: #AAA;					
				}
			}
			
			&.general_faqs {
				justify-content: center;
				align-items: center;
				span {
					display: flex;
					justify-content: center;
					font-size: 2.1em;
					line-height: 1.2em;
				}
			}
		}
	
		&:after {
			clear: both;
			display: block;
			content: '';
			width: 100%; height: 1px;
		}
	}
}

@include media-breakpoint-up(sm) {
	#faqs-games nav a {
		width: calc(33% - 10px);
	}
}

@include media-breakpoint-up(md) {
	#faqs-games {
		h4, p {
			max-width: calc(100% - 30px);
		}
		div p {
// 			font-size: 1.4rem;
		}
		h4 {
			font-size: 1.8rem;
// 			margin: 6vh 0 0;
		}
		nav a {
			width: calc(25% - 10px);
			padding: 15px
		}
	}
}

@include media-breakpoint-up(lg) {
	#faqs-games nav a {
		width: calc(20% - 10px);
	}
}

@include media-breakpoint-up(xl) {
	#faqs-games nav a {
// 		width: calc(10% - 10px);
	}
}

#faqs-list, #faqs-incoming {
	
	&:before {
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.8+0,0.2+100 */
background: -moz-linear-gradient(top,  		rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  	rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */ 
background: linear-gradient(to bottom,  	rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#33000000',GradientType=0 ); /* IE6-9 */

		height: 100%;
	}
	#faqs-game {
		position: relative;
		margin: 4vh 4vw;
		display: flex;
// 		flex-wrap: wrap;
		text-shadow: $small-black-text-shadow;
		width: 100%;
		
		img {
// 			margin-right: 0;
			width: 160px;
			height: 160px;
			max-width: 15vw;
			max-height: 15vw;
			flex: 0 0 160px;
			display: flex;
// 			float: left;
		}
		
		div {
			flex: 1 1 auto;
			align-self: center;
// 			width: calc(88vw - 120px);
			margin: 0 1rem;
			font-size: 5vw;
			
			h4 {
				font-size: 1.3em;
				
				margin-bottom: 0px;

				.icon-ios::before {
					transform: translateY(.1em);
				}
			}
			
			p {
				font-size: .8em;
				margin: 0 0 .8em;
				
				strong {
// 					display: inline-block; 
					font-weight: 500;
					span {
						font-weight: normal;
					}
				}
			}
		}
		
		+ nav + p {
			text-shadow: $small-black-text-shadow, $small-black-text-shadow, $small-black-text-shadow;
			margin: 2vh 30px 6vh;
			text-align: center;
			width: 100%;
			font-size: 5vw;
		}
		
	}
	
	nav {
		position: relative;
		margin: 10px 15px;
		width: 100%;
		a {
			display: block;
			margin: 5px 0px; 
			padding: 1em 1.2em;
			font-size: 6vw;
			border: solid 1px #4d4d4d;
			background-color: #111;
			border-radius: 2px;
			transition: background-color .2s linear, border-color .2s linear, color .2s linear;
			&:hover {
				background-color: black;
				border-color: #AAA;
				color: white;
				text-decoration: none;
			}
		}
	}

	p {
		margin: 10px 15px;
	}
	
	&:after {
		clear: both;
		display: block;
		content: '';
		width: 100%; height: 1px;
	}
}

#faqs-incoming #faqs-game div h4 {
	margin-bottom: .5em;
}

@include media-breakpoint-up(sm) {
	#faqs-list, #faqs-incoming {
		#faqs-game {
			div {
				font-size: 1.4rem;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	#faqs-list, #faqs-incoming {
		#faqs-game {
			div {
				font-size: 1.5rem;
			}
			+ nav + p {
				text-align: left;
				font-size: 1.7vw;
			}
		}
		nav {
			display: flex;
			flex-wrap: wrap;
			
			a {
				margin: 5px;
				width: calc(50% - 10px);
				font-size: 1.8vw;
				position: relative;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
				border: none;
				&:after {
					position: absolute;
					display: block;
					content: '';
					background-color: #4d4d4d;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 1px 10px, 1px calc(100% - 1px), calc(100% - 11px) calc(100% - 1px), calc(100% - 1px) calc(100% - 11px), calc(100% - 1px) 1px, 11px 1px, 1px 11px, 0px 10px);
					clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 1px 10px, 1px calc(100% - 1px), calc(100% - 11px) calc(100% - 1px), calc(100% - 1px) calc(100% - 11px), calc(100% - 1px) 1px, 11px 1px, 1px 11px, 0px 10px);
					z-index: -1;
					transition: background-color .2s linear;
				}
				
				&:hover {
					
					&:after {
						background-color: #AAA;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	#faqs-list, #faqs-incoming {
		#faqs-game {
			div {
				font-size: 1.6rem;
			}
			+ nav + p {
				font-size: 20px;
			}
		}
		nav a {
			margin: 5px;
			width: calc(33.333333% - 10px);
			font-size: 22px;
		}
	}
}