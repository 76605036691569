
#legal-content {
	
	flex-direction: row;
	&:before {
		background-image: none;
		background-color: rgba(255,255,255,.7);
		height: 100%;
		z-index: 0;
	}
	
	h3, p {
		margin: 1em auto;
		padding: 0 5%;
		width: 100%;
		max-width: 1200px;
		text-align: center;
		color: black;
		position: relative;
		z-index: 1;
		text-shadow: 0 0 10px white;
	}
	p {
		padding-top: 2em;
	}
	
	nav {
		display: flex;
		flex: 0 0 100%;
		margin: 2em auto;
		width: 100%;
		max-width: 1200px;
		flex-wrap: wrap;
		justify-content: center;
		position: relative;
		z-index: 1;
		a {
			display: flex;
			flex: 0 0 70%;
			margin: 1%;
			justify-content: center;
			padding: 5px 10px;
			background-color: rgba(0, 0, 0, 0.7);
			border: 1px solid rgba(0, 0, 0, 0.3);
			transition: border .2s linear, background .2s linear;
			border-radius: 3px;
			&:hover {
				background-color: rgba(0, 0, 0, 1);
				border: 1px solid black;
			}
		}
	}
	
}
	


@include media-breakpoint-up(md) {
	#legal-content {
		nav {
			a {
				
				flex: 0 0 40%;
			}
		}
		
	}
	
}

@include media-breakpoint-up(lg) {
	#legal-content {
		h3 {
			font-size: 2em;
		}
		nav {
			a {
				
				flex: 0 0 30%;
			}
		}
	}
	
}

@include media-breakpoint-up(xl) {
	#legal-content {
		
		
	}
}	