
#privacy-content {
	color: #000;
	&:before {
		background-image: none;
		background-color: rgba(255,255,255,.8);
		height: 100%;
	}
	
	.xscrollblock {
		width: 100%;
		overflow-x: hidden;
		
		> .row {
		// 	@include fade-in-up(2s, 2s);
			margin: 0 auto;
			max-width: 1200px;
		}

	}
	
	> .row {
	// 	@include fade-in-up(2s, 2s);
		margin: 0 auto;
		max-width: 1200px;
	}
		
	.about-content {
		display: flex;
		flex-direction: column;
		padding-bottom: 3rem;
		width: 100%;
		.terms {
// 			padding-right: 304px;
			max-width: 777px;
			margin: 0 1rem;
		}
	}
	
	nav {
		position: relative;
		display: inline-block;
		margin: 20px 0px; padding: 5px 7px;
		padding-right: 25px;
		background-image: url('/images/backgrounds/triangles/navigation.svg');
		background-position: center right;
		background-size: cover;
		background-repeat: no-repeat;
		color: #FFF;
		
		> span {
			font-size: .65em;
			top: -.15em;
			position: relative;
		} 
		
		a {
			color: #FFF;
			margin: 0px 5px;
			white-space: nowrap;
			
			&.active {
				color: $feral-orange;
			}
		}
		
		&.self {
			margin: 0px;
			padding: 20px;
			background-image: none;
			background-color: rgba(0,0,0,.8);
			border-radius: 4px;
			h3 {
				text-align: left;
				color: $feral-orange;
			}
			
			a {
				position: relative;
				text-align: left;
				background: none;
				padding: 3px 5px; margin: 0px;
				padding-left: 30px;
				white-space: unset;
				
				&:before {
					position: absolute;
					top: .45em; left: .5em;
					content: '☗';
					display: inline-block;
					transform: rotate(90deg);
					color: $feral-orange;
					font-size: .8em;
				}
				
/*
				&.top {
					padding-left: 5px;
					
					&:before {
						display: none;
					}
				}
*/
			}
		}
	}
	
	.about-text {
		position: relative;
		margin: 0 1rem;
	
		h2 {
			margin: 1.5rem 0 1rem; 
		}
		
		h3 {
			margin-top: 1em;
		}
		
		h4 {
			font-size: 1.15rem;
		}
		
		p {
			margin: 1rem .7rem 0rem;
		}
		
		ul {
			margin-left: .0em;
			margin-top: 1em;
			list-style: none;
			
			li {
				position: relative;
				&::before {
					
					@include fa-icon("\f0da");
					position: absolute;
					left: -1em;
					top: .25em;
				}
			}
		}
		table {
			margin-top: 1em;
			border-spacing: .1em;
		    border-collapse: separate;
		    font-size: 1em;
		    line-height: 1.4em;
		    background-color: rgba(0, 0, 0, 0.1);
		    border-radius: 7px;
// 			margin-left: -.7em;
// 			margin-right: -.7em;
		    /*

		    max-width: 100%;
		    overflow: scroll;
*/			
			thead {
				
				tr {
				    th {
					    background-color: rgba($feral-orange, .8);
						padding: .5em;
						text-align: left;
						color: white;
				    }
					:first-child {
						border-radius: 5px 0px 0px 0px;
					}
					:last-child {
						border-radius: 0px 5px 0px 0px;
					}
				}
			}
			tbody {
			    tr {
					td {
						background-color: rgba(255, 255, 255, 0.6);
						padding: .5em;
						vertical-align: top;
					}
					
					&:last-child {
						:first-child {
							border-radius: 0px 0px 0px 5px;
						}
						:last-child {
							border-radius: 0px 0px 5px 0px;
						}
					}
				}
			}
		}
		
		time {
			margin-bottom: 1em;
			display: block;
		}
		
		.vcard {
			padding: 1em 0 0 1.5em;
			
			.email {
				display: inline-block;
				padding-top: 1em;
			}
		}
		
	}
	
	.alert {
		margin: 1em 15px; padding: 10px 15px;
		background-color: rgba(255,255,255,.8);
		border: 1px solid rgba($feral-orange, .3);
		border-radius: 4px;
// 		color: $feral-orange;
		
		a {
// 			color: black;
// 			text-decoration: underline;
// 			opacity: .7;
			&:hover {
// 				opacity: 1;
			}
		}
	}
	
	h2 {
		margin: 10px 15px;
	}
	
	h4, h5 {
		margin: -1em 0 0 calc(30px - 1em);
		padding: 2.5em 0 0;
	}
	
	h3 + h4 {
		margin-top: -2em;
	}
	
	p {
		margin: 0px 10px 10px 25px;
	}
	
	aside {
		position: relative;
		margin: 1em auto 1em;
		text-align: center;
// 		order: 2;
		&.about-toc {
			order: -1;
			width: 95%;
			nav.self {
				width: 100%;
			}
		}
		
		a {
			display: block;
// 			margin: 10px 0px; 
			padding: 5px;
/*
			background-color: rgba(0,0,0,.8);
			border-radius: 3px;
*/
			text-align: center;
			span {
				margin-left: 0px;
				width: 200px;
				padding-top: 8px;
				-webkit-clip-path: polygon(19px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 19px);
						clip-path: polygon(19px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 19px);
				&::before {
					-webkit-clip-path: polygon(18px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 18px);
							clip-path: polygon(18px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 18px);
				}
			}
		}
		
		.platforms {
			display: none;
		}
	}
}

@include media-breakpoint-up(md) {
	#privacy-content {
		&:before {
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.7+0,0.9+4,0.8+69,0+100 */
background: -moz-linear-gradient(left,  rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.9) 4%, rgba(255,255,255,0.8) 69%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.9) 4%,rgba(255,255,255,0.8) 69%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.9) 4%,rgba(255,255,255,0.8) 69%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

		}
		nav {
			&::after { 
				content: ' ';
				background-color: rgba(0, 0, 0, 0.8);
				position: absolute;
				top: 0;
				bottom: 0;
				right: 100%;
				width: 50vw;
			}
			
			
		}
		.about-content {
			flex-direction: row;
			
			aside {
				margin-right: 0px;
				margin-left: 3vw;
// 				flex-grow: 1;
// 				display: flex;
				
/*
				> img {
					margin-right: 2vw;
					margin-bottom: 30px;
				}
*/
				
				&.about-toc {
					order: 0;
					width: 90%;
					nav.self {
						margin: 0px;
						padding: 20px;
						background-image: none;
						background-color: rgba(0,0,0,.8);
						width: 300px;
						border-radius: 25px 4px 4px 4px;
						-webkit-clip-path: polygon(19px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 19px);
						clip-path: polygon(19px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 19px);
						&::after { 
								content: ' ';
								background-color: rgba(0, 0, 0, 0.8);
								position: absolute;
								top: 0;
								bottom: 0;
								left: 100%;
								width: 50vw;
							}
						h3 {
							text-align: left;
							color: $feral-orange;
						}
						
						a {
							position: relative;
							text-align: left;
							background: none;
							padding: 3px 5px; margin: 0px;
							padding-left: 30px;
							white-space: unset;
							
							&::after {content:none;}
/*
							&.top {
								padding-left: 5px;
								
								&:before {
									display: none;
								}
							}
*/
						}
					}
				}
				
				a {
					background-color: transparent;
					background-image: url('/images/backgrounds/triangles/side-button.svg');
					background-position: left center;
					background-size: cover;
					background-repeat: no-repeat;
					text-align: left;
					border-radius: 0px;
						
					margin: 10px 0px; 
					padding: 10px;
					
					position: relative;
					&::after { 
						content: ' ';
						background-color: rgba(0, 0, 0, 0.8);
						position: absolute;
						top: 0;
						bottom: 0;
						left: 100%;
						width: 50vw;
					}
					
				}
				
				
			}			
			
			.about-text {
				flex-grow: 3;
				margin: 0rem 1rem 4rem;
// 				display: flex;
				font-size: 1.8vw;
				h2 {
					font-size: 3vw;
					margin: 1em auto .7em;
				}
				h3, h4, h5 {
					margin-top: calc(1.5em - 80px);
					padding-top: 80px;
				}
				h4 {
					font-size: 2.2vw;
				}
				h5 {
					font-size: 1.8vw;
				}
				p {
					font-size: 1em;
					margin: 1em .7em 0;
				}
				
				ul {
					margin-left: .6em;
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	#privacy-content {
		.row {
			
			aside {
				margin-left: 7vw;
			}
			
			.about-text {
				font-size: 1.8vw;
				text-shadow: $medium-whte-text-shadow;
				h2 {
					font-size: 3vw;
					margin: 1em auto .7em;
				}
				
				h3 {
					font-size: 2.5vw;
				}
				
				h4 {
					font-size: 2.2vw;
				}
				
				h5 {
					font-size: 1.9vw;
				}
				
				.alert {
					padding: 1.4vw 1.9vw;
				}
				
				p {
					font-size: 1em;
					margin: 1em .7em 0;
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
				
				li {
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
				
				table {
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
			}
		}
		
	}
	
}

@include media-breakpoint-up(xl) {
	#privacy-content {
		
		&:before {
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.7+0,0.9+10,0.8+67,0+100 */
background: -moz-linear-gradient(left,  rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.9) 10%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.9) 10%,rgba(255,255,255,0.8) 67%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,0.7) 0%,rgba(255,255,255,0.9) 10%,rgba(255,255,255,0.8) 67%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */


		}
		.row {
			h3 {
				font-size: 29px;
			}
			aside {
				margin-left: 84px;
				> img {
					margin-right: 24px;
				}
			}
			
			.about-text {
				font-size: 22px;
				h2 {
					font-size: 36px;
				}
				
				h3 {
					font-size: 30px;
				}
				
				h4 {
					font-size: 24px;
				}
				
				h5 {
					font-size: 20px;
				}
				
				.alert {
					padding: 17px 23px;
				}
			}
		}
		
	}
}	

	
/*

#privacy-content .about-content.privacy-games aside.about-toc a.top {
	padding-left: 5px;
	
	&:before {
		display: none;
	}
}
*/
	
	
	
