/*#top-feature-details {
	display: flex;
	
	h5 {
		margin-left: auto;
		display: block;
		align-self: center;
		text-align: right;
		width: auto;
		white-space: nowrap;
		
		* {
			display: none;
		}
		
		span.fab {
			display: inline-block;
		}
	}
}*/

#top-header {
	min-height: 60vh;
// 	min-height: 341px;
}

@include media-breakpoint-up(md) {
	#top-header {
		min-height: 61.32vh;
		h2 span {
			[lang=en] & {
				max-width: 210px;
			}
		}
	}
}

#games-list {
	background-color: #000;
	
	.row {
		@include fade-in-up(1.5s, 0);
	}
	
	// Removing annoying listings from stallman — remove the game code from here if we ever want to list these
	
	.gridautosport-tvos, .lisbeforethestorm {
		display: none;
	}
	
	//
	
	.dlc, .expansion {
		.panel {
			.dlc-note {
				position: absolute;
				left: 0;
				top: 7%;
				width: auto;
				height: auto;
				padding: .5em 1.1em .45em .65em;
				font-weight: 700;
				background-image: url('/images/backgrounds/triangles/dlc-frame.svg');
				background-position: right center;
				background-size: cover;
				background-repeat: no-repeat;
				z-index: 1;
				text-transform: uppercase;
				text-align: left;
				span {
					display: inline-block;
// 					transition: font-size .2s linear, opacity .2s linear;
					font-size: .8rem;
					position: relative;
					top: -.1em;
				}
				em, strong {
					opacity: 0;
					font-size: .05rem;
					width: 0;
					height: 0;
					display: inline-block;
					overflow: hidden;
					transition: font-size .2s linear, opacity .2s linear;
				}
				em {
// 					font-size: .4em;
					font-weight: 400;
					font-style: normal;
				}
			}
			
			&:hover {
				.dlc-note {
					span {
						opacity: 0;
/*
						font-size: .5rem;
						width: 0;
						height: 0;
*/
						overflow: hidden;
						display: none;
					}
					em, strong {
						opacity: 1;
						font-size: .8rem;
						width: auto;
						height: auto;
						display: inline-block;
					}
					em {
						display: block;
						font-size: .6rem;
					}
				}
			}
/*
			&::before {
				content: 'DLC';
				position: absolute;
				right: 0;
				top: 7%;
				width: auto;
				height: auto;
				padding: .5em .55em .45em 1.2em;
				font-weight: 700;
				background-image: url('/images/backgrounds/triangles/dlc-frame.svg');
				background-position: left center;
				background-size: cover;
				background-repeat: no-repeat;
				z-index: 1;
				text-transform: uppercase;
				text-align: right;
			}
*/
			
/*
			&:hover::before {
				content: attr(data-basegame);
				padding-left: 2em;
				max-width: 95%;
			}
*/
		
		}
		
		
	}
	
	.expansion {
		.panel {
			&::before {
				content: 'Expansion';
			}
		}
	}
	
	
	
	.panel {
		overflow: hidden;
		position: relative;
		min-height: 140px;
	}
	
	.panel-body {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		min-height: 25vh;
// 		min-height: 140px;
// 		min-height: 200px;
		padding: 10px 10px 0px;
		background-size: cover;
		background-position: center center;
		
		&:before {
			display: block;
			content: '';
			position: absolute;
			bottom: 0px; left: 0px;
			background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.6) 20%, rgba(0,0,0,.85) 50%, rgba(0,0,0,1));
			background-repeat: no-repeat;
			width: 100%; height: 39%;
			z-index: 0;
		}
		
		h4, h5, .panel-buttons {
			position: relative;
			width: 100%;
		}
		
		h4 {
			position: relative;
			font-size: 1rem;
			font-weight: normal;
			margin-top: auto;
			margin-right: auto;
			text-shadow: $small-black-text-shadow;
		}
		
		h5 {
			display: flex;
			font-size: .9rem;
			margin: 0px;
			text-shadow: $small-black-text-shadow;
			> * {
				margin-left: 5px;
				align-self: center;
				order: unset;
				&:first-child {
					margin-left: 0px;
					margin-right: .2em;
				}
				&[class^="icon-"], &[class*=" icon-"] {
					margin-right: 7px;
				}
				&[class^="icon-"]:before, &[class*=" icon-"]:before {
					font-size: 1.4em;
					margin: -.2em .05em 0;
				}
				&.icon-ios::before, &.icon-android::before {
					position: relative;
					bottom: -.12em;
				}
				&.icon-mac {
					margin-right: 3px;
				}
			}
		
			.price {
				margin-left: auto;
				order: 8;
				& ~ .price {
					margin-left: 5px;
				}
				&.tbyb {
					font-size: .8em;
					text-transform: uppercase;
					margin-bottom: -.3em;
					@include media-breakpoint-up(md) {
						max-width: 33%;
						text-align: center;
						white-space: normal;
						margin-top: -.35em;
						margin-bottom: -.2em;
						flex-wrap: wrap;
					}
				}
			}
		}
	}
	
	.panel-buttons {
		display: flex;
		padding: 5px 10px 10px;
		background-color: #000;
		
		.btn {
			display: flex;
			flex: 1 1 50%;
			justify-content: center;
			align-items: center;
			&:nth-child(2) {
				margin-left: 4px;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	#games-list {
		.row {
		    align-items: center;
		    justify-content: center;
// 		    @include fade-in-up(1.5s, 0s);
	    }
	    .panel {
			margin: 10px;
			-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
			background-color: rgba(0,0,0,0.7);
			
				
			&:after {
				position: absolute;
				display: block;
				content: '';
				background-color: #181818;
				background-color: #414042;
				top: 0px; left: 0px;
				bottom: 0px; right: 0px;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				z-index: -1;
			}
			
			.panel-body {
				flex-direction: column;
				align-items: flex-start;
				-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
				clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
// 				min-height: 25vw;
				min-height: calc(25vw - 17px);
				padding: 10px 15px 10px;
				box-shadow: inset 0px 0px 15px 7px rgba(0, 0, 0, .9);
				h4 {
					color: $feral-orange;
					font-size: 2vw;
					
				}
				
				h5 {
					font-size: 1.6vw;
					span {
						display: inline-block;
						font-weight: normal;
						font-size: 1.2em;
						&:not(:last-child) {
							font-size: 1em;
							margin-right: .3em;
						}						
						&.icon-mac {
							margin-right: .15em;
						}
					}
				}
				
				&:after {
					position: absolute;
					display: block;
					content: '';
					background-color: #000;
					background-color: #1a1a1a;
					top: 0px; left: 0px;
					bottom: 0px; right: 0px;
					-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
					clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
				}
			}
			
			.panel-buttons {
				background: none;
				
				.btn {
					font-size: 1.4vw;
					&:nth-child(2) {
// 						margin-left: .5vw;
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	#games-list .panel {
		
		.panel-body {
			min-height: 19vw;
			h4 {
				font-size: 1.5vw;
			}
			h5 {
				font-size: 1.2vw;
			}
		}
		.panel-buttons {
			.btn {
				font-size: 1.05vw;
			}
		}
	}
}