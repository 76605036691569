#top-header {	
	#jarallax-container-0 {
		div {
			height: 30vh !important;
			background-position: right top !important;
		}
	}
}
@include media-breakpoint-up(md) {
	#top-header {	
		#jarallax-container-0 {
			div {
				height: 35vh !important;
				background-position: right 23% !important;
			}
		}
	}
}
#privacy-content {

	&::before {
		z-index: 0;
	}
	
	.xscrollblock {
		z-index: 1;
		position: relative;
		.row {
			.about-content {
				display: block;
				margin: 0 1rem 3rem;
				h2 {
					margin: 1.5rem 0 1rem; 
				}
				h3 {
					margin: 0rem 0 1rem .3rem;
					padding-top: .5rem;
					padding-bottom: .4rem;
					border-bottom: 2px solid $feral-orange;
				}
				p {
					margin: 0px .7rem 1rem;
				}
				
				#postal-address, #company-registration, #tax-number {
					margin-bottom: 1rem;
					p {
						margin-bottom: 0;
					}
				}
				
				ul {
					margin-left: .0em;
					list-style: none;
					
					li {
						position: relative;
						&::before {
							@include fa-icon("\f0da");
							position: absolute;
							left: -1em;
							top: .25em;
						}
					}
				}
			}
		}
	}
	
}

@include media-breakpoint-up(md) {
	#privacy-content {
		.xscrollblock {
			.row .about-content {
			
			
			
			
				flex-grow: 3;
				margin: 0rem 28% 4rem 1rem;
// 				display: flex;
				font-size: 1.8vw;
				h2 {
					font-size: 3vw;
					margin: 1em auto .7em;
				}
				
				p {
					font-size: 1em;
					margin: 0px .7em 1em;
				}
				
				ul {
					margin-left: .6em;
				}
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	#privacy-content {
		.xscrollblock {
				.row .about-content {
			
			
				font-size: 1.8vw;
				text-shadow: $medium-whte-text-shadow;
				h2 {
					font-size: 3vw;
					margin: 1em auto .7em;
				}
				
				h3 {
					font-size: 2.5vw;
				}
				
				h4 {
					font-size: 2.2vw;
				}
				
				h5 {
					font-size: 1.9vw;
				}
				
				.alert {
					padding: 1.4vw 1.9vw;
				}
				
				p {
					font-size: 1em;
					margin: 0px .7em 1em;
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
				
				li {
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
				
				table {
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
			}
		}
		
	}
	
}

@include media-breakpoint-up(xl) {
	#privacy-content {
		.xscrollblock {
			.row .about-content {
			
			
				font-size: 22px;
				h2 {
					font-size: 36px;
				}
				
				h3 {
					font-size: 30px;
				}
				
				.alert {
					padding: 17px 23px;
				}
			}
		}
		
	}
}	