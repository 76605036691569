.xscrollblock {
	width: 100%;
	overflow-x: hidden;
}

.row {
// 	@include fade-in-up(2s, 2s);
	margin: 0 auto;
	max-width: 1200px;
}

#top-header {	
	#jarallax-container-0 {
		div {
			height: 155% !important;
			background-position: right top !important;
		}
	}
}

@include media-breakpoint-up(md) {
	#top-header {	
		#jarallax-container-0 {
			div {
				height: 35vh !important;
				background-position: right 23% !important;
			}
		}
	}
}
#about-content {
	display: flex;
	flex-direction: column;
	color: #000;
	
	&:before {
		background-image: none;
		background-color: rgba(255,255,255,.85);
		height: 100%;
	}
	
	aside {
		position: relative;
		margin: 10px auto;
		text-align: center;
		
		> img {
			margin: 1rem auto 2rem;
			width: 200px;
		}
		
		a {
			display: block;
// 			margin: 10px 0px; 
			padding: 5px;
/*
			background-color: rgba(0,0,0,.8);
			border-radius: 3px;
*/
			text-align: center;
			span {
				margin-left: 0px;
				width: 200px;
				padding-top: 8px;
				-webkit-clip-path: polygon(19px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 19px);
						clip-path: polygon(19px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 19px);
				&::before {
					-webkit-clip-path: polygon(18px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 18px);
							clip-path: polygon(18px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 18px);
				}
			}
		}
		
		.platforms {
// 			display: none;
			margin-top: 1em;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			span {
// 				display: flex;
				height: 80px;
				margin: 10px;
				&.icon-linux {
					&::before {
						content: '\e80c';
					}
				}
				&::before {
					margin: 0;
					font-size: 500%;
				}
				
				&.platform-mac {
					order: 1;
				}
				&.platform-ios {
					order: 2;
				}
				&.platform-linux {
					order: 3;
				}
				&.platform-android {
					order: 4;
				}
				&.platform-switch {
					order: 5;
				}
				&.platform-windows {
					order: 6;
				}
				&.platform-tvos {
					order: 7;
				}
			}
		}
	}
}

.about-text {
	position: relative;
	margin: 0 1rem;
	
	h2 {
		margin: 1.5rem 0 1rem; 
	}
	
	p {
		margin: 0px .7rem 1rem;
	}
}

@include media-breakpoint-up(md) {
	#about-content {
		flex-direction: row;
		.row {
			flex-direction: row;
			flex-wrap: nowrap;
			aside {
				margin-right: 0px;
				margin-left: 3vw;
// 				flex-grow: 1;
// 				display: flex;
				
				> img {
// 					margin-right: 2vw;
					margin-bottom: 30px;
				}
				
				a {
					background-color: transparent;
					background-image: url('/images/backgrounds/triangles/side-button.svg');
					background-position: left center;
					background-size: cover;
					background-repeat: no-repeat;
					text-align: left;
					border-radius: 0px;
						
					margin: 10px 0px; 
					padding: 10px;
					
					position: relative;
					&::after { 
						content: ' ';
						background-color: rgba(0, 0, 0, 0.8);
						position: absolute;
						top: 0;
						bottom: 0;
						left: 100%;
						width: 50vw;
					}
					
				}
			}
			
			.about-text {
				flex-grow: 3;
				margin: 0rem 1rem 4rem;
// 				display: flex;
				font-size: 1.8vw;
				h2 {
					font-size: 3vw;
					margin: 1em auto .7em;
				}
				
				p {
					font-size: 1em;
					margin: 0px .7em 1em;
				}
			}
		}
		
	}
	
}

@include media-breakpoint-up(lg) {
	#about-content {
		.row {
			
			aside {
				margin-left: 7vw;
			}
			
			.about-text {
				font-size: 1.8vw;
				text-shadow: $medium-whte-text-shadow;
				h2 {
					font-size: 3vw;
					margin: 1em auto .7em;
				}
				
				p {
					font-size: 1em;
					margin: 0px .7em 1em;
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
			}
		}
		
	}
	
}

@include media-breakpoint-up(xl) {
	#about-content {
		.row {
			
			aside {
				margin-left: 84px;
				> img {
// 					margin-right: 24px;
				}
			}
			
			.about-text {
				font-size: 22px;
				h2 {
					font-size: 36px;
				}
			}
		}
		
	}
}	