.row {
	margin: 0 auto;
	width: 100%;
	max-width: 1200px;
	position: relative;
	z-index: 1;
}
#sitemap-content {
	color: #000;
	&:before {
		background-image: none;
		background-color: rgba(255,255,255,.85);
		height: 100%;
	}
	
	.about-content {
		display: flex;
		flex-direction: column;
	}
	
	nav {
		position: relative;
		display: inline-block;
		margin: 20px 0px; padding: 5px 7px;
		padding-right: 25px;
		background-image: url('/images/backgrounds/triangles/navigation.svg');
		background-position: center right;
		background-size: cover;
		background-repeat: no-repeat;
		color: #FFF;
		
		> span {
			font-size: .65em;
			top: -.15em;
			position: relative;
		} 
		
		a {
			color: #FFF;
			margin: 0px 5px;
			white-space: nowrap;
			
			&.active {
				color: $feral-orange;
			}
		}
		
		
	}
	.about-text {
		position: relative;
		margin: 0 1rem;
	
		h2 {
			margin: 1.5rem 0 1rem; 
		}
		
		p {
			margin: 0px .7rem 1rem;
		}
		
		ul {
			margin-left: .0em;
			list-style: none;
			
			li {
				position: relative;
				&::before {
					content: "\f0da";
					display: inline-block;
					font-family: "Font Awesome 5 Free";
					position: absolute;
					left: -1em;
					top: .05em;
				}
			}
		}
	}
	h2, h3 {
		margin: 1em 15px 1em;
		
	}
	
	h4, h5 {
		margin: 1.5em 0 1em calc(30px - 1em);
	}
	
	p {
		margin: 0px 10px 10px 25px;
	}
	
	ul {
		margin-left: .0em;
		list-style: none;
		width: 100%;
		margin-bottom: 1em;
		padding-left: 1.5em;
		> li {
			a {
				font-weight: bold;
				text-transform: uppercase;
			}
			> ul {
				
				li {
					position: relative;
					
					&::before {
						content: "\f0da";
						display: inline-block;
						font-family: "Font Awesome 5 Free";
						position: absolute;
						left: -1em;
						color: $feral-orange;
		// 				top: .05em;
					}
					
					a {
						text-transform: none;
						font-weight: 400;
					}
				}
			}
		}
		
		a {
			color: rgba(0, 0, 0, 1);
			opacity: .8;
			&:hover {
				opacity: 1;
			}
		}
		
	}
	
}
	


@include media-breakpoint-up(md) {
	#sitemap-content {
		flex-direction: row;
		.row {
/*
			flex-direction: row;
			flex-wrap: nowrap;
*/
			
			nav {
				&::after { 
					content: ' ';
					background-color: rgba(0, 0, 0, 0.8);
					position: absolute;
					top: 0;
					bottom: 0;
					right: 100%;
					width: 50vw;
				}
				
				
			}
			
			
			.about-text {
				font-size: 1.8vw;
				
				h2, h3 {
					font-size: 3vw;
					margin: 1em auto .7em;
					width: 100%;
				}
				
				p {
					font-size: 1em;
					margin: 0px .7em 1em;
				}
				
				ul {
	// 					margin-left: .6em;
					padding-right: 1em;
					margin-bottom: 1.6em;
					display: flex;
					flex-wrap: wrap;
					> li {
						width: 24%;
						
						ul {
							display: block;
							padding-top: .5em;
							li {
								width: 100%;
								
								margin-bottom: .4em;
								line-height: 1.2em;
							}
						}
						
					}
				}
			}		
		}
		
	}
	
}

@include media-breakpoint-up(lg) {
	#sitemap-content {
		.row {
			
			
			.about-text {
				
				text-shadow: $medium-whte-text-shadow;
				
				h2, h3 {
					font-size: 3vw;
					margin: 1em auto .7em;
					width: 100%;
				}
				
				h3 {
					font-size: 2.5vw;
				}
				
				h4 {
					font-size: 2.2vw;
				}
				
				h5 {
					font-size: 1.9vw;
				}
				
				
				p {
					font-size: 1em;
					margin: 0px .7em 1em;
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
				
				li, li ul li a {
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}

			}
		}
		
	}
	
}

@include media-breakpoint-up(xl) {
	#sitemap-content {
		.row {
			
			
			
			.about-text {
			
			
			
				font-size: 22px;
				h2 {
					font-size: 36px;
				}
				h3 {
					font-size: 29px;
				}
			}
				
		}
		
	}
}	