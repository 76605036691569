$feral-orange: #F15A24;

// radar
$radarGreyBorder: #404040;

$radarBlips: ("notSoon": #159eb7, "quiteSoon": #C00, "soon": #ffd200, "verySoon": #008546);
$radarOutNow: #381e12;

@function blip-color($level) {
	@return map-get($radarBlips, $level);
}