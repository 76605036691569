#top-header {	
	#jarallax-container-0 {
		div {
			height: 155% !important;
			background-position: 50% top !important;
		}
	}
}

@include media-breakpoint-up(sm) {
	#top-header {	
		#jarallax-container-0 {
			div {
				height: 35vh !important;
				background-position: 50% 23% !important;
			}
		}
	}
}

#news-top, #news-list article {
	&:before {
		display: block;
		content: '';
		position: absolute;
		top: 0px; left: 0px;
		@include gradient-y-three-colors(rgba(0,0,0,0.9), rgba(0,0,0,0.7), 50%, rgba(0,0,0,0.9));
		width: 100%; height: 100%;
	}
	
	div.featured-news-image-small {
		position: relative;
		width: 100%;
		height: 26.5vw;
		overflow: hidden;
// 		display: flex;
		img {
			width: 100%;
			
			margin: -18% auto;
			
		}
	}
	
	.article-content {
		img {
			max-width: 100%;
		}
		ul {
			list-style: none;
			padding-left: 2em;
			li {
				position: relative;
				&::before {
					@include fa-icon("\f0da");
					position: absolute;
					left: -1em;
					top: .25em;
				}
			
			}
		}
	}
	
	.platforms {
		display: flex;
		margin-bottom: 15px;
		
		> span {
			margin-right: 5px;
			align-self: center;
		}
		
		time {
			margin-left: auto;
			align-self: center;
			order: 8;
		}
	}
	
	h3 {
// 		font-size: 1.2rem;
		margin-bottom: 1.2rem;
	}
	
	.shareable {
		display: flex;
		margin-bottom: 15px;
		
		
		> * {
// 			margin-left: 10px;
		}
		
		.copy-link {
			margin-left: 0px;
			margin-right: auto;
		}
		
		.copy-link, .fa-x-twitter, .fa-facebook-f, .fa-reddit-alien, .fa-envelope {
			border-color: black;
			
		}
	}
	
	.iframe-holder {
		position: relative;
		width: 100%;
		padding-bottom: 56.25%; /* 16:9 */
		margin-bottom: 1.5em;
		border-radius: 5px;
		border: 3px solid rgba($radarGreyBorder, 0.5);
		overflow: hidden;
		iframe {
			position: absolute;
			top: 0px; left: 0px;
			width: 100%; height: 100%;
		}
	}
	
	video {
		width: 100%;
		height: auto;
	}
	
	aside {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		
		h5 {
			font-size: 1rem;
			display: flex;
			margin: 25px 0px;
			
			img {
				width: 60px; height: 60px;
				flex-shrink: 0;
				flex-grow: 0;
				margin-right: 20px;
			}
			
			span {
				align-self: center;
				text-align: left;
			}
		}
		
		.article-buttons {
			display: flex;
			width: 102%;
			max-width: 102%;
			margin: 0 -1%;
			flex-wrap: wrap;
			justify-content: space-between;
			* {
				width: 100%;
				display: flex;
				flex: 1 1 48%;
				margin: 1%;
				justify-content: center;
				&:nth-child(2) {
// 					margin-left: 10px;
				}
			}
		}
	}
}

#news-top {
	border-bottom: solid 3px #FFF;
	
	aside > div:first-child {
		display: none;
	}
	
	.article-holder {
		position: relative;
		margin: 10px 30px;
		padding-top: 1rem;
		padding-bottom: 2rem;
	}
}

img, iframe, div {
	+ p {
		margin-top: 1rem;
	}
}

@include media-breakpoint-up(md) {
	#news-top, #news-list article {
		.article-content {
			font-size: 1.8vw;
			ul {
				margin-left: .7em;
				margin-right: .7em;
				li {
					
					&::before {
// 						top: .05em;
					}
				
				}
			}
			p {
				margin-left: .7em;
				margin-right: .7em;
			}
		}
		
		div.featured-news-image-small {
			height: 13.3vw;
		}

		
		h3 {
			margin: 1.4em auto 1.4em;
		}
	}
	#news-top {
		div.featured-news-image-small {
			display: none;
		}
		
		.shareable {
			position: absolute;
			top: 0px; right: 0px;
			.copy-link {
				margin-right: .7em;
			}
		}
		.article-holder {
			display: flex;
			margin: 0 auto;
			max-width: 1200px;
			.article-content {
				order: 2;
				width: 100%;
				max-width: 810px;
				padding-left: 30px;
				
			}
	
			aside {
				order: 1;
				text-align: right;
				align-items: flex-end;
// 				margin-right: 20px;
		
				div:first-child {
					display: block;
					width: 100%;
					max-width: 300px;
					-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
					clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
					background-color: rgba(0,0,0,0.7);
					position: relative;
					padding: 2px;
					
					&:after {
						position: absolute;
						display: block;
						content: '';
						background-color: #181818;
						background-color: #414042;
						top: 0px; left: 0px;
						bottom: 0px; right: 0px;
						-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
						clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px, 2px 10px, 2px calc(100% - 2px), calc(100% - 12px) calc(100% - 2px), calc(100% - 2px) calc(100% - 12px), calc(100% - 2px) 2px, 12px 2px, 2px 12px, 0px 10px);
						z-index: -1;
					}
					
					img {
						width: 100%;
						position: relative;
						z-index: 2;
						-webkit-clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
						clip-path: polygon(10px 0px, 100% 0px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0px 100%, 0px 10px);
						box-shadow: inset 0px 0px 15px 7px rgba(0, 0, 0, .9);
					}
				}
		
				h5 {
					width: 100%;
					max-width: 300px;
				}
				.article-buttons {
// 					width: 100%;
// 					max-width: 300px;
				}
			}
	
			.platforms {
				padding: 3px 0px;
		
				time {
					margin-left: 10px;
				}
			}
		}
	}
	
	#news-list article .shareable .copy-link {
		margin-right: auto;
	}
}

@include media-breakpoint-up(lg) {
	#news-top, #news-list article {
		.article-content {
			font-size: 1.5vw;
		}
		.platforms time {
			margin-left: 10px;
		}
	
	}
	#news-list article .shareable {
		position: absolute;
		top: 0px; right: 0px;
	}
}	

@include media-breakpoint-up(xl) {
	#news-top, #news-list article {
		h3 {
			font-size: 29px;
		}
		.article-content {
			font-size: 18px;
		}
	}
	#news-list article .shareable .copy-link {
		margin-right: .7em;	
	}

}	

#news-list {
	padding-bottom: 0px;
	
	.row.no-gutters {
		padding: 0;
	}
	
	article {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border-top: solid 3px #000;
		border-bottom: solid 10px #333;
		padding-bottom: 3rem;
	
		&:before {
			border-bottom: solid 3px #FFF;
		}
	
		.article-content {
			position: relative;
			margin: 30px;
			width: calc(100% - 60px);
		}
	
		aside {
			position: relative;
			margin: 10px 30px;
			margin-top: auto;
			width: calc(100% - 60px);
			
			h5 {
				margin-bottom: 25px;
			} 
		}
	}
}

.article-tags {
	flex: 0 0 auto;
	width: 100%;
	text-align: left;
	padding: 3em 0 1em;
	flex-direction: row;
	font-size: .85em;
	flex-wrap: nowrap;
	display: flex;
	strong {
		display: flex;
		flex: 1 1 auto;
		margin-right: 10px;
	}
	span {
		flex: 1 1 100%;
		flex-wrap: wrap;
		a {
			white-space: nowrap;
			color: white;
		}
	}
}

@include media-breakpoint-up(md) {
	#news-list {
		padding-top: 28px;
		background: #1a1a1a;
		article {
			&:nth-child(odd) {
				flex: 0 0 49.5%;
				max-width: 49.5%;
				margin-right: .5%;
			}
			&:nth-child(even) {
				flex: 0 0 49.5%;
				max-width: 49.5%;
				margin-left: .5%;
			}
			&:nth-child(odd):last-child {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

#load_more {
	@include gradient-y(#111, #666);
	text-align: center;
	padding: 30px;
	border-top: solid 3px #000;
	
	a {
		display: block;
		color: #FFF;
		font-size: 1.5rem;
		text-decoration: none;
		
		span {
			display: block;
			margin-top: 10px;
			font-size: 3rem;
		}
	}
}

@include media-breakpoint-up(md) {
	#load_more {
		padding-bottom: 80px;
	}
}



// Specific content styles 



#iphonescreen, .iphonescreen {
	background: url('/images/news/frames/iPhone-8Plus-Black.png') center center no-repeat transparent;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.5),
			only screen and (min-moz-device-pixel-ratio: 1.5),
			only screen and (min-resolution: 240dpi) {
				
				background-image: url('/images/news/frames/iPhone-8Plus-Black@2x.png');
			
	}
	padding: 3.4% 11.6% 3.6% 11.6%;
	margin: 1em auto;
	background-size: contain;
	width: 100%;
	
}	

img#ipadscreen, img.ipadscreen {
	background: url('/images/news/frames/iPad-Pro-105-Silver.png') no-repeat transparent;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.5),
			only screen and (min-moz-device-pixel-ratio: 1.5),
			only screen and (min-resolution: 240dpi) {
				
				background-image: url('/images/news/frames/iPad-Pro-105-Silver@2x.png');
			
	}
    padding: 3.0% 7.6% 3.5%;
    
    background: url('/images/news/frames/iPad-Pro-129-Black.png') no-repeat transparent;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.5),
			only screen and (min-moz-device-pixel-ratio: 1.5),
			only screen and (min-resolution: 240dpi) {
				
				background-image: url('/images/news/frames/iPad-Pro-129-Black@2x.png');
			
	}
    padding: 3.5% 3.4% 3.5% 3.55%;
    border-radius: 4.5%/6.5%;
    
    background-size: contain;
    width: calc(100% + 2em);
    max-width: calc(100% + 2em) !important;
	margin: .5em -1em;
}

.genericphonescreenframe {
	position: relative;
	margin: 1em -1em; 
	&::after {
		content: ' ';
		background: url('/images/news/frames/generic-phone-frame.png') no-repeat transparent;
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.5),
				only screen and (min-moz-device-pixel-ratio: 1.5),
				only screen and (min-resolution: 240dpi) {
					
					background-image: url('/images/news/frames/generic-phone-frame@2x.png');
				
		}
		background-size: contain;
		position: absolute;
		top: 0;
		height: 100%;
		left: 0;
		width: 100%;
		pointer-events: none;
	}
	img {
		padding: 2% 5.0% 2% 5.3%;
		width: 100%;
	}
}

.appstorebadge {
	display: block;
	height: 70px;
	margin: 1.5em auto;
	
}

// Article specific styles (often messy and hastily adapted from old site styles)

article#a676 {
	#ipads {
		background-color: rgba(126, 10, 10, 0.8);
		border: 3px solid #ffda6d;
		border-left: 0;
		border-right: 0;
		width: 100%;
		padding: 10px;
		margin: 1.2em 10px;
		text-align: center;
		box-shadow: 0px 5px 0px black, 0px -5px 0px black, inset 0px 0px 35px 0px rgba(0, 0, 0, .9);
		
		h2 {
			border-bottom: 2px solid #ffda6d;
			margin: .5em 0 2em;
			padding: 0 0 .8em;
			width: auto;
			color: #ffda6d;
			text-transform: uppercase;
		}
	
		svg {
			width: 88%;
			margin: 0 auto;
			
			.cls-1 {
				fill: white;
			}
		}
		
		ul {
			width: 30%;
			margin: 20px 0 1em 4.5%;
			padding: 0;
			float: left;
			li {
				list-style: none;
				margin: 0;
				padding: 0;
				.fr & {
// 					font-size: 13px;
				}
				.es &, .ru &, .pt_br & {
// 					font-size: 13px;
// 					line-height: 13px;
// 					margin-bottom: 9px;
				}
			}
			&:first-of-type {
				margin-left: 6.5%;
				margin-right: 0;
			}
			
			&:last-of-type {
				margin-right: 0;
				margin-left: 0;
				width: 27%;
			}
		}
	}
}
article#a818 {
	#devices {
	    font-size: 16px;
		text-align: center;
		padding: 10px 1.33em 30px;
		background-color: rgba(245, 245, 245, 1);
		border: 1px solid #cdcdcd;
		border-radius: 3px;
		h4 {
		    font-size: 1.3em;
	        clear: both;
		    padding: .2em 0 .3em;
		    margin-left: 5%;
		    margin-right: 5%;
	        border-bottom: 2px solid #c00;
		    border-top: 2px solid #c00;
		    margin: 1.8em 0 1em;
		    font-family: 'Michroma',sans-serif;
		    letter-spacing: .15em;
		    text-transform: uppercase;
		    font-weight: 400;
		    color: black;
    		span {
	    		text-transform: lowercase;
    		}
    		&:first-child {
	    		margin-top: .5em;
    		}
		}
		.ipadname, .iphonename {
		    text-align: center;
		    list-style: none;
		    margin: .3em 0 0;
		    padding: .5em .1em;
		    box-sizing: border-box;
		    line-height: 1.2em;
		    background: #fff;
		    font-size: 1em;
		    border: 1px solid rgba(0,0,0,.15);
		    border-radius: 2px;
		    color: #575757;
		}
		ul li::before {
			content: none;
		}
		#ipads {
		    padding: 0 6%;
		    box-sizing: border-box;
		    ul {
				padding: 0;			    
				float: left;
				margin: 0 2%;
				display: block;
				list-style: none;		    
			    &#largepad {
				    width: 32%;
				    img {
					    width: 87.481%;
					    margin: 0 auto;
					}
				}
				&#mediumpad {
				    width: 28%;
				    img {
					    width: 79.271%;
					    margin: 0 auto;
					}
				}
				&#smallpad {
				    width: 26%;
				    img {
					    width: 82.584%;
					    margin: 0 auto;
					}
				}
			}

		}
		#iphones {
			padding: 0 8%;
			box-sizing: border-box;
			.iphonesplit {
				float: left;
				width: 48%;
				padding: 0;
				margin: 0;
				
			    &:first-child {
				    width: 52%;
				}
				
				ul {
				    float: left;
				    margin: 0 3.5%;
				    padding: 0;
				    &#largephone {
					    width: 44%;
					    img {
						    width: 44.5%;
						    margin: 0 auto;
						}
					}
					&#xphone {
					    width: 42%;
					    img {
						    width: 41.101%;
						    margin: 0 auto;
						}
					}
					&#mediumphone {
					    width: 42%;
					    img {
						    width: 42.2%;
						    margin: 0 auto;
						}
					}
					&#smallphone {
					    width: 38%;
					    img {
						    width: 40.7%;
						    margin: 0 auto;
						}
					}
				}
			}
		}		
	}
}

article#a877 .article-content {
	ul {
		list-style: none;
		padding-left: 0;
		display: table;
		width: 100%;
		margin-top: 1em;
		li {
			display: block;
// 			clear: left;
			font-size: .8em !important;
			padding: 10px 10px 10px !important;
			width: 98%;
			background: url('/images/news/877/paper.png') center center / 100% 100% no-repeat transparent;
			box-sizing: border-box;
			margin: 0 auto 0;
			&::before {
				content: none !important;
			}
			&::after {
				content: ' ';
				display: block;
				clear: both;
			}
			p {
				width: 66%;
				padding: .7em 1em;
				box-sizing: border-box;
				color: black;
				margin: 1em 0 .6em 34%;
				border-radius: 3px;
				min-height: 137px;
				font-size: 1.3em;
				line-height: 1.3em;
				strong {
					display: block;
					text-transform: uppercase;
					font-size: 1.2em;
					padding-bottom: .4em;
					margin-bottom: .6em;
					border-bottom: 1px solid #910000;
				}
			}
			
			
			.trpios-dictators-desk-icon {
				float: left;
				display: inline-block;
				max-width: 200px;
				width: 30%;
// 				height: 200px;
				margin: 0 0 0 10px;
				padding-bottom: 20px;
			}
			
			&.intel {
				margin-left: 0;
				transform: rotate(-1deg);
				img {
					margin-top: 5%;
// 					margin-bottom: -5%; 
				}
			}
			
			&.avatar {
				margin-right: 0;
				transform: rotate(.5deg);
				img {
					margin-top: 2%;
// 					margin-bottom: -3%; 
				}
				p {
					margin-top: .5em;
				}
			}
			
			&.edicts {
				margin-left: 0;
				transform: rotate(1deg);
				min-height: 220px;
				img {
					margin-top: 2%;
// 					margin-bottom: -1%; 
				}
			}
			
			&.overlays {
				margin-right: 0;
				transform: rotate(1.5deg);
				min-height: 220px;
				img {
					margin-top: 1%;

				}
			}
			
			&.construction {
				margin-left: 3%;
				transform: rotate(-.2deg);
				min-height: 200px;
				img {
					margin-top: 1.3%;
// 					margin-bottom: -1%; 					
				}
			}
			
			&.almanac {
				margin-right: 0;
				transform: rotate(-1deg);
				img {
					margin-top: 4%;
// 					margin-bottom: -5%; 
				}
				p {
					margin-top: .5em;
				}
			}
			

		}
		+ p {
			clear: left;
			text-align: center;
		}
		
		#screenshot {
			.trpios-dictators-desk-screenshot {
				margin-top: 1em;
				margin-bottom: 1em;
			}
			+ p {
				clear: left;
				margin: 1em auto;
			}
		}
	}
}

article#a916 {
	.img-comp-container {
	  position: relative;
	  height: 419px; /*should be the same height as the images*/
	  margin-bottom: 2em;
	}
	
	.img-comp-img {
	  position: absolute;
	  width: auto;
	  height: auto;
	  overflow: hidden;
	  
	  p {
		  position: absolute;
		  top: 45%;
		  background-color: rgba(0, 0, 0, 0.78);
		  font-weight: bold;
		  color: white;
		  right: 0;
		  left: auto;
		  width: auto;
		  padding: .6em 1em;
		  display: inline-block;
		  text-transform: uppercase;
		  border-radius: 100px 0px 0px 100px;
	  }
	  
	  &.img-comp-overlay {
		  border-right: 2px solid white;
		  
		  p {
			  left: 0;
			  right: auto;
			  border-radius: 0px 100px 100px 0px;
		  }
	  }
	}
	
	.img-comp-img img {
	  display: block;
	  vertical-align: middle;
	  border-radius: 7px;
	}
	
	.img-comp-slider {
	  position: absolute;
	  z-index: 9;
	  cursor: ew-resize;
	  /*set the appearance of the slider:*/
	  width: 40px;
	  height: 40px;
	  background-color: #2196F3;
	  opacity: 0.7;
	  border-radius: 50%;
	}
	ul + p + a {
		display: block;
		text-align: center;
		 img {
			width: 220px;
			display: inline-block;
			margin: 1em auto 2em;
		 }
	}
}

article#a931 {
	
		
	#ipads {	
		
		background: url('/images/news/971/brown-paper-background.jpg') center center no-repeat white ;
		background-size: cover;
		font-size: 1.25em;
		border-radius: 2px;
		transform: rotate(.5deg);
		z-index: 2;
		padding: 2% 5% 6%;
		margin: 2em auto;
		width: 100%;
		max-width: 620px;
		box-shadow: 0 1px 1px rgba(0,0,0,0.5), /*bottom external highlight*/
					0 -1px 1px rgba(0,0,0,0.5), /*top external shadow*/ 
					inset 0 -1px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/ 
					inset 0 1px 2px rgba(255,255,255,0.8); /*top internal highlight*/
		&::before, &::after {
			content: ' ';
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: url('/images/news/971/brown-paper-background.jpg') center center no-repeat white ;
			background-size: cover;
			border-radius: 2px;
			box-shadow: 0 1px 1px rgba(0,0,0,0.5), /*bottom external highlight*/
						0 -1px 1px rgba(0,0,0,0.5), /*top external shadow*/ 
						inset 0 -1px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/ 
						inset 0 1px 2px rgba(255,255,255,0.8); /*top internal highlight*/
			transform: rotate(1deg);
			z-index: -1;
		}
		&::after {
			transform: rotate(-1deg);
		}
		
		
		img {
			opacity: .9;
		}
		
		h4, h2 {
			clear: both;
			width: auto;
			text-align: center;
			transform: rotate(-1deg);
			text-shadow: none;
			background-image: url('/images/news/971/divider-plain.png'), url('/images/news/971/divider.png');
			background-position: center bottom, center top;
			background-repeat: no-repeat;
			background-color: transparent;
			background-size: 100% auto;
			padding: 1.2em 0 1.1em;
			margin: 1em .5em 0 0;
			display: block;
			mix-blend-mode: multiply;
			color: #323853;
			font-size: 1.5em;
			text-transform: uppercase;
		font-family: 'Oswald', sans-serif;
			
			span {
				text-transform: lowercase;
			}
		}
		
		.devices {
			
			transform: rotate(-1deg);
		}
		
		ul {
			padding: 0;
			float: left;
			margin: 0 2% 0 2%;
			display: block;
			list-style: none;
			width: 29%;
/*
			-webkit-filter: invert(.9);
				    filter: invert(.9);
			mix-blend-mode: multiply;
			opacity: .7;
*/
			&#largepadnew {
				
				margin-left: 18.5%;
				margin-bottom: 5%;
				
				img {
					
				}
			}
			&#largepad {
				clear: left;
			}
/*				&#mediumpad  {
				width: 28%;
				img {
					width: 79.271%;
					margin: 0 auto;
				}

				
			}
			&#smallpad  {
				width: 26%;
				img {
					width: 82.584%;
					margin: 0 auto;
				}

				
			}
*/
			li {
				padding: 0;
			}
		}
	}
	
	
	
	.ipadname, .iphonename {
		text-align: center;
		list-style: none;
		margin: .3em 0 0;
		box-sizing: border-box;	
		line-height: 1.2em;
// 			background: #fff;
		font-size: 1.2rem;
		color: #575757;
		&::before {
			content: none !important;
		}
/*
		border: 1px solid rgba($black, 0.15);
		border-radius: 2px;
*/
		font-family: 'Oswald', sans-serif;
		font-weight: 300;
		
		span {
			display: block;
		box-sizing: border-box;	
		padding: .8em .1em;
		background: url('/images/news/971/ticker.png') center center no-repeat transparent ;
		background-size: 100% 100%;
			+ span {
				margin-top: -.8em;
				padding-top: 0;
				background: url('/images/news/971/ticker-bottom.png') center center no-repeat transparent ;
				background-size: 100% 100%;
				font-size: .9em;
			}
		}
	}
	
	
}

article#a942 {
	li strong {
		display: block;
	}
}

article#a971 {
	#iphones {	
		
		background: url('/images/news/971/brown-paper-background.jpg') center center no-repeat white ;
		background-size: cover;
		font-size: 1.25em;
		border-radius: 2px;
		transform: rotate(.5deg);
		z-index: 2;
		padding: 2% 5% 6%;
		margin: 2em auto;
		width: 100%;
		max-width: 620px;
		box-shadow: 0 1px 1px rgba(0,0,0,0.5), /*bottom external highlight*/
					0 -1px 1px rgba(0,0,0,0.5), /*top external shadow*/ 
					inset 0 -1px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/ 
					inset 0 1px 2px rgba(255,255,255,0.8); /*top internal highlight*/
		&::before, &::after {
			content: ' ';
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: url('/images/news/971/brown-paper-background.jpg') center center no-repeat white ;
			background-size: cover;
			border-radius: 2px;
			box-shadow: 0 1px 1px rgba(0,0,0,0.5), /*bottom external highlight*/
						0 -1px 1px rgba(0,0,0,0.5), /*top external shadow*/ 
						inset 0 -1px 2px rgba(0,0,0,0.5), /*bottom internal shadow*/ 
						inset 0 1px 2px rgba(255,255,255,0.8); /*top internal highlight*/
			transform: rotate(1deg);
			z-index: -1;
		}
		&::after {
			transform: rotate(-1deg);
		}
		
		
		img {
			opacity: .9;
		}
		
		h4, h2 {
			clear: both;
			width: auto;
			text-align: center;
			transform: rotate(-1deg);
			text-shadow: none;
			background-image: url('/images/news/971/divider-plain.png'), url('/images/news/971/divider.png');
			background-position: center bottom, center top;
			background-repeat: no-repeat;
			background-color: transparent;
			background-size: 100% auto;
			padding: 1.2em 0 1.1em;
			margin: 1em .5em 0 0;
			display: block;
			mix-blend-mode: multiply;
			color: #323853;
			font-size: 1.5em;
			text-transform: uppercase;
		font-family: 'Oswald', sans-serif;
			
			span {
				text-transform: lowercase;
			}
		}
		
		.devices {
			
			transform: rotate(-1deg);
		}
		
		ul {
			padding: 0;
			float: left;
			margin: 0 5%;
			display: block;
			list-style: none;
			width: 23%;
			
			li {
				padding: 0;
			}
		}
	}
	
	
	
	.ipadname, .iphonename {
		text-align: center;
		list-style: none;
		margin: .3em 0 0;
		box-sizing: border-box;	
		line-height: 1.2em;

		font-size: 1.2rem;
		color: #575757;
		font-family: 'Oswald', sans-serif;
		font-weight: 300;
		&::before {
			content: none !important;
		}
		span {
			display: block;
		box-sizing: border-box;	
		padding: .8em .1em;
		background: url('/images/news/971/ticker.png') center center no-repeat transparent ;
		background-size: 100% 100%;
			+ span {
				margin-top: -.8em;
				padding-top: 0;
				background: url('/images/news/971/ticker-bottom.png') center center no-repeat transparent ;
				background-size: 100% 100%;
				font-size: .9em;
			}
		}
	}

}

article#a999 .article-content {
	> ul {
		padding: 0;
		> li {
			&::before {
				content: none;
			}
		}
	}
	li {
		list-style: none;
		h2 {
			font-family: "'Michroma'", sans-serif;
			color: #c00;
			margin: 1.5em 0 .5em;
			font-size: 1.2em;
		}
		img {
			float: right;
			width: 33%;
			margin-left: 20px;
			margin-bottom: 20px;
			clear: right;
			border-radius: 4px;
		}
	}
}

article#a1036 {
	#ios-devices {
		padding: 0 0 2em;
		margin: 1.5em auto 2em;
		text-align: center;
		font-size: 1.4em;
		border-bottom: 5px solid #61634b;
		border-top: 5px solid #61634b; 
		background: url('/minisite/companyofheroes-mobile/images/background@2x.jpg') center center / 100% 100% transparent no-repeat;
		background-size: cover;
		font-family: 'Gotham',sans-serif;
		[lang=ru] & {
			font-family: 'Roboto', sans-serif;
		}
		
		+ p {
			width: auto;
			text-align: center;
			margin-bottom: 1.5em;
			+ a {
				display: block;
				text-align: center;
				padding-bottom: 3em;
				img {
					height: 60px; 
				}
				
			}
		}
		
		h4, h2 {
			clear: both;
			padding: 0;
			margin: 1em auto 1em;
			border-bottom: none;
			text-transform: uppercase;
			font-weight: bold;
			width: 100%;
			font-weight: 400;
			color: #1c1b1a;
			font-size: 1em;
			span {
				text-transform: lowercase;
			}
		}
		ul {
			margin: 0;
			padding: 0;
			li {
				
				&::before {
					content: none !important;
				}
				span {
					display: block;
					font-size: .67em;
					line-height: 1.2em;
					margin: .5em -.5em 0;
				}
			}
		}
		#ipads {	
			padding: 0 6% 0 6%;	
			box-sizing: border-box;	
			ul {
				float: left;
				margin: 0 2% 0 2%;
				display: block;
				list-style: none;
				&#largepad {
					width: 25%;
					img {
						width: 87.481%;
						margin: 0 auto;
					}
				}
				&#mediumpad {
					width: 21.9%;
					img {
						width: 79.271%;
						margin: 0 auto;
					}	
				}
				&#smallpad {
					width: 20.3%;
					img {
						width: 82.584%;
						margin: 0 auto;
					}	
				}
				&#minipad  {
					width: 16.5%;
					img {
						width: 82.584%;
						margin: 0 auto;
					}	
				}
			}
			&new {
				padding: 0 6% 2%;	
				box-sizing: border-box;	
				ul {
					float: left;
					margin: 0 2% 0 2%;
					display: block;
					list-style: none;
					&#largepadnew {
						width: 25%;
						margin-left: 24%;
						img {
							width: 87.481%;
							margin: 0 auto;
						}
					}
					&#mediumpadnew  {
						width: 23%;
						img {
							width: 79.271%;
							margin: 0 auto;
						}
					}
				}
			}
		}
		.ipadname, .iphonename, #android-requirements > div {
			text-align: center;
			list-style: none;
			margin: .3em 0 0;
			padding: .5em;
			box-sizing: border-box;	
			line-height: 1.2em;
			font-size: 1rem;
			border: 1px solid rgba(#61634b, 1.09);
			border-radius: 2px;
			background: rgba(#61634b, 0.9);
			color: white;
		}
	}
}

article#a1074 {
	
	padding-bottom: 100px;
	section {
		-webkit-font-smoothing: subpixel-antialiased;
		clear: both;
		padding: 0 0 30px;
		text-align: center;
		border-bottom: 5px solid #61634b;
		border-left: none;
		border-right: none;
		margin: 0 auto;
		background: url('/minisite/companyofheroes-mobile/images/background@2x.jpg') center center / 100% 100% white no-repeat;
		background-size: cover;
		font-family: 'Gotham',sans-serif;
		[lang=ru] & {
			font-family: 'Roboto', sans-serif;
		}
		text-shadow: 0 0 4px #fff,0 0 3px #fff,0 0 1px #fff;
		
		
		&:first-of-type {
			margin-bottom: 1em;
		}
		
		&:last-of-type {
			padding: 0 4% 0px;
			margin-bottom: 2em;
			h4 {
				margin: 0 -1.2% 1em;
			}
			
			> h4 {
				margin: 0 -4.4% 1em;
				border-width: 5px;
			}
			
/*
			+ p, + p + p, + p + p + p {
				width: 100%;
				text-align: center;
				padding: 0;
			}
*/
		}
		
		h4 {
			clear: both;
			padding-top: 1em;
			border-top: 3px solid #61634b;
			border-bottom: none;
			margin: 0 .5em 1em;
							font-size: 1.2em;
			font-family: 'Gotham',sans-serif;
		[lang=ru] & {
			font-family: 'Roboto', sans-serif;
		}
			letter-spacing: .15em;
			text-transform: uppercase;
			font-weight: 400;
			color: #1c1b1a;
			
			span {
				text-transform: lowercase;
			}
		}
		
		> h4 {
			font-weight: bold;
margin: 0 0 1em;
		}
		
		p {
			width: auto;
			padding: 0 5% .5em;
font-family: 'Gotham',sans-serif;
		[lang=ru] & {
			font-family: 'Roboto', sans-serif;
		}
color: black;
		}
		
		ul li {
			span {display: block;}
			&::before {
				content: none !important;
			}
		}
		#iphones {
		padding: 0 1%;
		box-sizing: border-box;
/*
		.iphonesplit {
			display: block;
			width: 100%;
			padding: 0;
			margin: 0;
			clear: both;
			&:first-child {
				ul {
					padding-bottom: 0;
				}
			}
			&:nth-child(2) {
				position: relative;
				top: -.5em;
			}
*/
			.devices {
				padding: 0 0 0 1%;
			}
			ul {
				padding: 0;
				float: left;
				margin: 0 2%;
				padding-bottom: 4%;
				&#iphonemax {
					width: 30%;
// margin-left: 18%; // REMOVE WHEN XR IS SUPPORTED
					img {
						width: 41.101%;
						margin: 0 auto;
					}
				}
				&#iphoner {
					width: 28.8%;
					img {
						width: 41.101%;
						margin: 0 auto;
					}
				}
				&#iphonex {
					width: 27.5%;
					padding-bottom: 2%;
					img {
						width: 41.101%;
						margin: 0 auto;
					}
				}
				&#iphoneplus {
					width: 30%;
					img {
						width: 45.5%;
						margin: 0 auto;
					}
				}
				&#iphone {
					width: 28.8%;
					img {
						width: 39.5%;
						margin: 0 auto;
					}
				}
				&#iphonese {
					width: 27.5%;
					img {
						width: 36%;
						margin: 0 auto;
					}
				}
				
				li {
				}
			}
// 			}
	}
	
	.ipadname, .iphonename, #android-requirements > div {
		text-align: center;
		list-style: none;
		margin: .3em 0 0;
		padding: .5em;
		box-sizing: border-box;	
		line-height: 1.2em;
		font-size: 1rem;
		border: 1px solid rgba(97,99,75,.9);
		border-radius: 2px;
		background: rgba(97,99,75,.9);
		color: #fff;
		text-shadow: none;
	}
	
	img {
		
	}
	
	
	#android-requirements {
		
		> div {
			width: 100%;
			clear: right;
			padding-bottom: .5em;
			display: inline-block;
			margin: 0.5em auto 0em;
			
			line-height: 2.5em;
			&:last-child {
				margin-bottom: 1em;
			}
			h5 {
				border: none;
				background: none;
				box-shadow: none;
				width: auto;
				font-size: 2em;
				padding: 0em .0em .7em;
				font-weight: bold;
				color: #fff;
				margin: .7em 0em .7em;
				border-bottom: 1px solid #fff;
			}
			ul {
				width: 100%;
				box-sizing: border-box;
				margin: 0;
				float: none;
				padding: 0; 	
				li.android-name {
					width: 100%;
					display: block;
					box-sizing: border-box;
					font-family: 'Gotham',sans-serif;
		[lang=ru] & {
			font-family: 'Roboto', sans-serif;
		}
					font-weight: 300;
					font-size: 1rem;
					margin: 0 0 .7em;
					color: white;
				}
			}
			
				
				&:last-child {
					background: transparent;
					border: none;
				}
		}
		
		.clearfix {
			float: none;
			clear: both;
			width: 100%;
			
			p {
				padding: 0 0;
				font-size: .8em;
				line-height: 1.4em;
				
			}
		}
		
	}
/*
		
		.ipadname, .iphonename {
			font-size: 1.4em !important;
		}
*/
		ul {
			li {
				span {
					
					font-size: .75em !important;
				}
			}
		}
		
		#android-requirements {
			div {
				width: 49.5%;
				padding: .5em .5em;
				ul {
					width: 100%;
					box-sizing: border-box;
// 						margin: 0 1%;
					float: none;
					padding-bottom: .3em;
					li.android-name {
						width: 100%;
						display: block;
						box-sizing: border-box;
						font-family: 'Gotham',sans-serif;
		[lang=ru] & {
			font-family: 'Roboto', sans-serif;
		}
						font-weight: 300;
						font-size: 1rem;
						margin: 0 0 .4em;
						line-height: 1.2em;
						
					}
				}
				
				&#samsung {
					float: left;
					margin-top: -.2em;
				}
				
				&#google {
					float: right;
// 						padding-bottom: .75em;
					margin-top: -.2em;
				}
				
				&#huawei {
					float: right;
					clear: right;
// 						padding-bottom: .75em;
				}
				
				&#oneplus {
					float: left;
					clear: left;
				}
				
				&#xiaomi {
					float: right;
					clear: right;
				}
				
				&#other {
					float: right;
					clear: right;
					margin-bottom: 1em;
				}
				
				h5 {
					text-transform: uppercase;
					font-size: 1.5em;
				}
/*
				&:last-child {
					background: transparent;
					border: none;
				}
*/
			}
		}

		
		
		
	}
	
}

article#a1060 {
	.device-type {
		margin: 2.5em auto;
		border: 2px solid #a80e03;
		outline: 4px solid black;
		padding-bottom: 2em;
		background: url('/minisite/xcom2collection-mobile/images/background@2x.jpg') center top no-repeat transparent;
		background-size: auto 200%;
		text-align: center;
		+ p {
			width: 100%; padding: 0;
			text-align: center;
			font-weight: bold;
		}
		h2 {
			clear: both;
			font-size: 2.7em;
			width: 90%;
			margin: .5em auto 1em;
			color: #f03427;
			border-bottom: 1px solid #f03427;
			font-family: 'Dharma Gothic E', 'PT Sans Narrow', helvetica, sans-serif;
			text-transform: uppercase;
			span {
				text-transform: lowercase;
			}
		}
		p {
			margin-bottom: 2em;
			padding-left: 10%;
			padding-right: 10%;
		}
		ul {
			margin: 0;
			padding: 0;
			li {
				color: white;
				&::before {
					content: none;
				}
				span {
					display: block;
					font-size: .65em;
					line-height: 1.2em;
					margin-top: .5em;
					color: white;
				}
			}
		}
		#ipads {	
			padding: 0 6% 0 6%;	
			box-sizing: border-box;	
			ul {
				float: left;
				margin: 0 2% 0 2%;
				display: block;
				list-style: none;
				&#largepad {
					width: 25%;
					margin-left: 14.4%;
					img {
						width: 87.481%;
						margin: 0 auto;
					}
				}
				&#mediumpad  {
					width: 21.9%;
					img {
						width: 79.271%;
						margin: 0 auto;
					}
				}
				&#smallpad  {
					width: 20.3%;
					img {
						width: 82.584%;
						margin: 0 auto;
					}
				}
				&#minipad  {
					width: 16.5%;
					img {
						width: 82.584%;
						margin: 0 auto;
					}
				}
			}
			
			&new {
				padding: 0 6% 2%;	
				box-sizing: border-box;	
				ul {
					float: left;
					margin: 0 2% 0 2%;
					display: block;
					list-style: none;
					&#largepadnew {
						width: 25%;
						margin-left: 24%;
						padding-bottom: 1em;
						img {
							width: 87.481%;
							margin: 0 auto;
						}
					}
					&#mediumpadnew  {
						width: 23%;
						img {
							width: 79.271%;
							margin: 0 auto;
						}
					}
				}
			}
		}
		#iphones {
			padding: 0 8%;
			box-sizing: border-box;
			.iphonesplit {
				display: block;
				width: 100%;
				padding: 0;
				margin: 0;
				clear: both;
				&:first-child {
					ul {
						padding-bottom: 0;
					}
				}
				&:nth-child(2) {
					position: relative;
				}
				ul {
					float: left;
					margin: 0 2%;
					&#xsphonemax {
						width: 30%;
						img {
							width: 41.101%;
							margin: 0 auto;
						}
					}
					&#xrphone {
						width: 28.8%;
						img {
							width: 41.101%;
							margin: 0 auto;
						}
					}
					&#xphone {
						width: 27.5%;
						padding-bottom: 1em;
						img {
							width: 41.101%;
							margin: 0 auto;
						}
					}
					&#largephone {
						width: 30%;
						margin-left: 18.7%;
						img {
							width: 45.5%;
							margin: 0 auto;
						}
					}
					&#mediumphone {
						width: 28.8%;
						img {
							width: 39.5%;
							margin: 0 auto;
						}
					}
					&#smallphone {
						width: 27.5%;
						img {
							width: 36%;
							margin: 0 auto;
						}
					}
				}
			}
		}
		.ipadname, .iphonename {
			text-align: center;
			list-style: none;
			margin: .3em 0 0;
			border-image-source: url('/minisite/xcom2collection-mobile/images/borders/border-hover.svg');
			border-image-slice: 45%;
			border-image-slice: 45% fill;
			border-image-width: 8px;
			border-image-repeat: stretch;
			border-image-outset: 0;
			border-width: 1px;
			border-style: solid;
			padding: .5em .1em;
			box-sizing: border-box;	
			line-height: 1.2em;
			font-size: 1rem;
			color: white;
		}
	}
}