/*
@keyframes animationFrames {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-moz-keyframes animationFrames {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-webkit-keyframes animationFrames {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-o-keyframes animationFrames {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-ms-keyframes animationFrames {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
*/



@mixin bounce-in-leftSmall($duration: 3s, $delay: 0) {
  opacity: 0;
  margin-left: -50px;
  padding-left: 62px;
  box-sizing: content-box;
  animation: bounceInLeftSmall ease $duration;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards; /*when the spec is finished*/
  animation-delay: $delay;
  -webkit-animation: bounceInLeftSmall ease $duration;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
  -webkit-animation-delay: $delay;
  -moz-animation: bounceInLeftSmall ease $duration;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode:forwards; /*FF 5+*/
  -moz-animation-delay: $delay;
  -o-animation: bounceInLeftSmall ease $duration;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode:forwards; /*Not implemented yet*/
  -o-animation-delay: $delay;
  -ms-animation: bounceInLeftSmall ease $duration;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode:forwards; /*IE 10+*/
  -ms-animation-delay: $delay;
}

@keyframes bounceInLeftSmall{
  0% {
    opacity:0;
    transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    transform:  translate(20px,0px)  ;
  }
  80% {
    transform:  translate(-5px,0px)  ;
  }
  100% {
    opacity:1;
    transform:  translate(0px,0px)  ;
  }
}

@-moz-keyframes bounceInLeftSmall{
  0% {
    opacity:0;
    -moz-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -moz-transform:  translate(20px,0px)  ;
  }
  80% {
    -moz-transform:  translate(-5px,0px)  ;
  }
  100% {
    opacity:1;
    -moz-transform:  translate(0px,0px)  ;
  }
}

@-webkit-keyframes bounceInLeftSmall {
  0% {
    opacity:0;
    -webkit-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -webkit-transform:  translate(20px,0px)  ;
  }
  80% {
    -webkit-transform:  translate(-5px,0px)  ;
  }
  100% {
    opacity:1;
    -webkit-transform:  translate(0px,0px)  ;
  }
}

@-o-keyframes bounceInLeftSmall {
  0% {
    opacity:0;
    -o-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -o-transform:  translate(20px,0px)  ;
  }
  80% {
    -o-transform:  translate(-5px,0px)  ;
  }
  100% {
    opacity:1;
    -o-transform:  translate(0px,0px)  ;
  }
}

@-ms-keyframes bounceInLeftSmall {
  0% {
    opacity:0;
    -ms-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -ms-transform:  translate(20px,0px)  ;
  }
  80% {
    -ms-transform:  translate(-5px,0px)  ;
  }
  100% {
    opacity:1;
    -ms-transform:  translate(0px,0px)  ;
  }
}



@mixin bounce-in-left($duration: 3s, $delay: 0) {
  opacity: 0;
  margin-left: -100px;
  padding-left: 130px;
  box-sizing: border-box;
  animation: bounceInLeft ease $duration;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards; /*when the spec is finished*/
  animation-delay: $delay;
  -webkit-animation: bounceInLeft ease $duration;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
  -webkit-animation-delay: $delay;
  -moz-animation: bounceInLeft ease $duration;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode:forwards; /*FF 5+*/
  -moz-animation-delay: $delay;
  -o-animation: bounceInLeft ease $duration;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode:forwards; /*Not implemented yet*/
  -o-animation-delay: $delay;
  -ms-animation: bounceInLeft ease $duration;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode:forwards; /*IE 10+*/
  -ms-animation-delay: $delay;
}

@keyframes bounceInLeft{
  0% {
    opacity:0;
    transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    transform:  translate(30px,0px)  ;
  }
  80% {
    transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    transform:  translate(0px,0px)  ;
  }
}

@-moz-keyframes bounceInLeft{
  0% {
    opacity:0;
    -moz-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -moz-transform:  translate(30px,0px)  ;
  }
  80% {
    -moz-transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    -moz-transform:  translate(0px,0px)  ;
  }
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity:0;
    -webkit-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -webkit-transform:  translate(30px,0px)  ;
  }
  80% {
    -webkit-transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    -webkit-transform:  translate(0px,0px)  ;
  }
}

@-o-keyframes bounceInLeft {
  0% {
    opacity:0;
    -o-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -o-transform:  translate(30px,0px)  ;
  }
  80% {
    -o-transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    -o-transform:  translate(0px,0px)  ;
  }
}

@-ms-keyframes bounceInLeft {
  0% {
    opacity:0;
    -ms-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -ms-transform:  translate(30px,0px)  ;
  }
  80% {
    -ms-transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    -ms-transform:  translate(0px,0px)  ;
  }
}







@mixin bounce-in-right($duration: 3s, $delay: 0) {
  opacity: 0;
  margin-right: -100px;
  padding-right: 130px;
  animation: bounceInRight ease $duration;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards; /*when the spec is finished*/
  animation-delay: $delay;
  -webkit-animation: bounceInRight ease $duration;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
  -webkit-animation-delay: $delay;
  -moz-animation: bounceInRight ease $duration;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode:forwards; /*FF 5+*/
  -moz-animation-delay: $delay;
  -o-animation: bounceInRight ease $duration;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode:forwards; /*Not implemented yet*/
  -o-animation-delay: $delay;
  -ms-animation: bounceInRight ease $duration;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode:forwards; /*IE 10+*/
  -ms-animation-delay: $delay;
}

@keyframes bounceInRight{
  0% {
    opacity:0;
    transform:  translate(1500px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  60% {
    opacity:1;
    transform:  translate(-30px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  80% {
    transform:  translate(10px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  100% {
    opacity:1;
    transform:  translate(0px,0px)  ;
	margin-right: 0px;
	padding-right: 30px;
  }
}

@-moz-keyframes bounceInRight{
  0% {
    opacity:0;
    -moz-transform:  translate(1500px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  60% {
    opacity:1;
    -moz-transform:  translate(-30px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  80% {
    -moz-transform:  translate(10px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  100% {
    opacity:1;
    -moz-transform:  translate(0px,0px)  ;
	margin-right: 0px;
	padding-right: 30px;
  }
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity:0;
    -webkit-transform:  translate(1500px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  60% {
    opacity:1;
    -webkit-transform:  translate(-30px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  80% {
    -webkit-transform:  translate(10px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  100% {
    opacity:1;
    -webkit-transform:  translate(0px,0px)  ;
	margin-right: 0px;
	padding-right: 30px;
  }
}

@-o-keyframes bounceInRight {
  0% {
    opacity:0;
    -o-transform:  translate(1500px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  60% {
    opacity:1;
    -o-transform:  translate(-30px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  80% {
    -o-transform:  translate(10px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  100% {
    opacity:1;
    -o-transform:  translate(0px,0px)  ;
	margin-right: 0px;
	padding-right: 30px;
  }
}

@-ms-keyframes bounceInRight {
  0% {
    opacity:0;
    -ms-transform:  translate(1500px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  60% {
    opacity:1;
    -ms-transform:  translate(-30px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  80% {
    -ms-transform:  translate(10px,0px)  ;
	margin-right: -100px;
	padding-right: 130px;
  }
  100% {
    opacity:1;
    -ms-transform:  translate(0px,0px)  ;
	margin-right: 0px;
	padding-right: 30px;
  }
}




@mixin fade-in-up($duration: 3s, $delay: 0){
  opacity:0;
  animation: fadeInUp ease $duration;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards; /*when the spec is finished*/
  animation-delay: $delay;
  -webkit-animation: fadeInUp ease $duration;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
  -webkit-animation-delay: $delay;
  -moz-animation: fadeInUp ease $duration;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode:forwards; /*FF 5+*/
  -moz-animation-delay: $delay;
  -o-animation: fadeInUp ease $duration;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode:forwards; /*Not implemented yet*/
  -o-animation-delay: $delay;
  -ms-animation: fadeInUp ease $duration;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode:forwards; /*IE 10+*/
  -ms-animation-delay: $delay;
}

@keyframes fadeInUp{
  0% {
    opacity:0;
    transform:  translate(0px,55px)  ;
  }
  100% {
    opacity:1;
    transform:  translate(0px,0px)  ;
  }
}

@-moz-keyframes fadeInUp{
  0% {
    opacity:0;
    -moz-transform:  translate(0px,55px)  ;
  }
  100% {
    opacity:1;
    -moz-transform:  translate(0px,0px)  ;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity:0;
    -webkit-transform:  translate(0px,55px)  ;
  }
  100% {
    opacity:1;
    -webkit-transform:  translate(0px,0px)  ;
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity:0;
    -o-transform:  translate(0px,55px)  ;
  }
  100% {
    opacity:1;
    -o-transform:  translate(0px,0px)  ;
  }
}

@-ms-keyframes fadeInUp {
  0% {
    opacity:0;
    -ms-transform:  translate(0px,55px)  ;
  }
  100% {
    opacity:1;
    -ms-transform:  translate(0px,0px)  ;
  }
}