@include media-breakpoint-up(md) {
	#primary-nav-pages ul li.careers a {
		color: #F15A24;
		pointer-events: none;
	}
}

#careers-content, #privacy-content, #about-content {
	> .row {
	// 	@include fade-in-up(2s, 2s);
		margin: 0 auto;
		max-width: 1200px;
	}
}
	

#careers-content {
	color: #000;
	padding-bottom: 0;
	&:before {
		background-image: none;
		background-color: rgba(255,255,255,.85);
		height: 100%;
	} 
	
	.about-content {
		display: flex;
		flex-direction: column;
	}
	
	nav {
		position: relative;
		display: inline-block;
		margin: 20px 0px; padding: 5px 7px;
		padding-right: 25px;
		background-image: url('/images/backgrounds/triangles/navigation.svg');
		background-position: center right;
		background-size: cover;
		background-repeat: no-repeat;
		color: #FFF;
		
		> span {
			font-size: .65em;
			top: -.15em;
			position: relative;
		} 
		
		a {
			color: #FFF;
			margin: 0px 5px;
			white-space: nowrap;
			
			&.active {
				color: $feral-orange;
			}
		}
		
		
	}
	.about-text {
		position: relative;
		margin: 0 1rem;		
		display: flex;
		flex-wrap: wrap;
		h2 {
			margin: 1.5rem 0 1rem; 
		}
		
		p {
			margin: 0px .7rem 1rem;
		}
		
		ul {
			margin-left: .0em;
			list-style: none;
			
			li {
				position: relative;
				&::before {
					@include fa-icon("\f0da");
					position: absolute;
					left: -1em;
					top: .05em;
				}
			}
		}
	}
	h2 {
		margin: 10px 15px;
	}
	
	h4, h5 {
		margin: 1.5em 0 1em calc(30px - 1em);
	}
	
	p {
		margin: 0px 10px 10px 25px;
	}
	
	aside {
		position: relative;
		margin: 1em auto 1em;
		width: 90%;
		> div {
			background: url('/images/backgrounds/triangles/careers-aside.svg') left top / 768px auto no-repeat transparent;
			
			border-radius: 28px 4px 4px 4px;
// 			-webkit-clip-path: polygon(19px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 19px);
// 			clip-path: polygon(19px 0px, 100% 0px, 100% 100%, 0px 100%, 0px 19px);
			&::after { 
				content: ' ';
				background-color: rgba(0, 0, 0, 0.0);
				position: absolute;
				top: 0;
				bottom: 0;
				left: 100%;
				width: 50vw;
			}
		}
		
		h5 {
			margin: 0px; padding: 1em 15px;
			color: $feral-orange;
		}
		
		ul {
			margin: 0px; padding: 0px 0px 15px;
			list-style: none;
		}
		
		li {
			
			background-color: #333;
			color: #FFF;
			border-top: solid 3px #999;
			display: flex;
			
			
			a {
				color: white;
				padding: 9px 15px 11px;
				display: block;
				width: 100%;
				&::before {
					@include fa-icon("\f0da");
					color: $feral-orange;
					margin-right: 15px;
					font-size: 1.5rem;
					top: 3px;
					left: 0;
					position: relative;
// 					transform: translateX(0);
					transition: transform .2s linear, left 2s linear;
				}
				
				&:hover {
					&::before {
						transform: translateX(5px);
					}
				}
			}
			
			* {
				align-self: center;
			}
			
			&:last-child {
				border-bottom: solid 3px #999;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	#careers-content {
	   
		nav {
			&::after { 
				content: ' ';
				background-color: rgba(0, 0, 0, 0.8);
				position: absolute;
				top: 0;
				bottom: 0;
				right: 100%;
				width: 50vw;
			}
			
			
		}
		.about-content {
			flex-direction: row;
			
			aside {
				margin-right: 0px;
				margin-left: 3vw;
				min-width: 300px;
				max-width: 300px;
				@include media-breakpoint-up(xl) {
					margin-left: 36px;
					
				}
// 				flex-grow: 1;
// 				display: flex;
				
/*
				> img {
					margin-right: 2vw;
					margin-bottom: 30px;
				}
*/
			}
		}
		.about-text {
			flex-grow: 3;
			margin: 0rem 1rem 4rem;
// 				display: flex;
			font-size: 1.8vw;
			h2 {
				font-size: 3vw;
				margin: 1em auto .7em;
			}
			
			p {
				font-size: 1em;
				margin: 0px .7em 1em;
			}
			
			ul {
				margin-left: .6em;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	#careers-content {
		.row {
			
			aside {
// 				margin-left: 7vw;
			}
			
			.about-text {
				font-size: 1.8vw;
				text-shadow: $medium-whte-text-shadow;
				h2 {
					font-size: 3vw;
					margin: 1em auto .7em;
				}
				
				h3 {
					font-size: 2.5vw;
				}
				
				h4 {
					font-size: 2.2vw;
				}
				
				h5 {
					font-size: 1.9vw;
				}
				
				.alert {
					padding: 1.4vw 1.9vw;
				}
				
				p {
					font-size: 1em;
					margin: 0px .7em 1em;
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
				
				li {
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
			}
		}
		
	}
	
}

@include media-breakpoint-up(xl) {
	#careers-content {
		.row {
			h3 {
				font-size: 29px;
			}
			aside {
// 				margin-left: 84px;
				> img {
					margin-right: 24px;
				}
			}
			
			.about-text {
				font-size: 22px;
				h2 {
					font-size: 36px;
				}
				h3 {
					font-size: 29px;
				}
				h4 {
					font-size: 26px;
				}
				h5 {
					font-size: 23px;
				}
				
				.alert {
					padding: 17px 23px;
				}
			}
		}
		
	}
}	

/*
.about-text {
	position: relative;
	
	h2 {
		margin: 10px 15px;
	}
	
	p {
		margin: 0px 10px 10px 25px;
	}
}
*/

#careers-filter {
	background-color: #000;
	
	&:before {
		display: none;
	}
	
	div {
// 		width: 100%;
		display: flex;
		padding: 20px 10px;
		text-align: center;
		justify-content: space-around;
		align-content: center;
		align-items: center;
		h3 {
			margin: 0 10px 0 0;
			position: relative;
// 			top: 3px;
			display: flex;
			color: white;
			font-size: 1.3em;
// 			flex-grow: 1;
		}
		
/*
		select {
			position: relative;
			display: inline-block;
			width: calc(100% - 40px);
			max-width: 400px;
			margin: 20px; padding: 3px 5px;
			border: solid 2px #FFF;
			border-radius: 4px;
			background-color: #333;
			color: #FFF;
		}
*/
		
		select {
			border: none;
			border-radius: 3px;
			padding: 5px 20px;
			background-image: url('/images/down-arrow-white.svg')/* , url('/images/line-white.svg') */;
			background-repeat: no-repeat;
				background-position: calc(100% - 17px) center/* , calc(100% - 46px) center */;
			background-size: 12px auto/* , 1px auto */;
			background-color: #414042;
			position: relative;
			-moz-appearance: none;
		    -webkit-appearance: none;
		    appearance: none;
			height: 48px;
// 			width: calc(100% - 40px);
// 			max-width: 400px;
			width: auto;
			font-size: 1.1em;
			font-weight: 500;
			border: 2px solid white;	
			transition: border .2s linear;
			cursor: pointer;
			color: white;
			display: flex;
			flex-grow: 2;
			&:hover {
				border-color: $feral-orange;
				background-image: url('/images/down-arrow-orange.svg')/* , url('/images/orange-line.svg') */;
			}
			
			
		}
		
	}
	
	&:after {
		display: block;
		content: '';
		width: 100%; height: 1px;
	}
}

@include media-breakpoint-up(md) {
	#careers-filter {
		background-color: #000;
		
		div {
			justify-content: center;
			padding: 0px 10px;
			select {
				max-width: 300px;
			}
		}
	}
}



#careers-list {
	&:before {
		display: none;
	}
	
	h1.name {
// 		margin: .5em 0px 0px; 
		text-align: center;
		font-size: 1.5rem;
		padding: 0;
		position: relative;
		margin: .5em auto 0;
		max-width: 1200px;
		span {
			display: inline-block;
			color: white;
			font-size: 60%;
			text-transform: uppercase;
			position: relative;
			margin: 0 auto .01em;
			padding: .7em 2em 0;
			background-color: rgba(0,0,0,.8);
			border-top-left-radius: 14px;
			border-top-right-radius: 14px;
			transition: background .2s linear;
			border-radius: 25% 25% 0px 0px;
	// 		font-size: .9rem;
			-webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
					clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
			
/*
			&:before {
				display: block;
			transition: background .2s linear;
				content: '';
				position: absolute;
				top: 9px; left: -14px;
				bottom: 0px;
			background-color: inherit;
				width: 15px;
				-webkit-clip-path: polygon(0px 100%, 100% 0px, 100% 100%);
				clip-path: polygon(0px 100%, 100% 0px, 100% 100%);
			}
			
			&:after {
				display: block;
			transition: background .2s linear;
				content: '';
				position: absolute;
				top: 9px; right: -14px;
				bottom: 0px;
			background-color: inherit;
				width: 15px;
				-webkit-clip-path: polygon(0px 0px, 100% 100%, 0px 100%);
				clip-path: polygon(0px 0px, 100% 100%, 0px 100%);
			}
*/
		}
		a {
			display: block;
			border-radius: 5px;
			padding: .5em 1em;
			background-color: rgba(0,0,0,.8);
			transition: background .2s linear, border-radius .2s linear;
			
			-webkit-clip-path: polygon(20px 0%, calc(100% - 20px) 0%, 100% 20px, 100% 100%, 0 100%, 0 20px);
					clip-path: polygon(20px 0%, calc(100% - 20px) 0%, 100% 20px, 100% 100%, 0 100%, 0 20px);
		}
	}
	
	> ul {
		list-style: none;
		margin: 10px; padding: 0px 0 70px;
		
		> li.open {
			overflow: visible;
			margin-bottom: 7vw;
			h1.name {
				span, span::before, span::after,
				a {background-color: #000; border-radius: 5px 5px 0px 0px;}
			}
			
			.container-fluid {
				display: block;
				
				&::after {
					content: ' ';
					position: absolute;
					z-index: 2;
					left: -1px;
					top: 100%;
					right: -1px;
					height: 7vw;
					background-image: url('/images/backgrounds/triangles/careers-foot-large.svg');
					background-position: center top;
					background-size: 100% auto;
					background-repeat: no-repeat;
					pointer-events: none;		
				}
			}
			
		}
		
		h2 {
			padding: .4em .5em;
			margin-bottom: 1em;
			font-size: 1.2rem;
			background-color: #000;
			color: $feral-orange;
			border-radius: 3px;
			-webkit-clip-path: polygon(5px 0%, calc(100% - 5px) 0%, 100% 5px, 100% calc(100% - 5px), calc(100% - 5px) 100%, 5px 100%, 0 calc(100% - 5px), 0% 5px);
					clip-path: polygon(5px 0%, calc(100% - 5px) 0%, 100% 5px, 100% calc(100% - 5px), calc(100% - 5px) 100%, 5px 100%, 0 calc(100% - 5px), 0% 5px);
			
			&:not(:first-child) {
				margin-top: 1.5em;
			}
		}
		
		ul {
			margin: 10px;
			padding: 0 0 0 1.5em;
			li {
				position: relative;
				list-style: none;
				&:before {
					position: absolute;
					top: .18em; left: -1.5em;
					content: '☗';
					display: inline-block;
					transform: rotate(90deg);
					color: black;
					font-size: .8em;
				}
			}
		}
		
		p {
			margin: 0px 10px 10px;
		}
	}
	
	
	a.apply {
		display: block;
		margin: auto; 
		padding: .6em;
		background-color: #000;
		width: max-content;
		border-radius: 7px;
		font-size: 1.2rem;
		-webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1.2em), calc(100% - 1.2em) 100%, 1.2em 100%, 0% calc(100% - 1.2em));
				clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1.2em), calc(100% - 1.2em) 100%, 1.2em 100%, 0% calc(100% - 1.2em));
		span {
			display: block;
			padding: .15em 2em .2em;
			background-color: #FFF;
			color: #000;
			border-radius: 3px;
		-webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 0.8em), calc(100% - 0.8em) 100%, 0.8em 100%, 0% calc(100% - 0.8em));
				clip-path: polygon(0 0, 100% 0, 100% calc(100% - 0.8em), calc(100% - 0.8em) 100%, 0.8em 100%, 0% calc(100% - 0.8em));
			transition: background .2s linear;
		}
		
		&:hover {
			text-decoration: none;
			
			span {
				background-color: $feral-orange;
			}
		}
	}
	
	a.close-job {
		text-align: center;
		display: block;
		margin: 15px auto calc(1em - 5vw);
		
		width: max-content;
		color: #000;
		text-transform: uppercase;
	
		position: relative;
		z-index: 3;
		transition: color .2s linear;
		span.icon-close {
			display: block;
			margin: 0 auto .5em;
			font-size: 120%;
			background-color: #000;
			color: #FFF;
			width: 2rem; height: 2rem;
			padding-top: .2em;
			border-radius: 4px;
			transition: background .2s linear;
		}
		
		&:hover {
			color: $feral-orange;
			text-decoration: none;
			span {
				background-color: $feral-orange;
			}
		}
	}
	
	.container-fluid {
		display: none;
		padding: 2em 15px 0;
		background-color: rgba(255,255,255,.8);
		border: solid 1px #000;
		border-bottom: 0;
		border-radius: 0px 0px 5px 5px;
		border-radius: 0;
		color: #000;
		margin: 0 auto;
		max-width: 1200px;
		position: relative;
		
	}
	
	&:after {
		display: block;
		content: '';
		width: 100%; height: 1px;
	}
	
	.job-foot {
		padding: 1.5em 0;
	}
}

@include media-breakpoint-up(md) {
	#careers-list {
		
		h1.name {
			
			span {
// 				margin-bottom: 1px;
			}
		}
	
		> ul {
			
			> li.open {
				
				.container-fluid {
					
					&::after {
						
// 						height: 4em;
						background-image: url('/images/backgrounds/triangles/careers-foot.svg');	
					}
				}
				
			}
		}
		
		
		a.close-job {
			
			span.icon-close {
				
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	#careers-list {
		
		h1.name {
			
			span {
// 				margin-bottom: 1px;
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	#careers-list {
		> ul {
			
			> li.open {
				
				.container-fluid {
					
					&::after {
						
					}
				}
				
			}
		}
		
		
		a.close-job {
			margin: 15px auto -3em;
			
			span.icon-close {
				
			}
		}
	}
}

// Why Feral Content



#careers-content .about-content {
	
	aside {
		
		#careers_tabs_control {
			li {
				display: block;
				position: relative;
				&.active {
					> a {
						pointer-events: none;
						font-weight: 600;
						-webkit-font-smoothing: antialiased;
						
						&::before {
							transform: rotate(90deg) translateX(1px);
							
						}
						
						&::after {
							content: ' ';
							display: block;
							height: 3px;
							width: calc(100% + 6px);
							background-color: $feral-orange;
							position: relative;
							top: 10px;
							left: -3px;
						}
					}
					
				}
				
				.why-feral {
					padding: 20px 25px;
					
					h3 {
						display: none;
					}
					
					p {
						margin: 0px 0px 10px;
					}
					
					h4, h5 {
						padding-left: 0;
						padding-right: 0;
						margin-left: 0;
						margin-right: 0;
					}
					
					h4 {
						margin-bottom: .2em;
					}
					
					h5 {
						padding-bottom: .6em;
					}
					
					li {
						border: 0;
					}
				
				}
			}
		}
		
		
		
		@include media-breakpoint-up(md) {
			
			overflow: visible;
			div {overflow: visible;		}
			#careers_tabs_control {
				overflow: visible;
				li {
					&.active {
						overflow: visible;
						> a {
							overflow: visible;
							min-height: 48px;
							padding: 13px 15px 7px;
							text-decoration: none;
							&::before {
								transform: rotate(180deg) translate(42px, 21px);
								font-size: 5.2em;
								overflow: visible;
								z-index: 20;
								position: absolute;
							}			
							&::after {
								content: none;
							}
						}
								
					}
				}
			}
		}
		
	}
	
	.about-text {
		div#careers-text-home {
// 			display: block !important;
		}
		div:not(#careers-text-home) {
			display: flex;
			flex-wrap: wrap;
			border: 2px solid #333;
			position: relative;
			border-radius: 14px;
			margin-top: .5em;
			padding: .2em 1.7em;
			background-color: rgba(255, 255, 255, 0.5);
			box-shadow: inset 0px 0px 0px 2px $feral-orange;
			justify-content: space-between;
			h3 {
				margin: 1.0em 0em .6em;
// 				width: 99%;
			}
			
			h4 {
				margin: .8em 0em .6em;		
				min-width: 100%;		
			}
			
			h5 {
				color: $feral-orange;
				margin: .8em 0em .6em;	
				min-width: 100%;
			}
			
			p {
				margin: 0px 0em 1em;
				min-width: 100%;
			}
			
			ul {
				margin: 0px;
				padding: 0px 0px 15px;
				list-style: none;
				min-width: 100%;
				
				li {
					&::before {
						content: none;
					}
				}
			}
		}
	}
	
	.jobs-system {
		display: inline-block;
		margin: 1em auto;
		&::before {
			content: ' ';
		}
	}
	
	div[style*="none"] + .jobs-system {
		display: none;
	}
	
	.careers-close-button {
		padding: 0;
		display: block;
		width: 100%;
		text-align: center;
		&::before {
			content: none;
		}
		
		&:first-of-type {
			position: absolute;
			top: 0;
			left: 0;
			height: 51px;
			span {
				display: none;
			}
		}
		
/*
		&.sticky {
			position: sticky !important;
			top: 75px;
			width: 1%;
			margin: 1.0em -.3em .6em 0em;
			z-index: 1500;
		}
*/
		&:last-of-type {
			margin: 1em auto 0;
			position: relative;
			display: inline-block;
			width: 100%;
			em {
				display: inline-block;
				padding-top: .2em;
			}
			.icon-close {
				margin-right: 7px;
				position: relative;
				top: -1px;
				color: black;
			}
		}
		
		em {
			font-style: normal;
		}
		
		.icon-close {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 1.4rem;
			width: 1.4rem;
			min-height: 1.4rem;
			min-width: 1.4rem;
			background-color: white;
			transition: background-color .2s linear, color .2s linear;
			border-radius: 5px;
			border: 1px solid black;
			cursor: pointer;
			line-height: 21px;
			opacity: 1;
			
			&::before {
				line-height: 1.1em;
				font-size: 1rem;
				font-weight: normal;
				opacity: .7;
				transition: opacity .2s linear;
				text-shadow: none;
			}
		}
		
		&:hover {
			text-decoration: none;
		}
			
		&:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):focus {
			opacity: 1;
			span {
				background-color: $feral-orange;
 				color: white;
				&::before {
					opacity: 1;
					font-weight: normal;
					text-shadow: none;
				}
			}
		}
		
		
		@include media-breakpoint-up(md) {
			padding: 0;
			display: flex;
			align-items: center;
			min-width: 1.43rem;
			&:first-of-type.sticky {
				position: sticky !important;
				top: 75px;
				left: auto;
				height: auto;
				width: 1%;
				margin: 1.0em -.3em .6em 0em;
				z-index: 1500;
				span {
					display: flex;
				}
			}
			
			&:last-child {
				margin: 0px auto 15px;
				position: relative;
				
				.icon-close {
					margin-right: 8px;
				}
			}
			
			em {
				font-style: normal;
			}
			
			.icon-close {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				height: 1.43rem;
				width: 1.43rem;
				min-height: 1.43rem;
				min-width: 1.43rem;
				background-color: white;
				transition: background-color .2s linear, color .2s linear;
				border-radius: 5px;
				border: 1px solid black;
				cursor: pointer;
				line-height: 21px;
				opacity: 1;
				
				&::before {
					line-height: 1.1em;
					font-size: 1rem;
					font-weight: normal;
					opacity: .7;
					transition: opacity .2s linear;
					text-shadow: none;
				}
			}
			
			&:hover {
				text-decoration: none;
			}
				
			&:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):focus {
				opacity: 1;
				span {
					background-color: $feral-orange;
	 				color: white;
					&::before {
						opacity: 1;
						font-weight: normal;
						text-shadow: none;
					}
				}
			}
				
		}
		
	}
}
