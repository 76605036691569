
.modal {
	padding-right: 0 !important;
}

.modal-dialog {
	border: solid 5px #FFF;
	border-radius: 5px;
	font-size: .8rem;
	background-color: #FFF;
	width: 95%;
	max-width: 1140px;
// 	max-height: calc(100vh - 1em);
	&:before {
		display: block;
/* 		content: ''; */
		position: absolute;
/* 			top: 0px; */ left: 0px;
		bottom: 0px; right: 0px;
		@include gradient-y(rgba(255,255,255,.8) 70%, rgba(150,150,150,.8));
		width: 100%; height: 80%;
		border-radius: 3px;
		z-index: 0;
	}
	.close {
		position: absolute;
		z-index: 300;
		top: -11px;
		right: -11px;
		opacity: 1;
		line-height: 21px;
		pointer-events: auto;
		span {
			
			display: block;
			height: 24px;
			width: 24px;
			background-color: white;
			transition: background-color .2s linear, color .2s linear;
			border-radius: 5px;
			border: 1px solid black;
			cursor: pointer;
			line-height: 21px;
			opacity: 1;
			&::before {
				line-height: 21px;
				font-size: 15px;
				font-weight: normal;
				opacity: .7;
				transition: opacity .2s linear;
				text-shadow: none;
			}
		}
		&:not(:disabled):not(.disabled):hover, &:not(:disabled):not(.disabled):focus {
			opacity: 1;
			span {
				background-color: $feral-orange;
// 				color: white;
				&::before {
					opacity: 1;
					font-weight: normal;
					text-shadow: none;
				}
			}
		}
	}
	
	.modal-content {
		border-color: #000;
		border-radius: 4px;
		margin: 0px;
		max-height: calc(100% - 2em);
/*
		max-height: calc(100vh - 2em - 75px);
		@media screen and (max-aspect-ratio: 6/13){
			max-height: calc(100vh - 2em - 115px); // XS etc.
		}
*/
		overflow: auto;
		overflow-x: hidden;
		flex-flow: row wrap;
		z-index: 1;
		background-color: transparent;
		background-size: cover;
// 		pointer-events: none !important;
		
		a {
			word-break: break-word;
		}
		
		ol {
			padding-left: 2em;
			li {
				
			}
		}
		
		.modal-gradient {
// 			content: ' ';
// 			position: absolute;
// 			z-index: -1;
// 			top: 0;
// 			bottom: 0;
// 			left: 0;
// 			right: 0;
			width: 100%;
			min-height: 100%;
			
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+77,6b6b6b+100&0.9+0,0.7+12,0.5+77,0.8+100 */
background: -moz-linear-gradient(top,  rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 12%, rgba(255,255,255,0.5) 77%, rgba(107,107,107,0.8) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.7) 12%,rgba(255,255,255,0.5) 77%,rgba(107,107,107,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.7) 12%,rgba(255,255,255,0.5) 77%,rgba(107,107,107,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6ffffff', endColorstr='#cc6b6b6b',GradientType=0 ); /* IE6-9 */


/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+70,6b6b6b+100&0.9+0,0.7+12,0.5+70,0.8+79,0.8+100 */
background: -moz-linear-gradient(top,  rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 12%, rgba(255,255,255,0.5) 70%, rgba(211,211,211,0.8) 79%, rgba(107,107,107,0.8) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.7) 12%,rgba(255,255,255,0.5) 70%,rgba(211,211,211,0.8) 79%,rgba(107,107,107,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.7) 12%,rgba(255,255,255,0.5) 70%,rgba(211,211,211,0.8) 79%,rgba(107,107,107,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6ffffff', endColorstr='#cc6b6b6b',GradientType=0 ); /* IE6-9 */


/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+70,6b6b6b+100&0.9+0,0.9+15,0.7+28,0.5+70,0.8+79,0.8+100 */
background: -moz-linear-gradient(top,  rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 15%, rgba(255,255,255,0.7) 28%, rgba(255,255,255,0.5) 70%, rgba(211,211,211,0.8) 79%, rgba(107,107,107,0.8) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.9) 15%,rgba(255,255,255,0.7) 28%,rgba(255,255,255,0.5) 70%,rgba(211,211,211,0.8) 79%,rgba(107,107,107,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.9) 15%,rgba(255,255,255,0.7) 28%,rgba(255,255,255,0.5) 70%,rgba(211,211,211,0.8) 79%,rgba(107,107,107,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6ffffff', endColorstr='#cc6b6b6b',GradientType=0 ); /* IE6-9 */

		}
		
	}
	
	.modal-header, .modal-body, .modal-footer {
		position: relative;
		border: none;
		padding-bottom: 0rem;
		flex: 1 100%;
		&:last-child {
			padding-bottom: 1rem;
		}
	}
	
	.modal-header {
		color: $feral-orange;
		z-index: 1;
// 		pointer-events: none;
	}
	
	.modal-error {
		background-color: $danger;
		margin: 5px 30px 0px;
		@include border-radius(4px);
		width: 100%;
		color: #FFF;
		padding: 3px 10px;
		font-size: 90%;
	}
	
	.modal-body {
// 		pointer-events: none;
		z-index: 0;
		
		
		a {
// 			pointer-events: auto;
		}
	}
	
	.modal-footer > .row {
		width: calc(100% + 30px);
		
		.btn {
			margin-bottom: 5px;
			
			&:last-child {
				margin-bottom: 0px;
				font-size: .8em;
			}
			
			&[data-method=PAYPAL] {
				&::before {
					background-color: white;
				}
				&:hover {
					background-color: $feral-orange;
				}
				span {
					color: transparent;
					display: inline-block;
					background: url('/images/logos/paypal.svg') center center no-repeat transparent;
					background-size: contain;
// 					height: auto;
// 					margin: -3px auto;
					width: 100%;
				}
			}
			
			&.loading {
				&::after {
					font-size: 1.4em;
				}
				&[data-method="PAYPAL"] {
					span {
						opacity: 0;
					}
				}
				&.btn-info {
					color: #808080;
				}
			}
			
			// FIXING BUTTON CORNERS FOR LARGE SCREENS
			@include media-breakpoint-up(xl) {
					padding-left: 23px;
					-webkit-clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
				    clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
				    &:before {
					    -webkit-clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
					    clip-path: polygon($largecornerclip 0px, 100% 0px, 100% 100%, 0px 100%, 0px $largecornerclip);
					}
				
			}


		}
	}
}

@include media-breakpoint-up(sm) {
	.modal-dialog {
// 		max-height: calc(100vh - 3.5em);
		.modal-content {
			border-color: #000;
			border-radius: 4px;
			margin: 0px;
			max-height: calc(100% - 2.5em);
			overflow: auto;
			overflow-x: hidden;
		}
	}
}

@include media-breakpoint-up(md) {
	.modal-dialog {
		margin: 1vw auto;
		.modal-footer > .row {
			flex-wrap: nowrap;
			> .col-5 {
				display: flex;
				align-items: flex-end;
				text-align: left;
				flex: 1 1 auto;
				width: auto;
			}
			
			> .col-7 {
				display: flex;
				align-items: flex-end;
			    justify-content: space-between;
				flex: 1 1 auto;
				max-width: 100%;
				.btn:first-child:last-child {
					margin-left: auto;
				}
			}
			
			.btn {
				margin: 5px 0 0;
				
				&:first-child {
					margin-left: 0px;
				}
				
				&:last-child {
					margin-right: 0px;
					margin-left: 10px;
				}
				
				&:first-child:last-child {
					margin-left: 0;
					white-space: nowrap;
				}
				
			}
		}
	}
}

#faqModal {
	.modal-content {
		font-size: 1rem;
		.modal-gradient {
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+77,ffffff+100&0.9+0,0.7+12,0.5+77,0.8+100 NOT USED */
			background: rgba(255,255,255,0.85);

		}
		.modal-header {
			font-size: 1em;
			h5 {
				line-height: 1.4em;
				font-size: 1.3em;		
				padding: 0 0 .8em;	
			}
		}
		
		.modal-body {
// 			padding: 1rem;
			
			&:before {
				content: none;
			}
			p {
/*
				a:first-child:last-child {
					display: inline-block;
					border: 1px solid silver;
					padding: .2em .8em;
					border-radius: 4px;
					transition: color .2s linear, background-color .2s linear, border-color .2s linear;
					&:hover {
						background-color: silver;
						color: black;
						border-color: #626262;
						text-decoration: none;
					}
				}
*/
			}
			
			ol, ul {
// 				margin-bottom: 1.2em;
			}
			
			img {
				max-width: 100%;
				&[src*=iPadControlCentreSilentModeButton] {
					width: 362px;
				}
			}	
			
			table {
				border-spacing: .1em;
			    border-collapse: separate;
			    font-size: 1em;
			    line-height: 1.4em;
			    width: calc(100% + 1.4em);
				margin: 1em -.7em;
			    /*

			    max-width: 100%;
			    overflow: auto;
*/
			    tr {
				    th {
					    background-color: rgba($feral-orange, .2);
						padding: .5em;
						text-align: left;
						&:nth-child(1):nth-last-child(2), &:nth-child(2):nth-last-child(1) {
							width: 50%;
						}
						&:nth-child(1):nth-last-child(3), :nth-child(2):nth-last-child(2), &:nth-child(3):nth-last-child(1) {
							width: 33%;
						}
						&:nth-child(1):nth-last-child(4), :nth-child(2):nth-last-child(3), &:nth-child(3):nth-last-child(2), &:nth-child(4):nth-last-child(1) {
							width: 25%;
						}
				    }
					td {
						background-color: rgba(122, 122, 122, 0.2);
						padding: .5em;
						vertical-align: top;
						&:nth-child(1):nth-last-child(2), &:nth-child(2):nth-last-child(1) {
							width: 50%;
						}
						&:nth-child(1):nth-last-child(3), :nth-child(2):nth-last-child(2), &:nth-child(3):nth-last-child(1) {
							width: 33%;
						}
						&:nth-child(1):nth-last-child(4), :nth-child(2):nth-last-child(3), &:nth-child(3):nth-last-child(2), &:nth-child(4):nth-last-child(1) {
							width: 25%;
						}
					}
					
					&:first-child {
						:first-child {
							border-radius: 5px 0px 0px 0px;
						}
						:last-child {
							border-radius: 0px 5px 0px 0px;
						}
						:nth-child(1):nth-last-child(1) {
							border-radius: 5px 5px 0px 0px;
						}
					}
					&:last-child {
						:first-child {
							border-radius: 0px 0px 0px 5px;
						}
						:last-child {
							border-radius: 0px 0px 5px 0px;
						}
						:nth-child(1):nth-last-child(1) {
							border-radius: 0px 0px 5px 5px;
						}
					}
				}
			}
			.handle {
				display: block;
				margin-top: 1rem;
				font-weight: 500;
				color: black;
				pointer-events: none;
			}
			
			.reqs_listing {
				padding: 0;
				display: flex;
				flex: 0 0 100%;
				flex-wrap: wrap;
				margin: 0 auto 5px;
				li {
					display: flex;
					flex: 0 0 100%;
					flex-wrap: nowrap;
					justify-content: flex-start;
					flex-direction: row;
					align-content: flex-start;
					align-items: baseline;
					padding: 4px 5px;
					background-color: white;
					margin-bottom: 2px;
					border-bottom: 1px solid #d2d2d2;
					&:first-child {
// 						border-radius: 3px 3px 0px 0px;
					}
					&:last-child {
// 						border-radius: 0px 0px 3px 3px;
						margin-bottom: 0;
					}
					h5 {
						font-size: 1rem;
						margin: .2rem .5rem 0 0;
						flex: 0 0 30%;
						text-align: left;
						&::after {
							content: ':';
							[lang=fr] & {
								content: ' :'
							}
						}
					}
					p {
						font-size: 1rem;
						flex: 0 1 calc(70% - .5rem);
						text-align: left;
						margin: .2rem 0 0;
					}
				}
				@include media-breakpoint-up(sm) {
					justify-content: center;
					margin: 0 -2px 5px;
					li {
	// 					flex: 1 1 calc(20% - 4px);
						flex: 1 1 auto;
						flex-wrap: wrap;
						padding: 8px 10px;
						margin: 2px !important;
						border-radius: 3px !important;
						border: 1px solid #d2d2d2;
						h5 {
							flex: 0 0 100%;
							text-align: center;
							margin: 0;
						}
						p {
							flex: 0 0 100%;
							text-align: center;
							margin: 4px 0 0;
						}
					}
				}
			}
			
			.devices_holder {
				background-color: transparent;
				margin: 0px; padding: 0px;
				border: 0px;
				
				.note {
					padding: 1em 1rem;
					margin-top: 2px;
// 					background-color: #FFF;
					
					p:last-child {
						margin-bottom: 0;
					}
				}
				
				.devices {
					display: flex;
					flex-wrap: wrap;
					justify-content: stretch;
					margin-top: 2px;
					padding: 5px 2px 2px;
// 					background-color: #FFF;
					
					h4 {
						width: 100%;
						padding: 10px 5px 3px;
					}
					
					.devices_list {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 100%;
						background-color: rgba(0, 0, 0, 0.1);
						margin: 2px;
						border-radius: 3px;
						
						&.iconed {
							flex-direction: row;
							justify-content: space-between;
							&.iphone {
								div { 
// 									width: 	78px; 
									+ ul {
	// 									padding: 1em;
										padding-left: 0px;
										min-width: calc( 100% - 2em - 78px);
										max-width: calc( 100% - 2em - 78px);
									}
								}
							}	
							
							&.ipad {
								div { 
// 									width: 	78px;
									
								
									+ ul {
	// 									padding: 1em;
										padding-left: 0px;
										min-width: calc( 100% - 2em - 78px);
										max-width: calc( 100% - 2em - 78px);
										
										li.device-iPad_Pro_11_4gb + li.device-iPad_Pro_11_6gb,
										li.device-iPad_Pro_12_9_3_4gb + li.device-iPad_Pro_12_9_3_6gb {
											display: none;
										}
									}
								}
							}
							
							div {
								margin: 1em;
								width: 78px;
								text-align: center;
							}
										
									
									
							img {
								&[src$="67.svg"] {
									width: 54px;
									height: 100px;
								}
								&[src$="65.svg"] {
									width: 42px;
									height: 88px;
								}
								
								&[src$="61.svg"] {
									width: 44px;
									height: 86px;
								}
								
								&[src$="58.svg"] {
									width: 38px;
									height: 81px;
								}
								
								&[src$="54.svg"] {
									width: 35px;
									height: 73px;
								}
								
								&[src$="55.svg"] {
									width: 54px;
									height: 100px;
								}
								
								&[src$="47.svg"] {
									width: 44px;
									height: 86px;
								}
								
								&[src$="40.svg"] {
									width: 35px;
									height: 73px;
								}
								
								&[src$="35.svg"] { // not set, not used for anything SO FAR
									width: 0;
									height: 0;
								}
								
								&[src$="129.svg"] {
									width: 78px;
									height: 111px;
								}
								
								&[src$="new_129.svg"] {
									width: 77px;
									height: 101px;
								}
								
								&[src$="110.svg"] {
									width: 64px;
									height: 89px;
								}
								
								&[src$="105.svg"] {
									width: 62px;
									height: 89px;
								}
								
								&[src$="97.svg"] {
									width: 60px;
									height: 88px;
								}
								
								&[src$="83.svg"] {
									width: 48px;
									height: 71px;
								}
								
								&[src$="79.svg"] {
									width: 48px;
									height: 71px;
								}
							

							}
						}
						
						ul {
							list-style: none;
							width: 100%;
							padding: .3em 1em; margin: 0px;
							
							li {
								padding: .9em .5em;
								margin: 0;
								line-height: 1.1em;
								border-top: 2px solid rgba(255, 255, 255, 0.5);
								
								&:first-child {
// 									padding-top: 0;
									border-top: none;
								}
								
								&:last-child {
// 									padding-bottom: 0;
								}
								span {
									font-size: .7rem;
									line-height: 1.1em;
								}
							}
						}
					}
				}
			}
			
				
			&#faq-ios_silent_mode {
				p:nth-child(4) {
					text-align: center;
					img {
						border-radius: 1em;
					}
				}
			}
			
		}
	}
}

#changeCountryModal {
	#countryChangeCurrency, #countryChangeRestricted {
		display: none;
	}
}

#checkoutModal {
	.modal-content {
		font-size: 1rem;
		
	}
	.modal-header {
		
		@media screen and (max-width: 374px) {
			padding-left: 7px;
			padding-right: 7px;
		}
		h5 {
			font-size: 1.8rem;
			margin: 0px;
		}
	}
	.modal-body {
		
		@media screen and (max-width: 374px) {
			padding-left: 7px;
			padding-right: 7px;
		}
	}
	
	h6 {
		padding: .5em; margin: 0px 0px 5px;
		background-color: #000;
		color: $feral-orange;
		border-radius: 3px;
		text-align: center;
		font-weight: normal;
		text-transform: uppercase;
		font-size: 1rem;
	}
	ul.bases + h6, ul.upgrades + h6 {
		margin-top: 5px;
	}
	form {
		margin: 0px;
	}
	
	.invalid {
		position: relative;
		margin-top: 2.2em;
		@include media-breakpoint-up(sm) {
			margin-top: 0;
		}
		input {
			background-color: #f9dfdf !important;
			border-color: #C00 !important;
		}
		
		// THE ERROR HANDLING HERE
		
		
		span {
			display: block;
			position: absolute;
			top: -2rem;
			@include media-breakpoint-up(sm) {
				top: -2.05rem;
			}
			left: 50%;
			transform: translate(-50%);
			padding: .3em .8em;
			background: white;
			border-radius: 7px;
			color: #cc3535;
			width: auto;
			white-space: nowrap;
			filter: drop-shadow(0 0 .3rem rgba(204, 53, 53, 0.3));
			z-index: 10;
			font-size: .85rem;
			font-weight: 500;
			border: 1px solid #cc3535;
			&::before {
				content: ' ';
				display: block;
				background: inherit;
				transform: rotate(45deg) translate(-50%);
				width: .8em;
				height: .8em;
				position: absolute;
				bottom: -.74em;
				left: 50%;
				z-index: 9;
				border: 1px solid #cc3535;
				border-left: none;
				border-top: none;
			}
		}
		
		/*
		
		&[data-error] {
			position: relative;
			padding-bottom: 25px;
			
			&:after {
				position: absolute;
				content: attr(data-error);
				display: block;
				bottom: 0px; left: 5px; right: 5px;
				font-size: 75%;
				color: #C00;
				font-weight: bold;
			}
		}
		
		*/
	}

/*
	ul.basket {
		li {
			flex-wrap: wrap;
		}
		li:nth-child(2)::after {
			content: "Requires Total War: WARHAMMER II";
			display: flex;
			width: 100%;
			background: black;
			padding: 5px;
			font-size: .8rem;
			align-content: center;
			justify-content: center;
			border-radius: 0px 0px 5px 5px;
		}
	}
*/
	
	ul {
		list-style: none;
		padding: 0px; margin: 0px;
		
		&.addons, &.upgrades, &.bases {
			
			li {
				flex-wrap: nowrap;
				flex-direction: row;
				@include media-breakpoint-down(xs) {
					flex-wrap: wrap;
				}
			}
			div {
				display: flex;
				align-items: center;
				width: 80%;
				@include media-breakpoint-down(xs) {
					width: 100%;
				}
			}
			
			form {
				display: flex;
				width: 20%;
				@include media-breakpoint-down(xs) {
					margin-top: 0;
					width: 100%;
				}
			}
			form button {
				width: 100%;
			}
			img {
				min-width: 64px;
			}
			
		}
	
		&.bases, &.upgrades {
			> li {
// 				display: block;
				padding: 0 ; margin: 3px 0px 0;
				background-color: rgba(0,0,0,.8);
				text-align: center;
				
				&:first-child {
					margin-top: 0px;
				}
				
				img {
					margin: 5px 10px 5px 5px;
					flex: 0 0 64px;
				}
				
				p {
					text-align: left;
				}
				
				
			}
		}
		
		&.addons {
			background-color: rgba(0,0,0,.5);
			border-radius: 3px;
			
			> li {
				display: block;
				padding: 0px 5px 5px; 
				margin: 0px;
				background-color: transparent;
				text-align: center;
				
				&:first-child {
					padding-top: 5px;
				}
				
				img {
					margin: 5px 10px 5px 5px;
					flex: 0 0 64px;
				}
				
				> p {
					padding: 0px 5px 5px;
				}
				
				ul {
					
					@include media-breakpoint-up(md) {
						max-height: 7em;
						overflow: hidden;
						position: relative;
						&::after {
							content: 'Show more...';
							
							[lang="fr"] & {
								content: 'Développer';
							}
							[lang="it"] & {
								content: 'Espandi';
							}
							[lang="de"] & {
								content: 'Einblenden';
							}
							[lang="es"] & {
								content: 'Ampliar';
							}
							[lang="ru"] & {
								content: 'Раскрыть';
							}
							[lang="zh_CN"] & {
								content: '展开';
							}
							[lang="pr_BR"] & {
								content: 'Expanda';
							}

							display: block;
							position: absolute;
							font-size: 1em;
							bottom: 0;
							left: 0;
							right: 0;
							height: 3em;
							padding-top: 1.5em;
							box-sizing: border-box;
							color: $feral-orange;
							
							border-radius: 3px;
							transition: max-height .2s linear;
 background: rgb(51,51,51);
background: -moz-linear-gradient(0deg, rgba(51,51,51,0.95) 40%, rgba(51,51,51,0.2) 100%);
background: -webkit-linear-gradient(0deg, rgba(51,51,51,0.95) 40%, rgba(51,51,51,0.2) 100%);
background: linear-gradient(0deg, rgba(51,51,51,0.95) 40%, rgba(51,51,51,0.2) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#333333",endColorstr="#333333",GradientType=1); 
						}
						
						&:hover {
							max-height: 1000em;
							&::after {
								display: none;
							}
						}
					}
				}
				
				li {
					background-color: rgba(0,0,0,.6);
					
					&:last-child {
						margin-bottom: 0px;
					}
					
					p {
						line-height: 1.2em;
						padding: 5px 0;
					}
				}
			}
		}
	}
	
	li {
		background-color: rgba(0,0,0,.8);
		border-radius: 3px;
		margin: 0px 0px 3px;
		color: #FFF;
		display: flex;
		align-items: center;
		text-align: left;
		
		> img {
			margin: 5px 10px 5px 5px;
			flex: 0 0 64px;
		}
		
		> form {
			margin: 5px 10px 5px 5px;
			
			button.img {
				position: relative;
				margin: 0px; padding: 0px;
				border: none;
				background: none;
				flex: 0 0 64px;
				> span {
					opacity: 0;
					pointer-events: none;
					position: absolute;
					padding-top: 13px;
					top: 0px; left: 0px;
					background-color: rgba(0,0,0,.7);
					width: 100%; height: 100%;
					color: #FFF;
					text-transform: uppercase;
					font-size: .7rem;
					transition: opacity .2s linear;
					span {
						color: $feral-orange;
						border: solid 2px;
						border-radius: 4px;
						font-size: 1.2rem;
						width: 1.5rem; height: 1.5rem;
						margin-bottom: 5px;
						line-height: 1.1em;
					}
				}
			}
			
			+ p {
				padding: 5px 0;
			}
		}
		
		&:hover button.img > span {
			opacity: 1;
			pointer-events: auto;
		}
	
		p {
			margin: 0px;
			width: 100%;
			padding: 5px 0;
		}
		
		aside {
			margin: 5px;
			min-width: 65px;
			text-align: center;
		}
		
		.price {
			display: block;
			padding-top: 5px;
			margin-bottom: -4px;
		}
	}
	
	.basket-total {
		margin: 0px 0px 5px; 
		padding: 3px 10px;
		@include media-breakpoint-up(md) {
// 		padding: 3px 5px;
		}
		background-color: rgba(0,0,0,.8);
		border-radius: 3px;
		color: $feral-orange;
		text-align: right;
		font-size: 2rem;
		
		small {
			color: #FFF;
			font-size: 1rem;
			text-transform: uppercase;
			float: left;
			padding: 1.1em 0 0em;
			&:nth-of-type(2):last-child {
				display: block;
				font-size: .8rem;
				text-transform: none;
				font-style: italic;
				padding: .8em 0 .8em;
				float: none;
				border-top: 2px solid #5f5f5f;
			}
		}
	}
	
	p.item {
		margin: 0px 0px 5px; padding: .7em .9em;
		background-color: rgba(0,0,0,.8);
		border-radius: 3px;
		color: #FFF;
		font-size: .9rem;
	}
	
	input[type=text], input[type=email], select {
		display: block;
		width: 100%;
		padding: .25rem .5rem;
		border: solid 1px #EEE;
		border-radius: 3px;
	}
	
	fieldset {
		padding: 7px;
		background-color: rgba(0,0,0,.8);
		border-radius: 3px;
		color: #FFF;
		
		label {
			font-weight: bold;
			margin-bottom: 3px;
		}
		
		.form-group {
			margin-bottom: 10px;
			#countryFlex {
				display: flex;
				align-items: stretch;
				align-content: stretch;
				flex-wrap: wrap;
				@include media-breakpoint-up(sm) {
					flex-wrap: nowrap;
				}
				input {
					flex: 1 1 100%;
					display: flex;
					@include media-breakpoint-up(sm) {
						flex: 1 1 auto;
					}
				}
				button {
					flex: 0 0 100%;
					width: auto;
					white-space: nowrap;
					display: flex;
					justify-content: center;
					margin: .4em 0 0 0;
					@include media-breakpoint-up(sm) {
						flex: 0 0 auto;
						margin: 0 0 0 .4em;
					}

					@include media-breakpoint-up(md) {
						font-size: .8em;
						padding: 8px 20px 8px 24px;
					}
				}
			}
		}
		
		.details div.field-row {
			display: flex;
			margin-bottom: 3px;
			
			div {
				display: flex;
				width: 100%;
			}
		}
		
		> p:last-child {
			margin: .3em 0px;
			text-align: center;
			font-size: .9rem;
		}
	}
		
	table {
		margin-bottom: 5px;
		background-color: rgba(0,0,0,.8);
		border-radius: 3px;
		font-size: 1rem;
		color: #FFF;
		width: 100%;
		th {
			color: $feral-orange;
			font-weight: 500;
			text-align: left;
			padding: 1rem 0 0 1.2rem;
		}
		
		td {
			padding: 1rem 1.2rem 0 .5rem;
			word-break: break-word;
		}
		
		tr:last-child {
			th, td {
				padding-bottom: 1rem;
			}
		}
	}
	
	iframe {
		width: 100%;
		border: 0;
		min-height: 550px;
		@include media-breakpoint-up(sm) {
// 			min-height: 000px;
		}
		@include media-breakpoint-up(md) {
			min-height: 520px;
		}
		@media screen and (min-width: 1030px) {
			min-height: 420px;
		}
		border-radius: 3px;
	}
	
	.purchase_summary div {
		padding: 1em;
		margin-bottom: 5px;
		background-color: rgba(0,0,0,.8);
		border-radius: 3px;
		color: #FFF;
		
		p {
// 			font-size: 1em;
			@include media-breakpoint-up(md) {
// 				font-size: 1.5em;
			}
			
		}
		
		code {
			display: block;
			background-color: #FFF;
			color: $feral-orange;
			text-align: center;
			margin: 0 0 1em;
			border-radius: 2px;
			line-height: 1.9rem;
			font-family: $font-family-base;
			font-size: 1em;
			word-break: break-word;
			padding: .3em;
			@include media-breakpoint-up(md) {
				padding: 1em;	
				margin: 1em;			
			}
		}
		
		> *:last-child {
			margin-bottom: 0px;
		}
	}
	
	.modal-footer {
		justify-content: space-between;
		.row {
			> div {
				
				@include media-breakpoint-down(sm) {
					&:first-child{
						padding-left: 0;
					}
					
					&:last-child{
						padding-right: 0;
					}
				}
				&:first-child{
// 					padding-left: 0;
				}
				
				&:last-child{
// 					padding-right: 0;
					text-align: right;
					justify-content: flex-end;
					flex-wrap: wrap;
				}
				&:first-child:last-child {
					text-align: left;
				}
				a[type="button"] {
						
					-moz-appearance: none;
				    -webkit-appearance: none;
				    appearance: none;
					
				}	
			}
			small {
				display: block;
				padding-top: 10px;
				color: #FFF;
			}
		}
	}
}

#buyModal {
	.product-details {
		
		&.gridlegends .shareable + .warning {
			display: none;
		}
		
//		background-color: #C00;
		.modal-body {
			text-align: center;
		}
				
		.btn-success {
			clip-path: polygon(0 0px, 100% 0px, 100% 100%, 0px 100%, 0px 0);
			font-size: 1.5rem;
			padding: 7px 10px;
			&::before {
				clip-path: none;
			}
			
			span {
				font-size: 1.5rem;
				font-weight: bold;
			}
		}
		
		&.dlc, .expansion {
// 			.modal-header {
/*
				&::before {
					content: 'DLC';
					position: absolute;
					right: 0;
					top: 20px;
					width: auto;
					height: auto;
					padding: .5em .55em .45em 1.2em;
					font-weight: 700;
					background-image: url('/images/backgrounds/triangles/dlc-frame.svg');
					background-position: left center;
					background-size: cover;
					background-repeat: no-repeat;
					z-index: 1;
					text-transform: uppercase;
					text-align: right;
					color: white;
					font-size: 1rem;
				}
*/
				
				&:hover::before {
// 					content: attr(data-basegame);
// 					padding-left: 2em;
// 					max-width: 95%;
				}
// 			}	
			
			
			
		}
		
		.expansion {
			.panel {
/*
				&::before {
					content: 'Expansion';
				}
*/
			}
		}
		
		&.gridautosport-mobile {
			aside {
				h6:first-of-type + img + img {
					width: 100px;
					margin-top: 1rem;
				}
			}
		}
		&.gridautosport-switch {
			aside {
				h6:first-of-type + img {
					width: 100px;
// 					margin-top: 1rem;
				}
			}
		}
		
	}
	img {
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;
	}
	
	img.rating {
		max-width: 150px;
		height: auto;
		margin: 7px;
		&:first-of-type {
			margin-top: 25px;
			&[src*="IOS"], &[src*="mobile"]:last-of-type {
				background-color: white;
				border-radius: 3px;		
			}
		}		
	}
	
	.modal-header {
		flex-direction: column;
		text-align: center;
		
		img {
			margin: 10px auto 20px;
		}
		
		img.rating {
			display: flex;
			order: 2;
			margin: 0 0 -47px 20px;
		}
		
		h2 {
			margin: 0px -10px;
			color: $feral-orange;
			background-color: rgba(255,255,255,.9);
			border: solid 1px #DDD;
			border-radius: 5px;
			line-height: 1.2em;
			padding: .4em .2em;
			font-size: 2em;
			width: calc(100% + 20px);
		}
			
		.dlc-requires {
			width: 100%;
			display: block;
			font-size: 1.2em;
			font-weight: normal;
			color: rgba(0, 0, 0, 0.7);
			margin-top: 10px;
			line-height: 1.2em;
			span {
				margin-right: .5em;
			}
		}
	}

	
	.price-details {
		display: flex;
		font-size: 2rem;
		width: 100%;
		min-width: 100%;
		color: white;
		flex-wrap: wrap;
		justify-content: center;
		* {
			align-self: center;
		}
		
		.icon-linux::before {
			content: '\e80c';
		}
		
		.price {
			margin: 20px auto 0;
			font-weight: bold;
			color: #212529;
			del {
				font-size: .5em;
				top: -.7em;
			}
		}
		.tbyb_info {
			font-size: .8em;
			line-height: 1.1em;
			em {
				display: block;
				text-transform: uppercase;
				font-weight: 500;
				padding: .3em .6em;
				background-color: #212529;
				border-radius: .3em;
				font-style: normal;
				margin-top: .3em;
				margin-bottom: .3em;
			}
			small {
				display: block;
				font-weight: 500;
				font-size: .8em;
				color: #212529;
			}
			+ .price {
				margin-top: 0;
				min-width: 100%;
			}
		}
	}
	
	.ias-button + .price-details.multi {
		display: none;
	}
	
	a + .price-details {
		display: none;
		
		&.multi {
			display: flex;
		}
	}
	
	.ias-button + .gpy-button img, .ias-button + .price-details + .gpy-button img  {
		padding-top: 10px;
	}
	
	.gpy-button img, .ias-button img {
		@media only screen and (min-width: 470px) and (max-width: 768px){
			height: 75px;
			width: auto;
			padding: 8px 5px !important;
		}
		
	}
	
	.nintendo-button, .egs-button, .mqstore-button {
		background-color: #333333;
		border-radius: 16px;
		display: inline-block;
		padding: .5em .7em .65em;
		border: 1px solid #8a8a8a;
		font-size: 1.2em;
		transition: background-color .2s linear, transform .2s linear;
		span {
			text-transform: uppercase;
			padding: 0em .5em .5em;
			display: inline-block;
			font-weight: 500;
			transition: color .2s linear;
		}
		&:hover {
			text-decoration: none;
			border: 1px solid #8a8a8a;
			background-color: #151515;
			transform: scale(.98);
// 			transition: background-color .1s linear;
			span {
				text-decoration: none;
// 				color: white;
			}
		}
		&:active {
// 			transform: scale(.98) translateY(2px);
			background-color: #777777;
			transition: background-color .1s linear;
		}
	}
	
	.multiplayer {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 10px; padding: 0px;
		background-color: #FFF;
		border: solid 1px #DDD;
		border-radius: 5px;
		
		li {
			display: flex;
			align-items: center;
			padding: 3px 5px;
			width: 100%;
			border-top: solid 1px #DDD;
// 			border-right: solid 1px #DDD;
			
			> span {
				margin-right: 5px;
				width: 1.2rem;
			}
			
			&:first-child {
				width: 100%;
				text-align: center;
				font-weight: bold;
				border-top: none;
				border-right: none;
				justify-content: center;
			}
			
			&:nth-child(odd) {
				border-right: none;
			}
			
			&:nth-child(even):last-child {
				border-right: none;
				width: 100%;
			}
		}
	}
	
	.shareable {
		margin: 10px 0;
		@include media-breakpoint-up(md) {margin: 10px;}
		text-align: center;
		a {
			border: 1px solid #d2d2d2;
		}
		.fa-x-twitter {
			border-color: black;
			box-shadow: none;
		}
		.fa-twitter {
			border-color: #1d9bde;
		}
		.fa-facebook-f {
			border-color: #4a72b6;
		}
		.fa-reddit-alien {
			border-color: #fc6400;
		}
		.fa-envelope {
			border-color: #8a8a8a;
		}
	}
	
	.reqs_holder {
		display: none;
		
		&.open {
			display: block;
		}
		
		> *:last-child {
			margin-bottom: 0px !important;
		}
		
		.reqs_listing {
			padding: 0 !important;
			display: flex;
			flex: 0 0 100%;
			flex-wrap: wrap;
			margin: 0 auto 5px;
			li {
				display: flex;
				flex: 0 0 100%;
				flex-wrap: nowrap;
				justify-content: flex-start;
				flex-direction: row;
				align-content: flex-start;
				align-items: baseline;
				padding: 4px 5px;
				background-color: white;
				margin-bottom: 2px;
				border-bottom: 1px solid transparent;
				&:first-child {
					border-radius: 3px 3px 0px 0px;
				}
				&:last-child {
					border-radius: 0px 0px 3px 3px;
					margin-bottom: 0;
				}
				h5 {
					font-size: 1rem;
					margin: .2rem .5rem 0 0;
					flex: 0 0 30%;
					text-align: left;
					&::after {
						content: ':';
						[lang=fr] & {
							content: ' :'
						}
					}
				}
				p {
					font-size: 1rem;
					flex: 0 1 calc(70% - .5rem);
					text-align: left;
					margin: .2rem 0 0;
				}
			}
			@include media-breakpoint-up(sm) {
				justify-content: center;				
				margin: 0 -2px 5px;
				li {
// 					flex: 1 1 calc(20% - 4px);
					flex: 1 1 auto;
					flex-wrap: wrap;
					padding: 8px 10px;
					margin: 2px !important;
					border-radius: 3px !important;
					h5 {
						flex: 0 0 100%;
						text-align: center;
						margin: 0;
					}
					p {
						flex: 0 0 100%;
						text-align: center;
						margin: 4px 0 0;
					}
				}
			}
		}
		
	}
	
	.requirements {
		margin: 0px -10px; padding: 5px;
		background-color: rgba(0, 0, 0, 0.4);
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.6+11,0.3+100 */
		background: -moz-linear-gradient(top,  rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 11%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 11%,rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 11%,rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99000000', endColorstr='#4d000000',GradientType=0 ); /* IE6-9 */

		border: solid 1px rgba(0, 0, 0, 0.5);
		border-radius: 5px;
		
		h4 {
			text-align: center;
			color: white;
			+ p {
				color: white;
				font-size: .9rem;
			}
		}
		
		.devices {
			h4 {
				color: $body-color;
			}
		}
		
		nav {
			text-align: center;
			margin-bottom: .8em;
			a {
				display: inline-flex;
				width: calc(2.6rem + 2px); height: calc(2.6rem + 2px);
				border: solid 2px black;
				border-radius: 100%;
				color: #FFF;
				text-align: center;
				background-color: #333;
				font-size: 1.45rem;
				font-weight: bold;
				text-decoration: none;
				box-sizing: content-box;
				transition: border .2s linear;
				margin: 2px;
				
				span {
					margin: auto;
					align-self: center;
					&::before {
						text-shadow: $small-black-text-shadow;
					}
				}
				
				&:hover {
					border-color: $feral-orange;
				}
				
				&:active {
					transform: scale(.93);
					box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 1);
					span::before {
						
					}
				}
				
				&.active {
					border-color: black;
					border-width: 2px;
					background-color: #000;
					width: calc(2.6rem + 2px); height: calc(2.6rem + 2px);
	// 				filter: drop-shadow(0 0 .3rem $feral-orange);;
					background-color: #FFF;
					color: $feral-orange;
					pointer-events: none;
					span {
						&::before {
							text-shadow: 0px 0px 3px white, 0px 0px 1px white, 0px 0px 5px white;
						}
					}
				}
			}
		}
		
		
		p:first-of-type {
			text-align: center;
			
			span {
				display: none;
			}
		}
		
		p:empty {
			display: none;
		}
		
		nav.ios_reqs + h4 + p:first-of-type span#ios-reqs-note, nav.android_reqs + h4 + p:first-of-type span#android-reqs-note {
			display: inline;
		}
		
		th, td {
			text-align: left;
			padding: 0.3vh 1.5vw;
		}
		
		th {
			font-size: 1rem;
		}
		
		td {
			font-size: .95rem;
		}
		
		a.handle {
			display: block;
			padding: 3px 5px;
			margin-top: 5px;
			background-color: #FFF;
			border-radius: 3px;
			color: $body-color;
			font-weight: bold;
			font-size: 1rem;
			padding-left: 2rem;
			position: relative;
			text-align: left;
			&:first-of-type {
				margin-top: 2px;
			}
			
			&:before {
				@include fa-icon("\f0da");
				display: inline-block;
				width: 1.5rem;
				text-align: center;

				margin: 0;
				top: 6px;
				left: .3rem;				
				position: absolute;
				
				
			}
			
			&.open {
				border-radius: 3px 3px 0px 0px;
				&::before {
					content: "\f0d7";
				}
			}
		}
		#mac_reqs {
			ul {
				padding-left: 1.6em;
				li {
					margin: .2em 0;
				}
			}
		}
		.expandable {
			display: none;
			padding: 1em 1.2em;
			margin-top: 2px;
			margin-bottom: 5px;
			background-color: #FFF;
			border-radius: 0px 0px 3px 3px;
			width: 100%;
			font-size: .95rem;
			text-align: left;
			&.open {
				display: block;
			}
			p {
				text-align: left;
				&.install-space {
					white-space: pre-wrap;
				}
				&:first-of-type:last-of-type {
					margin-bottom: 0;
				}
			}
			
			&.devices_holder {
				background-color: transparent;
				margin: 0px; padding: 0px;
				border: 0px;
				
				.note {
					padding: 1em 1rem;
					margin-top: 2px;
					background-color: #FFF;
					
					p:last-child {
						margin-top: 1em;
						margin-bottom: 0;
					}
				}
				
				.devices {
					display: flex;
					flex-wrap: wrap;
					justify-content: stretch;
					margin-top: 2px;
					padding: 5px 2px 2px;
					background-color: #FFF;
					
					h4 {
						width: 100%;
						padding: 10px 5px 3px;
					}
					
					.devices_list {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 100%;
						background-color: #EEE;
						margin: 2px;
						border-radius: 3px;
						
						&.iconed {
							flex-direction: row;
							justify-content: space-between;
// 							flex-grow: 1;
// 							align-content: center;
							&.iphone {
								div { 
// 									width: 	78px; 
									+ ul {
	// 									padding: 1em;
										padding-left: 0px;
										min-width: calc( 100% - 2em - 78px);
										max-width: calc( 100% - 2em - 78px);
									}
								}
							}	
							
							&.ipad {
								div { 
// 									width: 	78px;
									
								
									+ ul {
	// 									padding: 1em;
										padding-left: 0px;
										min-width: calc( 100% - 2em - 78px);
										max-width: calc( 100% - 2em - 78px);
										
										li.device-iPad_Pro_11_4gb + li.device-iPad_Pro_11_6gb,
										li.device-iPad_Pro_12_9_3_4gb + li.device-iPad_Pro_12_9_3_6gb {
											display: none;
										}
									}
								}
							}
							
							div {
								margin: 1em;
								width: 78px;
								text-align: center;
							}
										
									
							img {
								&[src$="67.svg"] {
									width: 54px;
									height: 100px;
								}
								&[src$="65.svg"] {
									width: 42px;
									height: 88px;
								}
								
								&[src$="61.svg"] {
									width: 44px;
									height: 86px;
								}
								
								&[src$="58.svg"] {
									width: 38px;
									height: 81px;
								}
								
								&[src$="54.svg"] {
									width: 35px;
									height: 73px;
								}
								
								&[src$="55.svg"] {
									width: 54px;
									height: 100px;
								}
								
								&[src$="47.svg"] {
									width: 44px;
									height: 86px;
								}
								
								&[src$="40.svg"] {
									width: 35px;
									height: 73px;
								}
								
								&[src$="35.svg"] { // not set, not used for anything SO FAR
									width: 0;
									height: 0;
								}
								
								&[src$="129.svg"] {
									width: 78px;
									height: 111px;
								}
								
								&[src$="new_129.svg"] {
									width: 77px;
									height: 101px;
								}
								
								&[src$="110.svg"] {
									width: 64px;
									height: 89px;
								}
								
								&[src$="105.svg"] {
									width: 62px;
									height: 89px;
								}
								
								&[src$="97.svg"] {
									width: 60px;
									height: 88px;
								}
								
								&[src$="83.svg"] {
									width: 48px;
									height: 71px;
								}
								
								&[src$="79.svg"] {
									width: 48px;
									height: 71px;
								}
							

							}
						}
						
						ul {
							list-style: none;
							width: 100%;
							padding: .3em 1em; margin: 0px;
							
							li {
								padding: .7em .5em;
								margin: 0;
								line-height: 1.1em;
								border-top: 2px solid rgba(255, 255, 255, 0.5);
								
								&:first-child {
// 									padding-top: 0;
									border-top: none;
								}
								
								&:last-child {
// 									padding-bottom: 0;
								}
								span {
									font-size: .7rem;
									line-height: 1.1em;
								}
							}
						}
					}
				}
				
				> *:last-child {
					border-radius: 0px 0px 3px 3px;
				}
			}
		}
		
		a.supported {
			color: #008945;
		}
		
		a.nonsupported {
			color: $feral-orange;
		}
		
		.unsupported {
			padding: 9px 9px 9px 2rem;
			margin-top: 5px;
			margin-bottom: 5px;
			background-color: #FFF;
			color: #C00;
			border-radius: 3px;
			font-size: 1rem;
			line-height: 1.25em;
			position: relative;
			text-align: left;
			&:before {
				@include fa-icon("\f071");
				display: inline-block;
// 				float: left;
				margin: 0;
				width: 1.5rem;
				text-align: center;
				position: absolute;
				top: .58em;
				left: .3rem;
// 				margin-right: 2px;
			}

		}
		
		table.reqs_table {
			padding: 0px;
			border-collapse: separate;
			border-spacing: 0px 3px;
			width: 100%;

			td, th {
				background-color: #FFF;
				

				&:first-child {
					border-top-left-radius: 3px;
					border-bottom-left-radius: 3px;
				}
	
				&:last-child {
					border-top-right-radius: 3px;
					border-bottom-right-radius: 3px;
				}
			}
		}
		
		#android_reqs {
			h5 {
				margin-top: 1em;
				margin-bottom: -.3em;
				font-weight: 700;
			}
			li {
// 				text-align: center;
// 				font-size: 1.1rem;
			}
		}
		.switch_minimum_note, .mquest_minimum_note {
			display: none;
			@include media-breakpoint-up(md) {
				display: flex;
				opacity: 0;
				height: 1px;
				margin: -1px 0 0 0;
			}
		}
		#switch_reqs {
			max-width: 100%;
// 			background-color: white;
			border-radius: 3px;
			
			.note {
// 				margin-top: 0;
// 				padding-top: 0;
				
				
				&::after {
					content: ' ';
					display: block;
					width: 100%;
					height: 40vw;
					max-height: 160px;
					background: url('/images/switch-controllers.jpg') center center no-repeat transparent;
					background-size: contain;
					@include media-breakpoint-up(md) {
// 						height: 160px;
					}
					@include media-breakpoint-up(xl) {
// 						height: 160px;
					}
				}
			}
			
			p {
				padding: .8em 1em 0em 1em;
// 				font-size: .965rem;
				text-align: left;
				
				span {
					font-size: 1.3em;
					margin-left: -1.65em;
					margin-right: .45em;
					&::before {
						color: #ffcc00;
// 						top: -1px;
						position: relative;
					}
				}
/*
				&::before {
					content: "\f071";
					color: #ffcc00;
					font-size: 1.3em;
					font-family: "Font Awesome 5 Free";
					margin-left: -1.65em;
					margin-right: .45em;
				}
*/
			}
				
			ul {
// 				padding: 0;
				li {
					text-align: left;
// 					font-size: 0.965rem;
				}
			}
		}
	}
	
	h6 {
		text-align: center;
		margin: 10px 5px 5px;
		+ img {
			margin-bottom: 10px;
		}
	}
	
	ul.languages {
		list-style: none;
		padding: 0px; margin: 5px;
		text-align: center;
		
		li {
			display: inline-block;
			padding: 1px 5px; margin: 2px;
			background-color: #FFF;
			border: solid 1px #DDD;
			border-radius: 3px;
		}
	}
	
	p.warning {
		position: relative;
		margin: 10px -10px 20px; 
		padding: .5rem .6rem .5rem 3.3rem;
		background-color: rgba(0,0,0,.7);
		color: #FFF;
		font-size: .8rem;
		border-radius: 5px;
		
		text-align: left;
		span:first-child {
			position: absolute;
			top: .6rem; left: .6rem;
			font-size: 2.1rem;
		}
		
		a {
			color: #FFF;
			text-decoration: underline;
			&:hover {
				color: $feral-orange;
			}
		}
		
		&:nth-of-type(2) {
			span {
				font-size: 1.3em;
				padding-left: .45em;
				&::before {
					color: #ffcc00;
					top: -1px;
					position: relative;
				}
			}
		}
	}
	.gridlegends .rating + p.warning {
			display: none;
		}
	
	.btn {
		display: block;
		width: calc(100% - 0px);
		margin: 10px auto;
	}
	
	.product-legal {
		display: block;
		margin: 0px 10px 10px;
		line-height: 1.1em;
		text-align: justify;
		color: white;
		z-index: 1;
// 		text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.67);
		color: rgba(223, 223, 223, 1);
	}
}

a.ios-button, a.google-button {
	display: inline-block;
	margin: 10px auto;
	max-width: 100%;
	
	img {
		max-width: 100%;
	}
}

@include media-breakpoint-up(md) {
	#faqModal {
		.modal-content {
			font-size: 1.5rem;
			.modal-gradient {
				padding: 1em 1.2em;
			}
			.modal-header {
				font-size: 1em;
				padding: 0;
				h5 {
						
				}
			}
			.modal-body {
				padding: 0 .7em;
				font-size: .7em;
				
				table {
					tr {
						th, td {
							padding: .6em .9em;
						}
					}
				}
				
				h3 {
					font-size: 1.4em;
					margin: 1em -.5em .8em;
				}
				/*
&#faq-i_ios_silent_mode  {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					p {
// 						flex: 1 300%;
// 					flex-direction: column;
						
						&:nth-child(4) {
							order: -1;
								float: right;
							img {
// 								display: flex;
// 								flex: 1 100%;
							}
						}
					}
				}
*/				
				.devices_holder {
					.note {
						font-size: 1.1rem;
					}
					.devices .devices_list {
						width: calc(50% - 4px);
					}
				}
				
				&#faq-ios_silent_mode {
					position: relative;
					min-height: 474px;
					p {
						
						width: 50%;
					}
					p:nth-child(4) {
						position: absolute;
						top: 0;
						right: 0;
						padding-left: 1.2em;
					}
				}
			}
		}
	}
	
	#buyModal {
		.modal-header {
			
			flex-direction: row;
			text-align: center;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: flex-start;
			
			img {
				margin: 10px auto 20px;
			}
			
			h2 {
				margin: 0px -10px;
				color: $feral-orange;
				background-color: rgba(255,255,255,.9);
				border: solid 1px #DDD;
				border-radius: 5px;
				line-height: 1.2em;
				padding: .4em .2em;
				font-size: 2em;
				width: calc(100% - 0px);
				display: flex;
				flex-wrap: wrap;
				background-color: transparent;
				border: none;
				text-align: left;
				padding-left: 15px;
			}
			
			.dlc-requires {
				font-size: .4em;
				padding-left: 5px;
			}
			
			img.rating {
				max-width: 150px;
				height: auto;
				display: flex;
				margin-bottom: -47px;
				margin-left: 20px;
			}
		}
		.shareable {
			text-align: left;
			margin-left: 7px;
		}
		.price-details {
			flex-direction: column;
			color: #212529;
			.platforms {
				display: flex;
				justify-content: center;
// 				width: 200px;
				font-size: .9em;
				padding: .5em 0;
			}
			
			.tbyb_info {
				font-size: 1.6vw;
				line-height: 1.1em;
			    min-width: 100%;
			    @include media-breakpoint-up(lg) {
				    font-size: .5em;
			    }
			    
				em {
					display: block;
					text-transform: uppercase;
					font-weight: 700;
					padding: .3em .6em;
					background-color: white;
					border-radius: .3em;
					font-style: normal;
					margin-top: 0em;
					margin-bottom: .3em;
				}
				small {
					display: block;
					font-weight: 700;
					font-size: .98em;
					color: #212529;
				}
				+ .price {
					margin-top: 0;
				}
			}
			.price {
				margin: 0px auto 0px;
// 				width: 200px;
				
				ins {
					margin-top: -15px;
				}
				del {
// 					top: -.7em;
// 					right: auto;
					position: relative;
				}
			}
		}
		
		.product-details {
			display: flex;
			
			> div {
				width: 81%;
			}
		}
		
		p.warning, .requirements {
			margin: 10px 0px;
		}
		
		.requirements {
			thead th {
				height: 3em;
				padding-top: .8em;
				padding-bottom: 0;
			}
			
			a.handle, .unsupported {
				min-height: 3em;
				padding-top: .85em;
				padding-bottom: .8em;
			}
			
			a.handle {
				&::before {
					top: .7em;
					font-size: 1.3em;
				}
			}
		
			.expandable.devices_holder {
				.note {
					font-size: 1.1rem;
				}
				.devices .devices_list {
					width: calc(50% - 4px);
				}
			}
			
			.unsupported {
				&::before {
					top: .98em;
				}
			}
			
		}
		
		.reqs_selector {
			display: flex;
			align-items: center;
			margin: 15px 5px;
			flex-wrap: wrap;
			h4 {
				white-space: nowrap;
				margin-right: 25px;
			}
			
			nav {
				margin: 0px 15px .5em 0;
				display: flex;
			}
			
			p {
				text-align: left !important;
				margin: 0px 0 .5em;
				
			}
		}
		
		.reqs_table > table {
			tbody {
				th {
					width: 20%;
				}
			
				td {
					width: 40%;
				}
			}
		}
		
		aside {
			position: relative;
			width: 25%;
			max-width: 220px;
			text-align: center;
			padding: 1.5vw 0 1.5vw 1.5vw;
			
			> img {
				margin: 0 auto 20px;
				width: 100%;
				max-width: 200px;
				height: auto;
			}
			
			h6 {
				text-align: left;
				margin: 2em 0 .8em;
			}
			.languages {
				text-align: left;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	#faqModal {
		.modal-content {
			font-size: 1.8rem;
			
		}
	}
	
	#buyModal {
		.modal-header h2 {
			font-size: 3em;
		}
		.reqs_selector {
			flex-wrap: nowrap;
			margin: 5px 5px;
		}
	}
}

@include media-breakpoint-up(xl) {
	#faqModal {
		.modal-content .modal-body .devices_holder .devices .devices_list {
			width: calc(33.33333% - 4px);
		}
	}
	#buyModal {
		.modal-header h2 {
			font-size: 4em;
		}
		
		.requirements {
			
			.expandable.devices_holder {
				
				.devices .devices_list {
					width: calc(33.33333% - 4px);
					
					&.iconed.ipad div + ul {
						max-width: 174px;
					}
				}
			}
			
		}
		aside {
			padding: 1rem 0 1rem 1rem;
		}
	}
}

#contactModal {
	.modal-content {
		font-size: 1rem;
		.modal-title {
			h5 {
				font-size: 2em;
			}
			p {
				margin-bottom: 0px;
				color: $body-color;
			}
		}
		
		.modal-body {
			padding: .5rem; margin: .5rem;
			background-color: rgba(0,0,0,.1);
			border-radius: 3px;
			
			> *:last-child {
				margin-bottom: 0px;
			}
				
			.form-group {
				.col-md-12 {
					padding: 0;
				}
				margin: 0 0 1em;
				label {
					padding: 0;
					+ div {
						padding: 0;
					}
					@include media-breakpoint-up(md) {
						padding-right: 15px;
						+ div {
							padding-left: 15px;
						}
					}
				}
			}
			
			&.modal-success {
				background-color: #FFF;
				justify-content: center;
				text-align: center;
				margin: .5rem;
				padding: 0;
				h3 {
					color: $feral-orange;
					text-align: left;
					padding: .5rem .5rem 1rem;
					font-size: 2em;
					margin-bottom: 0;
				}
				
				> div {
					background-color: rgba(0,0,0,.1);
					padding: 1em;
					border-radius: 3px;
					margin-bottom: 20px;
					text-align: left;
					padding: 0.5rem;
					
					@include media-breakpoint-up(md) {
						padding: 1.3em 1.5em 1.3em;
					}
					p:last-child {
						margin-bottom: 0px;
					}
				}
				
				> .btn-success {
					float: right;
					width: 200px;
					margin: 0.25rem .75rem .75rem;
					
					@include media-breakpoint-up(md) {
						min-width: 200px;
						font-size: 0.8em;
					}
				}
				
			}
			
			h6 {
				font-size: 1.5rem;
				
				+ p {
					margin: 1em .2em 1em;
				}
			}
			
			#contactSummaryEmail, #contactSummaryMessage, #contactSummaryAttachments {
				margin: .4em 0 1em;
				padding: 1em; 
				border-radius: 7px; 
				border: 1px solid #ddd; 
				background-color: rgba(0, 0, 0, 0.05);
				white-space: pre-wrap;
				li {
					margin-left: 1em;
				}
			}
		}
		
		.modal-footer.modal-error {
			position: relative;
			padding-top: 40px;
			
			p {
				position: absolute;
				top: 0px;
				left: .5rem; right: .5rem;
				text-align: center;
				margin: 0px;
				font-weight: bold;
				color: $danger;
				font-size: 95%;
			}
		}
		
		ul {
			padding-left: 1.3rem;
		}
		
		.special {
			background-color: rgba(255, 255, 255, 0.8);
			padding: 1em;
			margin-bottom: 1rem;
			border-radius: 3px;
			
			> *:last-child {
				margin-bottom: 0px;
			}
			
			ul {
				list-style: none;
				li {
					
					position: relative;
					&::before {
						
						@include fa-icon("\f0da");
						position: absolute;
						left: -1em;
						top: .25em;
					}
				}
			}
			
		}
		
		select {
			padding: 3px 5px;
			border: solid 2px #000;
			background-color: #666;
			color: $feral-orange;
			width: 100%;
			border-radius: 4px;
		}
		
		select {
		
			border-radius: 4px;
			background-color: #333;
			padding: 0 36px 0 8px;
			background-image: url('/images/down-arrow-white.svg')/* , url('/images/line-black.svg') */;
			background-repeat: no-repeat;
			background-position: calc(100% - 13px) center/* , calc(100% - 46px) center */;
			background-size: 12px auto/* , 1px auto */;
			position: relative;
			-moz-appearance: none;
		    -webkit-appearance: none;
		    appearance: none;
			height: 34px;
			font-size: 1em;
			font-weight: normal;
			border: 2px solid black;	
			transition: border .2s linear, color .2s linear;
			cursor: pointer;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			color: $feral-orange;
			&:hover {
				border-color: $feral-orange;
				background-image: url('/images/down-arrow-orange.svg')/*,  url('/images/line-orange.svg') */;
				color: white;
			}
			
			
		}
		
		input[type=text], input[type=email], textarea {
			padding: 3px 5px;
			border: solid 2px #000;
			background-color: #FFF;
			color: $feral-orange;
			width: 100%;
			border-radius: 4px;
		}
		
		.input-grouping {
			display: flex;
			width: 100%;
			border: solid 2px #000;
			background-color: #FFF;
			color: #000;
			border-radius: 4px;
			align-items: center;
			
			span {
				display: block;
				font-size: 1.1rem;
				width: 2rem;
				text-align: center;
			}
			
			input {
				width: 100%;
				
				&[type="file"] {
					font-size: .9em;
					&::-webkit-file-upload-button {
						margin: .2em 0;
						padding: .3em .8em;
						font-size: .9em;
						border: 1px solid #b8b8b8;
						background-color: #e9e9e9;
						border-radius: 3px;
						-moz-appearance: none;
					    -webkit-appearance: none;
					    appearance: none;

					}
				}
				
				&:hover {
					&[type="file"]::-webkit-file-upload-button {
						color: $feral-orange;
					}
				}
				
				&:active {
					&[type="file"]::-webkit-file-upload-button {
						color: $feral-orange;
						background-color: #333;
						border-color: black;
					}
				}
			}
			
			button {
				display: block;
				width: 2rem;
				font-size: 1.1rem;
				border: none;
				background: none;
				transition: color .2s linear;
				&:hover {
					color: $feral-orange;
				}
			}
		}
		
		.row {
			align-items: center;
		}
		
		label {
			font-weight: bold;
			
			&[for="contactModalMoreFiles"] + div .input-grouping {
				
			}
		}
		
		.platforms {
			margin-top: -2px;
			label {
				margin: 0px;
				background-color: #333;
				border: solid 2px #000;
				color: #FFF;
				border-radius: 100%;
				font-size: 1.45rem;
				width: 2.8rem; height: 2.8rem;
				line-height: 1.4rem;
				text-align: center;
				font-weight: normal;
				transition: border .2s linear;
				cursor: pointer;
				position: relative;
				z-index: 1;
				&::before {
					line-height: 1.5em;
					text-shadow: $small-black-text-shadow;
					cursor: pointer;

				}
				
				&:hover {
					border-color: $feral-orange;
				}
				
				&:active {
					transform: scale(0.93);
					box-shadow: inset 0px 0px 15px 0px black;
				}
				
				&.active {
					background-color: #FFF;
					color: $feral-orange;
					pointer-events: none;
					border: solid 2px #000;
					&::before {
						text-shadow: none;
					}
					&::after {
						content: ' ';
						display: block;
						position: absolute;
						top: -5px;
						left: -5px;
						right: -5px; 
						bottom: -5px;
						border: 2px solid $feral-orange;
						border-radius: 100%;
// 						background-color: black;
						box-shadow: inset 0 0 10px black;
						pointer-events: none;
					}
					&.icon-linux::before {
						content: '\e80c';
					}
				}
				
				input {
					display: none;
				}
			}
		}
		
		.contact-footnote {
			font-size: .8em;
		}
		
		.modal-footer {
			justify-content: space-between;
			
			button {
				min-width: 120px;
				&.loading::after {
					font-size: 1.4em;
				}
			//	&:after {
//					content: attr(data-create);
//				}
			}
		}
		
		.modal-summary {
			display: none;
			max-width: 100%;
		}
		
		&.confirming {
			//.modal-footer button:after {
//				content: attr(data-confirm);
//			}
		}
		
		.invalid small {
	// 		display: none;
			position: absolute;
			bottom: calc(100% + 3px); 
			left: 50%;
			transform: translateX(-50%);
			padding: 3px 5px !important;
			background-color: lighten($danger, 30%);
			width: auto;
			min-width: 250px;
			max-width: 100%;
			border-radius: 5px;
			text-align: center;
			color: darken($danger, 20%);
			white-space: normal !important;
			border: solid 2px darken($danger, 20%);
			box-shadow: 1px 1px 2px rgba(0,0,0,.4);
			
			&:before {
				position: absolute;
				display: block;
				content: '';
				bottom: -10px; left: calc(50% - 10px);
				background-color: darken($danger, 20%);
				width: 20px; height: 10px;
				-webkit-clip-path: polygon(0px 0px, 100% 0px, 50% 100%);
				clip-path: polygon(0px 0px, 100% 0px, 50% 100%);
			}
			
			&:after {
				position: absolute;
				content: '';
				display: block;
				bottom: -8px; left: calc(50% - 8px);
				background-color: lighten($danger, 30%);
				width: 16px; height: 10px;
				-webkit-clip-path: polygon(0px 0px, 100% 0px, 100% 2px, 50% 100%, 0px 2px);
				clip-path: polygon(0px 0px, 100% 0px, 100% 2px, 50% 100%, 0px 2px);
			}
		}
	}
}

@include media-breakpoint-up(md) {
	#contactModal, #checkoutModal {
		.modal-content {
			font-size: 1.5em;
			.modal-title {
				h5 {
					font-size: 2em;
				}
				p {
					margin-bottom: 0px;
					color: $body-color;
				}
			}
			.modal-body {
				padding: 1em 1.5em 0; 
// 				margin: 1em;
			}
			
			.form-group.row label {
				margin: 0px;
			}
			
			.special {
				padding: 1em;
				margin-bottom: 1.5em;
				margin-top: 1.5em;
				
				ul{
					padding-left: 1.5em;
				}
			}
			input + .special {
				margin-top: 0em;
			}
			.modal-footer {
				button {
					min-width: 200px;
					font-size: .8em;
				}
			}
		}
		
	}
}

@include media-breakpoint-up(md) {
	#contactModal {
		.modal-content {
			
			.modal-body {
				padding: 1.3em 1.5em 1.3em; 
			}
			
		}
		
	}
}



#screenshotsModal {
	text-align: center;
	.modal-dialog {
		border-color: #000000;
		background-color: #404040;
	    width: 95%;
	    max-width: 960px;
	    position: absolute;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-52%, -50%);
		transform: translate(-52%, -50%);
		.modal-content {
			position: relative;
			justify-content: center;
			border: none;
			border-radius: 4px;
			overflow: hidden;
			.prev {
				position: absolute;
				z-index: 10;
				top: 0;
				bottom: 0;
				left: 0;
				width: 33.333%;
				height: 94.55%;
				display: flex;
				justify-content: stretch;
				align-items: center;
				text-decoration: none;
				opacity: .4;
				color: white;
				text-shadow: 0px 0px .3em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6);
				transition: opacity .2s linear;
				justify-content: flex-start;
				&:hover {
					text-decoration: none;
					opacity: 1;
				}
				span {
					display: flex;
					justify-content: stretch;
					padding: 5%;
					font-size: 5em;
					&::before {
						display: flex;
					}
				}
				
			}
			img {
				max-width: 100%;
				max-height: 100vh;
				display: inline-block;
				align-self: flex-start;
				border-radius: 3px 3px 0px 0px;
				+ .prev {
					right: 0;
					left: auto;
					justify-content: flex-end;
					span {
						
					}
				}
			}
			p {
				font-size: 1rem;
				color: white;
				padding: 1em .5em 0;
				&:empty {
					display: none;
				}
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	#screenshotsModal {
		.modal-dialog {
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
}
@include media-breakpoint-up(md) {
	#screenshotsModal {
		.modal-dialog {
			margin: 0 auto;
		}
	}
}


.fadedImage {
	position: absolute;
	opacity: .5;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
}