#newsletters-content {
	display: flex;
	flex-wrap: wrap;
	&::before {
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.9+0,0.4+36,0+56 */
background: -moz-linear-gradient(-45deg,  rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.4) 36%, rgba(0,0,0,0) 56%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.4) 36%,rgba(0,0,0,0) 56%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.4) 36%,rgba(0,0,0,0) 56%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */



		
		height: 100%;
	}
	
	#jarallax-container-1 {
		div {
// 			background-position: 62% 50% !important;
		}
	}
	
	.row {
		@include fade-in-up(2s, 0s);
		width: 100%;
	}
	header {
		position: relative;
		width: 100%;
		h3 {
			margin: 1.6rem 0 1rem;
			text-align: center;
			text-transform: uppercase;
			font-size: 1.3rem;
			text-shadow: $small-black-text-shadow;
		}
	}
	
	.panel {
		margin: 1rem 20px;
		
		h4 {
			font-size: 1.2rem;
			text-align: center;
			font-weight: normal;
			text-shadow: $small-black-text-shadow;
			
		}
		
		
		
		p {
			text-align: center;
			padding-top: 1rem;
			text-shadow: $small-black-text-shadow;
			span:first-child {
				margin-left: calc(10px + .5vw);
			}
		}
		
		.panel-buttons {
			display: flex;
			
			.btn {
				width: 100%;
				
				&:nth-child(2) {
					margin-left: 1vw;
				}
			}
		}
	}
	
	
#stay-up-to-date {
	position: relative;
	margin-bottom: 10px;
	width: 100%;
	text-align: center;
	h6 {
		font-size: 1em;
	}
/*
	&:before {
		content: 'under revision';
		display: block;
		position: absolute;
		top: 0; 
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 9;
		background: rgba(255, 0, 255, 0.6);
		color: white;
		font-weight: bold;
		font-size: 3rem;
		text-transform: uppercase;
		padding-top: 30%;
		text-align: center;
		opacity: 1;
		text-shadow: $small-black-text-shadow;
	}
*/
	
/*
	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0px; right: 0px;
		@include gradient-x(#fd7401, #e4ac59);
		height: 10px;
	}
*/
	
	input {
		border: solid 2px #FFF;
		border-radius: 5px;
		
		&[type=email] {
			margin: 5px 20px 15px; padding: 7px 20px;
			@include gradient-x(#e4ac59, #fd7401);
			font-size: 1.3rem;
			width: calc(100% - 40px);
			
			&::placeholder {
				color: white;
				opacity: .6;
			}
			
		}
		
		&[type=number] {
			background-color: #333;
			width: 100%;
			text-align: center;
			color: $feral-orange;
			margin: 0px 2px;
			/* Hide arrows on Chrome, Safari, Edge, Opera */
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
			  -webkit-appearance: none;
			  margin: 0;
			}
			
			/* Hide arrows on Firefox */
			&[type=number] {
			  -moz-appearance: textfield;
			}
		}
		
		
	}
	
	.panel {
		display: flex;
		align-items: center;
		text-align: left;
		text-shadow: $small-black-text-shadow;
		&.newsletter {
			flex-wrap: wrap;
			
			> h6 {
				width: 100%;
			}
			
			label {
				flex-wrap: wrap;
				
				span {
					width: calc(100% - 30px);
					span {
						padding-left: 0;
						width: 100%;
						font-weight: normal;
						display: inline-block;
					}
				}
				
				div {
					align-self: flex-start;
					font-size: 1em;
					&::before {
						line-height: 1.4em;
					}
				}
			}
		}
		
		&.platforms {
			flex-wrap: wrap;
			
			> h6 {
				width: 100%;
			}
		}
		
		&.age {
			
			flex-wrap: wrap;
			justify-content: center; 
			> h6 {
				margin-bottom: 0px;
				margin-right: 10px;
				display: flex;
				flex: 1 1 20%;
			}
			
			input {
				display: flex;
				flex: 1 1 20%;
				&:last-of-type {
					flex: 2 2 25%;
				}
			}
			
			.btn {
				flex: 0 0 50%;
				margin-top: 2em;
			}
		}
	}
	
	label {
		font-weight: bold;
		display: flex;
		align-items: center;
		margin: 0px;
		width: 49%;
		margin-right: 1%;
		cursor: pointer;
		
		&:last-child:nth-child(even) {
			width: 100%;
		}
		
		&:hover {
			> span {text-decoration: underline;}
		}
		
		div {
// 			content: '';
			display: block;
// 			border: solid 2px #FFF;
// 			border-radius: 3px;
			width: 20px; height: 20px;
			margin-right: 10px;
			
			&::before {
				content: "\f0c8";
				font-family: "Font Awesome 5 Free";
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				font-weight: 400;
			}
		}
		
		input {
			display: none;
			
			&:checked + div {
				
				&::before {
					content: "\f14a";
				}
				
			}
		}
		
		
	}
// 	footer {
// 			justify-content: center;
// 			text-align: center;

		.btn {
			margin: .5em auto 2em;
		}
// 	}
}




}

@include media-breakpoint-up(sm) {
	
	
	#jarallax-container-1 {
		div {
// 			background-position: 50% 50% !important;
		}
	}
}

@include media-breakpoint-between(md, xl) {
	
	
	#jarallax-container-1 {
		div {
// 			background-position: left 50% !important;
		}
	}
}

@include media-breakpoint-up(md) {
	
	#newsletters-content {
		font-size: 1.4em;
		.row {
			width: 700px;
		}
		#stay-up-to-date {
			header {
				h3 {
	// 				margin: 1.6rem 0 1rem;
	// 				text-align: center;
	// 				text-transform: uppercase;
					font-size: 1.8em;
					text-align: left;
				}
			}
			.panel {
				padding-bottom: 1em;
				h4 {
					text-align: left;
					padding-left: 12px;
					margin-bottom: 1em;
				}
				p {
	// 				font-size: 1.6vw;
				}
				
				&.newsletter {
	// 				width: 30%;
				
				
					label {
						
						span span {
							padding-left: .5em;
							width: auto;
							font-weight: normal;
						}
						
						div {
							&:before {
								line-height: 1.6em;
							}
						}
					}
			
				}
				&.platforms {
	// 				width: 60%;
	
					label:last-child {
						width: 49%;
					}
				}
				
				
			}
			
			
		}

		
		
	}
}


@include media-breakpoint-up(lg) {
	#newsletters-content {
		
		#stay-up-to-date {
			margin-bottom: 2em;
			.panel {
				
				h4 {
					font-size: 1.8vw;
				}
				p {
					font-size: 1.6vw;
				}
				.panel-buttons {
					
					padding: 0 5vw;
					.btn {
						
						
					}
				}
				
				&.age {
	// 				width: 400px;
					display: flex;
// 					margin-bottom: 2em;
					flex-wrap: nowrap;
// 					justify-content: space-between;
					
					h6 {
						flex: 1 1 auto;
					}
				
					input {
						display: flex;
						flex: 1 1 10%;
						&:last-of-type {
							flex: 2 2 15%;
							margin-right: 5%;
						}
					}
					
					.btn {
						flex: 2 2 20%;
						margin: 0;
					}
				
				}
				
				
			}
		
		}
	}
}


@include media-breakpoint-up(xl) {
	#newsletters-content {
		.panel {
			
			h4 {
				font-size: 1.5rem;
			}
			p {
				font-size: 1.3rem;
			}
			.panel-buttons {
				
// 				padding: 0 5vw;
				.btn {
					
					
				}
			}
		}
	}
}
