& {
	background-size: cover;
	position: relative;
// 	display: flex;	
	align-self: flex-start;
	background-position: center top;
}

#minisite-content {
	display: flex;
	flex-direction: column;
	h1 {
		margin: 110px auto 1em;
		text-align: center;
		flex: 1 1 10%;
		align-self: flex-start;
		align-items: center;
		img {
			max-width: 90%;
			margin: 0 auto;
			align-self: center;
			display: flex;
		}
	}
	
	h2 {
		text-shadow: 0px 0px .3em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6); 
		text-align: center;
		text-transform: uppercase;
		margin: 0 auto 1em;
		max-width: 84%;
		flex: 1 1 84%;
		
		font-size: 1.4em;
		strong {
			font-weight: 500;
			white-space: nowrap;
			.lowercase-mac {
				text-transform: lowercase;
			}
		}
	}
	
	form {
		align-self: flex-start;
		text-align: center;
		margin: 1.5em auto .7em;
		font-size: 5vw;
		text-transform: uppercase;
		max-width: 94%;
		flex: 1 0 30%;
		flex-direction: row;
		flex-wrap: wrap;
		display: flex;
		justify-content: center;
		z-index: 10;
		@include media-breakpoint-up(sm) {
			font-size: 1.3em;
			
		}
		#date-options {
			display: flex;
			flex: 1 1 100%;
			align-content: center;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 auto;
			label {
				display: flex;
				align-content: center;
				align-items: center;
				margin-left: .5em;
				flex: 0 1 100%;
				justify-content: space-between;
				font-weight: bold;
				text-shadow: 0px 0px .3em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6);
				@include media-breakpoint-up(md) {
					flex: 0 1 20%;
					justify-content: center;
					
				}
				
				select {
					display: flex;
					flex: 0 0 65%;
					line-height: 1.3em;
					font-weight: normal;
					border-radius: 3px;
					margin: 0 .5em;
					padding: 0 calc(1em + 17px) 0 1em;
					background-image: url('/images/down-arrow.svg');
					background-repeat: no-repeat;
					background-position: calc(100% - 17px) center;
					background-size: 12px auto;
					background-color: rgba(255, 255, 255, 0.6);
					position: relative;
					-moz-appearance: none;
				    -webkit-appearance: none;
				    appearance: none;
					height: 48px;
					border: 2px solid black;	
					transition: border .2s linear;
					cursor: pointer;
					will-change: background-color;
					transition: background-color .2s linear;
					text-transform: uppercase;
					color: black;
					&:hover {
	// 					border-color: $feral-orange;
	// 					background-image: url('/images/down-arrow-orange.svg')/*,  url('/images/line-orange.svg') */;
						background-color: white;
					}
					
					@include media-breakpoint-up(md) {
						flex: 1 1 50%;
						
					}
				}
			}
		}
			
		button {
			display: flex;
			flex: 0 1 auto;
			padding: 0 1em;
			margin: 1em auto 0;
			background: rgba(0, 0, 0, 0.6);
			-moz-appearance: none;
		    -webkit-appearance: none;
		    appearance: none;
			height: 48px;
			font-size: 1.1em;
			border: 2px solid black;	
			border-radius: 3px;
			color: white;
			will-change: background-color, transform;
			transition: background-color .2s linear, transform .2s linear;
			text-transform: uppercase;
		    align-items: center;
			&:hover {
				background: black;
				transform: scale(1.05);
			}
		}
	}
	@include media-breakpoint-up(md) {
		h1 {
			margin-top: 120px;
		}
	}

	
	
	footer {
		#minisite-legal {
			
			p {
				@include media-breakpoint-up(md) {
					flex: 0 0 100% !important;
					text-align: center;
				}
			}
		}
		a {
			bottom: 0em !important;
		}

	}
}


#access-restricted {
	font-size: 1.4em;
	text-align: center;
	box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.7);
	background-color: rgba(0, 0, 0, 0.4);
	display: inline-block;
	padding: 1.5em;
	color: white !important;
	text-shadow: 0px 0px .3em rgba(0, 0, 0, 0.6), 0px 0px .1em rgba(0, 0, 0, 0.6), 0px 0px .5em rgba(0, 0, 0, 0.6) !important;  
}