$accounts-violet: #5c3c7a;
$label-background: #e7dfed;
.row {
// 	@include fade-in-up(2s, 2s);
	margin: 0 auto;
	max-width: 1200px;
}
@include media-breakpoint-down(xs) {
	#top-header #jarallax-container-0 {
		div {
				height: 120% !important;
				background-position: right 35% !important;
			
		}
	}
	#jarallax-container-1 {
		div {
// 				height: 120% !important;
				background-position: 57% 50% !important;
			
		}
	}
}

 > section:first-of-type {
	
	&:before {
		
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.8+0,1+50,0.2+50,0.8+100 */
background: -moz-linear-gradient(top,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.2) 50%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */

		width: 100%; height: 100%;
	}
}

.alert {
	margin: 20px;
	border: 3px solid $success;
	color: #FFF;
	font-weight: bold;
	background-color: rgba($success, 0.5);
	
	.close {
		min-width: auto !important;
		height: auto !important;
		background: none !important;
		border: none !important;
	}
}

#login-content, #signup-content, #forgotten-content {
	form {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		margin: 0px;
	
		> .form-buttons {
			margin: 0px 20px 2em;
			width: calc(100% - 40px);

			.agreement {
				color: #afafaf;
				font-size: .8rem;
				
				a {
					color: lighten( $accounts-violet, 18% );
				}
			}
		}
	}
	
	aside {
		flex: 0 0 auto;
		padding: 40px 20px 10px;
		margin: 0px 20px;
		background-color: rgba(0,0,0,.6);
		text-align: center;
		-webkit-clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 30px), calc(100% - 30px) 100%, 10px 100%, 0px calc(100% - 10px));
		clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 30px), calc(100% - 30px) 100%, 10px 100%, 0px calc(100% - 10px));
		
		img {
// 			float: left;
			width: 20vw;
			height: auto;
			margin: -1em auto 1.2em;
			
		}
		
		h5 {
// 			margin: 1.2em 0px 0;
			color: $feral-orange;
			
			
		}
		
		@include media-breakpoint-only(sm) {
			img {
				width: 20%;
				margin: -1em 2em 2em 1em;
				float: left;
			}
			
			h5, p {
				text-align: left;
			}
		}
		
	}
	
	
}



.panel {
	@include panel(rgba(0,0,0,.7));
	
	li strong {
		color: lighten( $accounts-violet, 18% );
	}
}

#login-whynot, #faqs-content, #terms-content {
	display: flex;
	flex-direction: column;
	
	.row {
		justify-content: stretch;
	}
	
	.panel {
		margin: 5px 10px; padding: 10px 20px;
		text-align: center;
// 		width: 100%;
		position: relative;
		transition: background-color .2s linear;
		flex-grow: 1;
		
		&::before {
			background-color: #4d4d4d;
			transition: background-color .2s linear;
		}
		
		&:first-child {
			margin-top: 20px;
		}
		
		&:last-child {
			margin-bottom: 20px;
		}
		
		&:hover {
			background-color: black;
			&::before {
				background-color: #AAA;
			}
		}
		
		h3 {
			margin: 0px;
			font-weight: normal;
			font-size: 1.2rem;
			
			a {
				display: block;
				
				&::before {
					content: ' ';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
				}
				
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
#login-whynot {
	padding: 35px 25px 50px;
	.panel {
		width: 50%;
		padding: 30px;
		margin: 15px 10px;
		&:first-child {
			margin: 15px 10px;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
			
			margin: 5px 0;
			&:first-child {
				margin: 5px 0;
			}
		}
	}
}
#terms-content {
	.panel {
		&:first-child:hover {
			background-color: rgba(0, 0, 0, 0.7);
			&::before {
				background-color: #4d4d4d;
			}
		}
		&:nth-last-child(3):nth-child(2) {
			h3 a { 
				display: inline;
			}
		}
	}
}
#signup-whynot {
	
	.row {
		align-content: center;
	}
	
	h3 {
		margin: 1.8em 30px .2em;
		position: relative;
		color: $feral-orange;
		text-align: center;
		flex-grow: 1;
		text-shadow: $small-black-text-shadow, $small-black-text-shadow, $small-black-text-shadow, $small-black-text-shadow;
	}
	
	.panel {
		flex-grow: 1;
		margin: 1em 20px; padding: 20px;
		text-align: center;
		font-size: 1rem;
		text-shadow: $small-black-text-shadow, $small-black-text-shadow;
		&::before {
			background-color: #4d4d4d;
		}
		h4 {
			color: $feral-orange;
			margin: .3em 0 .8em;
		}
		
		h5 {
			color: $feral-orange;
			margin-bottom: 1em;
		}
		
		&:after {
			display: block;
			content: '';
			width: 100%; height: 1px;
			clear: both;
		}
		
		> img {
			width: 100%;
			border: solid 10px #000;
			box-shadow: 0px 0px 1px 1px #82609e;
			margin-bottom: 1em;
		}
		
		p {
// 			color: #82609e;
			margin-left: 10px;
			margin-bottom: 40px;
			color: lighten( $accounts-violet, 18% );
			
			&:last-child {
				margin-bottom: 10px;
			}
		}
		
		.icons {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			a {
				width: calc(25% - 10px);
				padding-top: calc(25% - 10px);
				margin: 5px;
				position: relative;
				
				img {
					position: absolute;
					top: 0px; left: 0px;
					width: 100%; height: 100%;
				}
			}
			
			+ h5 {
				margin-top: 1.5em;
				text-align: center;
			}
		}
		
		+ a {
			padding: 15px 50px;
			margin: 1em auto 2em;
			font-size: 1.2rem;
			white-space: nowrap;
			font-weight: bold;
			-webkit-clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 30px), calc(100% - 30px) 100%, 10px 100%, 0px calc(100% - 10px));
			clip-path: polygon(0px 20px, 40px 0px, calc(100% - 10px) 0px, 100% 10px, 100% calc(100% - 15px), calc(100% - 25px) 100%, 10px 100%, 0px calc(100% - 10px));
			transition: background-color .2s linear, color .2s linear;
			color: white;
			background-color: lighten( $accounts-violet, 0% );
			&:hover {
				background-color: black;
				color: $feral-orange;
			}
		}
	
	}
}


/*
@include media-breakpoint-up(sm) {
	#signup-whynot .panel {
		
		.icons {
			a {
				width: calc(20% - 20px);
				padding-top: calc(20% - 20px);
				margin: 10px;
			}
		}
	}
}
*/


@include media-breakpoint-up(md) {
	#signup-whynot .panel {
		padding: 40px;
		text-align: left;
		font-size: 1.8vw;
		
		h4 {
			font-size: 2.2vw;
		}
		
		h5 {
			font-size: 1.9vw;
		}

		> img {
			width: 400px;
			
			margin-bottom: 0;
		}
		
		
		&:first-of-type {
			> img {
				
				margin-bottom: 1.5em;
			}
		}
		
		&:nth-child(odd) > img {
			float: left;
			margin-right: 5%;
		}
		
		&:nth-child(even) > img {
			float: right;
			margin-left: 5%;
		}
		
		
		.icons {
			clear: right;
			a {
				width: calc(12.5% - 10px);
				padding-top: calc(12.5% - 10px);
				margin: 5px;
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	#signup-whynot .panel {
		font-size: 22px;
		
		h4 {
			font-size: 2.2vw;
		}
		
		h5 {
			font-size: 1.9vw;
		}
	}
}

.form-contents {
	margin: 0px 20px;
	text-shadow: $small-black-text-shadow, $small-black-text-shadow, $small-black-text-shadow, $small-black-text-shadow;
	
	h3 {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 30px 0px;
		color: $feral-orange;
		
		&#signup-top, &#login-top {
			margin-top: 0;
			padding-top: 30px;
			@include media-breakpoint-up(sm) {
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-between;
			}
		}
		
		span {
			display: inline-block;
			padding: 15px 30px;
			margin-top: 10px;
			margin-left: auto;
			margin-right: auto;
			font-size: 1.2rem;
			font-weight: bold;
			white-space: nowrap;
			border: none;
			background-color: #000;
			color: $accounts-violet;
			color: lighten( $accounts-violet, 18% );
			-webkit-clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 30px), calc(100% - 30px) 100%, 10px 100%, 0px calc(100% - 10px));
			clip-path: polygon(0px 20px, 40px 0px, calc(100% - 10px) 0px, 100% 10px, 100% calc(100% - 15px), calc(100% - 25px) 100%, 10px 100%, 0px calc(100% - 10px));
			text-shadow: none;
			
			a {
				transition: color .2s linear;
				&:hover {
					color: white;
				}
			}
			
			@include media-breakpoint-up(sm) {
				margin-right: 0;
			}
		}
	}
	
	> p {
		margin: 0px 20px 20px;
		text-align: center;
		@include media-breakpoint-up(sm) {
			text-align: left;
		}
	}
	
	.inputs-group > div {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		text-shadow: none;
		
		label {
			position: relative;
			z-index: 1;
		}
		
		.helper {
			margin-top: -25px;
			margin-bottom: 20px;
			padding: 25px 20px 20px;
			display: block;
			background-color: rgba(0,0,0,.8);
			color: #82609e;
			border-radius: 0px 0px 3px 3px;
			z-index: 0;
			position: relative;
			border: solid 2px #362542;
		}
	}
	
	
}

	
	
.external-login {
	text-align: center;
	margin-top: 1em;
	margin-bottom: 2em;
	position: relative;
	padding-top: 1em;
	font-size: 1.2em;
	width: 100%;
		
	> p {
		z-index: 3;
		position: absolute;
		display: inline-block;
		width: auto;
		min-width: 1.8em;
		height: 1.8em;
		line-height: 1.6em;
		top: 0.1em;
		left: calc(50% - .9em);
		border: 2px solid #333;
		background: $black;
		border-radius: .9em;
		padding: 0 .4em;
		filter: none;
		text-shadow: none;

	}
	
	#external-form-holder {
		display: flex;
		position: relative;
		z-index: 1;
		min-width: 100%;
		@include panel(rgba(0,0,0,.7));
		flex-wrap: wrap;
		flex-direction: row;
		padding: 30px 30px 26px;
		text-align: center;
		align-content: center;
		justify-content: center;
		@include media-breakpoint-up(md) {
// 				flex-wrap: nowrap;
		}
		
		form {
			flex-direction: row;
		}
		
		p {
			min-width: 100%;
		}
		
		div#googleid-signin, div#appleid-signin {
			display: flex;
			max-width: 64px;
			&:hover {
				filter: drop-shadow(0 0 0.75rem $feral-orange);
			}
		}
		
		div#googleid-signin {
			width: 64px;
			height: 64px;
			margin: 0px 20px 1px 0;
			border: 1px solid black;
			border-radius: 20%;
			overflow: hidden;
			div:empty {
// 					display: none;
			}
			> div {
				margin: -3px auto auto -3px;
				transform: scale(170%);
				transform-origin: left top;
			}
		}	
	}
}

@include media-breakpoint-up(md) {
	.form-contents {
		h3 {
			span {
				margin-right: 0;
			}
		}
	}
}


/*


		
		input[type=file], input[type=text], input[type=email], input[type=password], select {
			width: 300px;
		}
	
	input[type=text], input[type=email], input[type=password], select {
		padding: 3px 5px;
		width: 100%;
		border: solid 2px rgba(0,0,0,.5);
		border-radius: 3px;
		background-color: rgba(255,255,255,.4);
		color: $feral-orange;
	}
	
	select {
		height: calc(1rem + 18px);
	}

*/

.form-contents, #settings-contents, #profile-unverified {
	label {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 0px; margin-bottom: 20px;
		background-color: $label-background;
		border: solid 2px darken($label-background, 70%);
		color: #111;
// 		font-size: .8rem;
		border-radius: 5px;
		
		> div {
			display: flex;
			align-items: center;
		}
		
		input, select {
			padding: 5px 10px;
			height: auto; width: 100%;
			border: none;
			background-color: transparent;
			flex: 1 1 auto;
		    outline: none;
		    -webkit-box-shadow: none;
		    -moz-box-shadow: none;
		    box-shadow: none;
		}
		
		input[type=number] {
			text-align: center;
			box-sizing: border-box;
			width: 3em;
			padding: 5px 5%;
			&:last-child {
				width: 4.5em;
			}
		}
		//Remove Arrows
		/* Chrome, Safari, Edge, Opera */
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
		  -webkit-appearance: none;
		  margin: 0;
		}
		
		/* Firefox */
		input[type=number] {
		  -moz-appearance: textfield;
		}
		
		select {
			text-align-last: center;
		}
		
		span {
			padding: 5px 10px;
			font-weight: bold;
			white-space: nowrap;
			text-shadow: none;
		}
	}
	
	.inputs-group > div.newsletter {
		display: flex;
		flex-wrap: wrap;
		@include media-breakpoint-up(md) {
			flex-wrap: nowrap;
		}
		flex-direction: row;
		margin-bottom: 20px;
		padding: 0 15px 0;
		background-color: $label-background;
		border: solid 2px darken($label-background, 70%);
		border-radius: 5px;
		// color: #82609e;
		
		> div {
			display: flex;
			flex-wrap: wrap;
			flex: 0 0 100%;
			margin: 10px 0;
			@include media-breakpoint-up(md) {
				flex: 1 1 65%;
			}
			justify-content: space-between;
			&:first-child {
// 				margin-right: 5%;
				@include media-breakpoint-up(md) {
					flex: 1 1 20%;
				}
			}
			&:last-child {
				
				width: 46% !important;
				@include media-breakpoint-up(sm) {
					width: auto !important;
				}
			}
		}
		h6 {
			color: #111;
			margin: 0;
// 			@include media-breakpoint-up(sm) {
				flex: 0 0 100%;
// 			}
		}
		
		label {
			margin: 0.5rem 10px 0 0;
			padding-left: 27px;
			display: inline-flex;
			flex-wrap: nowrap;
			align-items: flex-start;
			width: auto !important;
			border: none;
			background: transparent;
			font-weight: bold;
			&:before {
				border-color: #82609e;
				position: absolute;
				left: 0;
			}
		}
	}
}

label.error {
	position: relative;
	border: solid 2px darken($danger, 20%) !important;
	
	&:after {
		@include fa-icon("\f06a");
		position: absolute;
		top: 4px; right: 7px;
		display: block;
		font-size: 150%;
		color: darken($danger, 20%);
		cursor: help;
		text-shadow: none;
	}
	
	.error_message {
		display: none;
		position: absolute;
		bottom: calc(100% + 10px); 
		left: 50%;
		transform: translateX(-50%);
		padding: 3px 5px !important;
		background-color: $label-background;
		width: auto;
		min-width: 250px;
		max-width: 100%;
		border-radius: 5px;
		text-align: center;
		color: darken($danger, 20%);
		white-space: normal !important;
		border: solid 2px darken($danger, 20%);
		box-shadow: 1px 1px 2px rgba(0,0,0,.4);
		
		&:before {
			position: absolute;
			display: block;
			content: '';
			bottom: -10px; left: calc(50% - 10px);
			background-color: darken($danger, 20%);
			width: 20px; height: 10px;
			-webkit-clip-path: polygon(0px 0px, 100% 0px, 50% 100%);
			clip-path: polygon(0px 0px, 100% 0px, 50% 100%);
		}
		
		&:after {
			position: absolute;
			content: '';
			display: block;
			bottom: -8px; left: calc(50% - 8px);
			background-color: $label-background;
			width: 16px; height: 10px;
			-webkit-clip-path: polygon(0px 0px, 100% 0px, 100% 2px, 50% 100%, 0px 2px);
			clip-path: polygon(0px 0px, 100% 0px, 100% 2px, 50% 100%, 0px 2px);
		}
	}
	
	&:hover, &:focus, &.focus {
		.error_message {
			display: block;
		}
	}
	
	input[name="nickname"] + span.error_message {
		width: 100%;
	}
}

.form-contents p.success.floating {
	border: 1px solid $success;
	background-color: darken($success, 20%);
	padding: 1em .5em;
	border-radius: 5px;
	text-shadow: none;
	text-align: center;
}

.form-contents label + p.success {
	border: 1px solid $success;
	border-top: none;
	background-color: darken($success, 20%);
	padding: 1em .5em;
	border-radius: 0 0 5px 5px;
	text-shadow: none;
	margin-top: -20px;
	text-align: center;
}

@include media-breakpoint-up(sm) {
	.form-contents, #settings-contents, #profile-unverified {
	    label {
	   		flex-direction: row;
	
	   		input {
// 	   			padding: 10px 8%;
	   			width: auto;
				
				&[type=file] {
					padding: 8px 20px 7px;
				}
	   		}
			
			select {
				margin-right: 10px;
				height: calc(1rem + 20px);
				text-align-last: unset;
			}
	
	   		span {
	   			padding: 10px 20px;
	   		}
	   	}
	}
	
	label.error {
		&:after {
// 			position: static;
			top: .4em; right: .4em;
		}
	}
}

@include media-breakpoint-up(md) {
	#signup-content .form-contents {
		label {
			flex-direction: column;
			input:not([type=number]), span {
				width: 100%;
			}
		}
	}
	
	#settings-contents label, #profile-unverified label {
		display: inline-flex;
		width: 450px;
	}
}

@include media-breakpoint-up(lg) {
	#signup-content .form-contents {
		label {
			flex-direction: row;
			input:not([type=number]), span {
				width: auto;
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	#login-content .form-contents {
		label {
			justify-content: space-between;
			input {
				max-width: 70%;
			}
		}
		
	}
}

.form-buttons {
	margin: 0px 20px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	
	p {
		margin: 0px;
	}
	
	button {
		padding: 15px 20px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		font-weight: bold;
		border: none;
		background-color: $accounts-violet;
		color: #FFF;
		-webkit-clip-path: polygon(0px 0px, 100% 0px, 100% calc(100% - 30px), calc(100% - 30px) 100%, 10px 100%, 0px calc(100% - 10px));
		clip-path: polygon(0px 20px, 40px 0px, calc(100% - 10px) 0px, 100% 10px, 100% calc(100% - 15px), calc(100% - 25px) 100%, 10px 100%, 0px calc(100% - 10px));
		transition: background-color .2s linear, color .2s linear;
		
		&:hover {
			background-color: black;
			color: $feral-orange;
		}
	}
}

#forgotten-content .form-contents {
	.inputs-group label:first-child {
		width: 100%;
		margin: 0px 0px 20px;
	}
	.form-buttons {
		justify-content: flex-end;
	}
}

@include media-breakpoint-up(md) {
	.external-login {
		margin-left: 23vw;
		width: calc(100% - 23vw);
	}
	
	#login-content, #signup-content, #forgotten-content {
		form {
			flex-direction: row;
			align-items: flex-start;
		}
	
		aside {
			padding: 65px 2vw 0;
			margin: -20px 3vw 5vw 0;
			width: 20vw;
			
			img {
				max-width: 100%;
				width: 100%;
			}
		
		}
	}
	
	.form-contents {
		width: calc(100% - 23vw);
		margin: 0;
		h3 {
			flex-direction: row;
		
			span {
				margin-top: 0px;
			}
		}
		
		.inputs-group > div {
			flex-direction: row;
		
			label, span.helper {
				width: calc(50% - 10px);
				
				&.full {
					width: 100%;
				}
			
				&.left {
					margin-right: 20px;
				}
			}
		}
	}
	
	.form-buttons {
		margin-top: 30px;
		flex-direction: row;
		justify-content: space-between;
		p {
			order: 1;
		}
	
		button {
			padding: 15px 50px;
			margin-right: 0px;
			margin-left: 14%;
			margin-bottom: 0px;
			font-size: 1.2rem;
			white-space: nowrap;
			order: 2;
		}
	}
	
	#signup-content span.helper {
		order: 20;
	}
}
@include media-breakpoint-up(xl) {
	.external-login {
		margin-left: 276px;
		width: 827px;
	}
	
	#login-content, #signup-content, #forgotten-content {
		
		aside {
			padding: 65px 24px 0;
			margin-bottom: 60px;
			margin-right: 36px;
			width: 240px;
		}
	}
	
	.form-contents {
		width: 827px;
	}
}

#profile-contents, #settings-contents, #profile-unverified {
	h2 {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		margin: 20px;
		text-align: center;
		font-size: 1.5rem;
		
		> div {
			display: flex;
			flex-direction: column;
		    justify-content: center;
			margin-left: auto;
			margin-right: -20px;
			background-color: rgba(0,0,0,.8);
			border: solid 1px #333;
			font-size: 1rem;
			text-align: left;
			
			> * {
				display: block;
				
				&:first-child {
					margin: auto 15px 5px;
				}
				
				&:last-child {
					margin: 5px 15px auto;
				}
			}
		}
		
		> span {
			margin: 20px 0px 0px;
			order: 20;
			
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}
	
	.panel {
		margin: 0px 20px 20px;
		padding: 7px 10px;
	}
}

#profile-contents, #profile-unverified {
	.panel {
		h3 {
			font-size: 1.1rem;
			color: $feral-orange;
		}
		
		p {
// 			font-size: .7rem;
		}
		
		ul {
			list-style: none;
			padding: 0px; margin: 0px;
			display: flex;
			flex-direction: column;
// 			font-size: .7rem;
			
			img {
				margin-right: 10px;
			}
			
			span {
				display: flex;
				flex-direction: column;
			}
			
			li {
				display: flex;
				align-items: center;
				border: solid 1px #333;
				padding: 5px; margin-bottom: 10px;
				background-color: rgba(0,0,0,.5);
			}
		}
		
		table {
			width: 100%;
			color: #FFF;
			border-collapse: separate;
			border-spacing: 0px 5px;
// 			font-size: .7rem;
			
			td, th {
				border-top: solid 1px #333;
				border-bottom: solid 1px #333;
				padding: 5px;
				background-color: rgba(0,0,0,.5);
			}
			
			td:first-child, th:first-child {
				border-left: solid 1px #333;
			}
			
			td:nth-last-child(2), th:nth-last-child(2) {
				border-right: solid 1px #333;
			}
			
			td:last-child, th:last-child {
				display: none;
			}
			
			td:first-child {
				a {
					display: flex;
					align-items: center;
					color: #FFF;
				
					img {
						flex: 0 0 auto;
						margin-right: 10px;
					}
				}
			}
		}
		
		nav {
			display: flex;
			align-items: flex-end;
			
			a {
				position: relative;
				padding: 3px 5px;
				background-color: rgba(0,0,0,.5);
				border: solid 1px #333;
				border-bottom: none;
				margin-right: 5px;
				color: #FFF;
				
				&:nth-child(1) { z-index: 3; }
				&:nth-child(2) { z-index: 2; }
				&:nth-child(3) { z-index: 1; }
				
				&.active {
					color: $feral-orange;
					z-index: 4;
				}
				
				span:last-child {
					display: none;
				}
			}
			
			form {
				display: block;
				margin: 0px;
				margin-left: auto;
			}
			
			input {
				padding: 3px 5px;
				border: none;
// 				font-size: .7rem;
				background-color: rgba(255,255,255,.4);
				color: #FFF;
				
				&::placeholder {
					color: #FFF;
				}
			}
		}
	}
	
	#users_holder {
		border: solid 1px #333;
		background-color: rgba(0,0,0,.5);
		padding: 10px;
		
		ul {
			display: none;
			
			&.active {
				display: flex;
			}
		}
		
		li {
			display: flex;
			align-items: center;
			background-color: rgba(255,255,255,.2);
			
			.profile {
				display: flex;
				align-items: center;
// 				max-width: 67%;
			}
			
			span {
				display: block;
				max-width: calc(100% - 50px);
				em {
					max-width: 100%;
					text-overflow: ellipsis;
					display: inline-block;
					overflow: hidden;
					font-style: normal;
					word-break: break-word;
					padding-right: 10px;
				}
				small {
					display: inline-block;
					width: 100%;
					&:before { content: '('; }
					&:after { content: ')'; }
				}
			}
			
			.buttons {
				display: flex;
				flex-direction: column;
				margin-left: auto;
				
				button {
					width: 100%;
				}
				
				form {
					display: inline;
					margin: 0px;
				}
			}
			
			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
	
	&:after {
		display: block;
		content: '';
		width: 100%; height: 1px;
		clear: both;
	}
}

@include media-breakpoint-up(md) {
	#profile-contents, #settings-contents, #profile-unverified {
		h2 {
			align-items: center;
			
			img {
				margin-right: 20px;
			}
		
			> div {
				padding: 20px;
				padding-right: 40px;
				order: 2;
			}
		
			> span {
				margin: 0px;
				order: 1;
			}
		}
		
		.panel {
			padding: 20px 25px;
		}
	}
	#profile-contents, #profile-unverified {
		.panel {
			h3 {
				font-size: 1.3rem;
			}
		
			p {
// 				font-size: .9rem;
			}
			
			ul {
				margin: 0px -10px;
				flex-direction: row;
				flex-wrap: wrap;
// 				font-size: .9rem;
			
				li {
					margin: 10px;
					width: calc(50% - 20px);
				}
			}
			
			table {
// 				font-size: .9rem;
			
				td:nth-last-child(2), th:nth-last-child(2) {
					border-right: none;
				}
			
				td:last-child, th:last-child {
					display: table-cell;
					border-right: solid 1px #333;
				}
			}
			
			nav {
				display: flex;
				align-items: flex-end;
			
				a {
					padding: 10px;
					padding-right: 40px;
					border-top-left-radius: 4px;
					margin-right: -10px;
					-webkit-clip-path: polygon(0px 0px, calc(100% - 40px) 0px, 100% 100%, 0px 100%);
					clip-path: polygon(0px 0px, calc(100% - 40px) 0px, 100% 100%, 0px 100%);
					
					&:after {
						position: absolute;
						content: '';
						display: block;
						top: 0px; right: -1px; bottom: 0px;
						background-color: #333;
						width: 40px;
						-webkit-clip-path: polygon(0px 0px, 1px 0px, 100% 100%, calc(100% - 1px) 100%);
						clip-path: polygon(0px 0px, 1px 0px, 100% 100%, calc(100% - 1px) 100%);
					}
					
					span:last-child {
						margin-left: 10px;
						display: inline-block;
					}
				}
			
				input {
// 					font-size: .9rem;
					padding: 6px 10px;
					margin-bottom: 10px;
				}
			}
		}
	}
	
	#profile-contents #users_holder li:last-child {
		margin-bottom: 10px;
	}
}

@include media-breakpoint-up(lg) {
	
	#profile-contents, #profile-unverified {
		.panel {
			
			ul {
			
				li {
					margin: 10px;
					width: calc(33.3333% - 20px);
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	
	#profile-contents, #profile-unverified {
		.panel {
			
			ul {
			
				li {
					margin: 10px;
					width: calc(25% - 20px);
				}
			}
		}
	}
}

#faqs-content, #terms-content {
	.panel {
		padding: 30px;
		
		&:first-child {
			text-align: left;
		}
		
		h1 {
			margin: 0px 30px 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			color: $feral-orange;
			
			img {
				max-width: 152px;
			}
			
			span {
				display: inline-block;
				padding: 20px 0;
			}
		}
	}
	.row {
		.panel {
			&:first-child {
				text-align: center;
			}
		}
	}
}

#faqs-content {
	.panel {
		border: 0;
		&:first-child:hover {
			background-color: rgba(0, 0, 0, 0.7);
			&::before {
				background-color: #4d4d4d;
			}
		}
		nav {
			padding: 30px 10px 20px;
			border-top: solid 2px $feral-orange;
			
			a {
				display: block;
				padding-bottom: 5px;
			}
		}
		
		ul {
			margin: 0px; padding: 0px;
			list-style: none;
			
			li {
				padding-top: 40px;
				clear: both;
				break-inside: avoid;
				
				li {
					padding-top: .2em;
					margin-left: 1em;
				}
			}
			
			h4 {
				padding: 3px 7px;
				border-bottom: solid 2px $feral-orange;
				color: $feral-orange;
				a {
					float: right;
				}
			}
			
			p {
				margin: 10px 20px;
			}
		}
	}
}

#terms-content {
	h2 {
		padding: 3px 7px;
		border-bottom: solid 2px $feral-orange;
	}
			
	p {
		margin: 10px 20px;
	}
}

@include media-breakpoint-up(sm) {
	#faqs-content {
		flex-wrap: wrap;
		
		.panel {
			
			h1 {
				flex-direction: row;
				margin-bottom: 30px;
				img {
					margin-right: 30px;
					margin-left: -30px;
				}
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	#login-whynot, #faqs-content, #terms-content {
		flex-direction: row;
		.row {
			flex-wrap: nowrap;
			align-content: stretch;
			width: 100%;
			.panel {
				margin: 10px; padding: 40px 30px;
				width: calc(50% - 0px);
// 				width: 600px;
				display: flex;
				flex-grow: 1;
				text-align: left;
				flex-wrap: nowrap;
				h3 {
					font-size: $h3-font-size;
					
					a {
						display: inline-block;
					}
				}
			}
		}
	}
	
	#faqs-content {
		flex-wrap: wrap;
		
		.panel {
			&:nth-child(n+2) {
				width: calc(50% - 40px);
			}
						
			nav {
				column-count: 2;
				column-gap: 3vw;
			}
		}
	}
	
	#terms-content {
		flex-wrap: wrap;
		justify-content: space-around;
		
		.panel {
			&:nth-child(n+2) {
				width: calc(33.33333% - 40px);
			}
			
			h1 {
				flex-direction: row;
	
				img {
					margin-right: 30px;
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	#faqs-content {
	
		nav {
			column-gap: 40px;
		}
		
		ul {
			column-count: 2;
			column-gap: 40px;
		}
	}
}

section > nav {
	position: relative;
	margin: 10px 0px; padding: 5px 15px;
	width: 100%;
	background-color: rgba(0,0,0,.6);
// 	font-size: .9rem;
	width: calc(100% + 40px);
	text-align: left;
	
	a {
		color: #FFF;
		
		&:last-child {
			color: $feral-orange;
		}
	}
}

@include media-breakpoint-up(md) {
	section > nav {
		display: inline-block;
		padding: 10px 30px 10px 20px;
		width: auto;
		background-color: transparent;
		background-image: url(/images/backgrounds/triangles/navigation.svg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center right;
	}
}

#achievements-content {
	h2 {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		margin: 20px;
		text-align: center;
		font-size: 1.5rem;
		
		> div {
			display: flex;
			flex-direction: column;
		    justify-content: center;
			margin-left: auto;
			margin-right: -20px;
			background-color: rgba(0,0,0,.8);
			border: solid 1px #333;
			font-size: 1rem;
			text-align: left;
			
			a {
				display: block;
				
				&:first-child {
					margin: auto 15px 5px;
				}
				
				&:last-child {
					margin: 5px 15px auto;
				}
			}
		}
		
		> span {
			margin: 20px 0px 0px;
			order: 20;
		}
	}
	
	.panel {
		margin: 20px 10px;
		
		h4 {
			display: flex;
			align-items: center;
			padding: 5px 10px;
			
			em {
				font-weight: bold;
				font-style: normal;
				color: $feral-orange;
			}
			
			em + button {
				margin-left: auto;
			}
			
			span {
				margin-left: auto;
			}
			
			.progress, button {
				margin-left: 20px;
				display: none;
			}
			
			button.active {
				color: $feral-orange;
			}
			
			.progress {
				position: relative;
				padding: 5px 0px;
				flex: 1 1 auto;
				text-align: center;
				border-radius: 3px;
				background-color: #333;
				
				.bar {
					position: absolute;
					top: 0px; left: 0px;
					background-color: #FFF;
					height: 100%;
					mix-blend-mode: difference;
				}
			}
		}
		
		ul {
			margin: 0px; padding: 5px 10px;
			list-style: none;
// 			font-size: .8rem;
			
			&#locked-achievements {
				strong {
					color: $feral-orange;
				}
				
				img, .icon, p {
					opacity: .5;
				}
			}
			
			.icon {
				flex: 0 0 auto;
				width: 40px; height: 40px;
				line-height: 40px;
				text-align: center;
				background-color: #333;
				
				&:before {
					content: '+';
				}
			}
			
			li {
				display: flex;
				align-items: center;
				background-color: rgba(0,0,0,.5);
				border: solid 1px #333;
				border-radius: 3px;
				margin-bottom: 10px;
				padding: 5px;
				@include panel;
				
				p {
					margin: 0px 10px;
					
					strong {
						display: block;
					}
				}
				
				small {
					align-self: start;
					margin-left: auto;
				}
			}
		}
	}
	
	&:after {
		display: block;
		content: '';
		width: 100%; height: 1px;
	}
}

@include media-breakpoint-up(md) {
	#achievements-content {
		h2 {
			align-items: center;
			
			img {
				margin-right: 20px;
			}
		
			> div {
				padding: 20px;
				padding-right: 40px;
				order: 2;
			}
		
			> span {
				margin: 0px;
				order: 1;
			}
		}
		
		.panel {
			margin: 20px; padding: 10px;
		
			h4 {
				span {
					margin-left: 20px;
				}
			
				.progress, button {
					display: block;
				}
			}
			
			ul {
				font-size: 1rem;
				
				li {
					padding: 10px 15px;
				}
			}
		}
	}
}

#settings-contents {
	> nav {
		width: 100%;
	}
	
	h2 > span {
		display: flex;
		flex-direction: column;
		text-align: left;
		width: 100%;
		@include media-breakpoint-up(md) {
			width: auto;
		}
		em {
			font-size: 90%;
			font-weight: 300;
		}
		
		span, strong {
			font-size: 75%;
		}
		
		strong:first-child {
			font-size: 100%;
		}
	}
	
	h3 {
		font-size: 1.5rem;
		color: $feral-orange;
	}
	
	h4 {
		font-size: 1.2rem;
		color: $feral-orange;
	}
	
	p {
// 		font-size: .9rem;
	}
	
	input[type=file] {
		width: 100%;
	}
	
	form {
		margin: 0px;
		
		small {
			display: block;
			font-style: italic;
			margin-bottom: 5px;
		}
	}
	
	#account-communication {
		h5 {
			margin: 1em 0 .5em;
		}
		button {
			margin-top: 1em;
		}
	}
		
	&:after {
		display: block;
		content: '';
		width: 100%; height: 1px;
		clear: both;
	}
	
	h4 {
		padding-bottom: 2px;
		
		&.open {
			border-bottom: solid 2px #FFF;
		}
	}
	
	.opener {
		padding: 0px 10px;
	}
	
	label.checkable {
		flex-direction: row;
		background: none;
		border: none;
		color: #FFF;
		width: 100%;
		
		span {
			padding: 0px;
			flex: 0 0 auto;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-left: auto;
			border: solid 2px;
			border-radius: 100%;
			width: 26px; height: 26px;
			background-color: #82609d;
			font-size: 80%;
			
			&[data-title] {
				position: relative;
				cursor: help;
				
				&:after {
					display: none;
					content: attr(data-title);
					position: absolute;
					right: -10px; bottom: calc(100% + 5px);
					font-family: $font-family-base;
					font-weight: normal;
					width: 200px;
					white-space: normal;
					z-index: 2;
					background-color: rgba(0,0,0,.9);
					border: solid 1px;
					border-radius: 5px;
					padding: .5em .6em;
					font-size: 1rem;
					line-height: 1.2rem;
				}
				
				&:hover:after {
					display: block;
				}
				
				&::before {
					position: relative;
					top: 1px;
				}
			}
		}
	}
	
	.avatars {
		margin: 0px 0px 10px; padding: 0px;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		
		li.group {
			width: 100%;
			margin: 10px 0px 5px;
		}
		
		input {
			display: none;
		}
		
		label {
			position: relative;
			width: auto;
			border: none;
			background: none;
			
			a {
				display: flex;
				position: absolute;
				top: 2px; right: 2px;
				width: 30%; height: 30%;
				align-items: center;
				justify-content: center;
				color: $danger;
			}
			
			&.checked {
				border: solid 2px #C00;
			}
		}
		
		+ button {
			margin-bottom: 30px;
		}
	}
	
	br {
		display: none;
	}
}

#profile-unverified, #settings-contents {
	button {
		margin: 0px 0 20px;
		width: 100%; height: calc(#{$font-size-base} + 27px);
		border: none;
		border: solid 2px rgba(0,0,0,.5);
		border-radius: 5px;
		background-color: #82609d;
		color: #FFF;
		transition: background-color .2s linear, border-color .2s linear;
		&:hover {
			background-color: black;
			border-color: white;
		}
	}
	
	.buttoned {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
		
		label {
			margin: 0px 0px 10px;
		}
		
		button {
			margin: 0px;
		}
	}
}

@include media-breakpoint-up(md) {
	#settings-contents {
		> nav {
			width: auto;
		}
		
		form label.checkable {
			width: 500px;
			margin-bottom: 10px;
			
			span {
				margin-left: 10px;
			}
		}
		
		br {
			display: block;
		}
		
		.avatars {
			justify-content: flex-start;
			
			li {
				margin-right: 10px;
			}
		}
		
		#account-communication form:nth-child(4) {
			
		}
	}
	
	#profile-unverified, #settings-contents {
		
		button {
			width: auto;
			min-width: 150px;
		}
	
		.buttoned {
			flex-direction: row;
		
			label {
				margin: 0px;
			}
		
			button {
				margin-left: 10px;
			}
		}
	}
}