
.row {
// 	@include fade-in-up(2s, 2s);
	margin: 0 auto;
	max-width: 1200px;
	color: white;
	position: relative;
// 	z-index: 10;
	flex-wrap: wrap;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+38,0.65+61,0+100 */
background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 38%, rgba(0,0,0,0.65) 61%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 38%,rgba(0,0,0,0.65) 61%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 38%,rgba(0,0,0,0.65) 61%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */

}

#top-header {	
	#jarallax-container-0 {
		div {
// 			height: 155% !important;
			background-position: left 55% !important;
		}
	}
}

@include media-breakpoint-up(md) {
	#top-header {	
		#jarallax-container-0 {
/*
			div {
				height: 35vh !important;
				background-position: right 23% !important;
			}
*/
		}
	}
}
#notfound-content {
	display: flex;
	flex-direction: column;
	color: #000;
	min-height: 40vh;
	padding: 0 .5em;
	justify-content: center;
	
	&:before {
		background-image: none;
		@include gradient-y(rgba(0,0,0,0.8), transparent);
		width: 100%; height: 100%;

	}
	
	form {
		display: flex;
		margin: 1em auto 5em;
		width: 100%;
		max-width: 610px;
		font-size: 1rem;
		@include media-breakpoint-up(sm) {
			font-size: 1.3rem;			
		}
// 		background: white;
// 		border-radius: 3px;
	}
	
	input {
		padding: .5em .7em;
		width: 100%;
		
		border: none;
		border-right: solid 2px #000;
		color: $feral-orange;
		border-radius: 4px 0px 0px 4px;
	}
	
	button {
		padding: .5em .7em;
		border: solid 2px #fff;
		border-left: none;
		background-color: $feral-orange;
		color: #fff;
		transition: background .2s linear;
		border-radius: 0px 4px 4px 0px;
		&:hover {
			background-color: black;
			border: solid 2px $feral-orange;
			border-left: none;
		}
		@include media-breakpoint-up(sm) {
			padding: .5em 1.8em;
		}
	}
	
	p {
		margin: .49em .7rem .49em;
		text-shadow: 0px 0px 1.5em black, 0px 0px .8em black, 0px 0px 1.5em black, 0px 0px .8em black;
	}
}

// .about-text {
// 	position: relative;
// 	margin: 0 1rem;
	
	
	
	h3 {
		margin: 1.5em 0 1.5em; 
		font-size: 6vw;
		text-align: center;
// 		justify-content: center;
		width: 100%;
		text-shadow: 0px 0px 1.5em black, 0px 0px .8em black, 0px 0px 1.5em black, 0px 0px .8em black;
		span {
			display: block;
			font-size: 3.55em;
// 			transform: translateX(.005em);
			font-weight: 700;
			line-height: .8em;
		}
	}
	
	#helpfullinks li {
		text-shadow: 0px 0px 1.5em black, 0px 0px .8em black, 0px 0px 1.5em black, 0px 0px .8em black, 0px 0px 1.5em black, 0px 0px .8em black, 0px 0px 1.5em black, 0px 0px .8em black;
		position: relative;
		@include media-breakpoint-down(xs) {
			list-style: none;
			&::before {
						
				@include fa-icon("\f0da");
				position: absolute;
				left: -1em;
				top: .25em;
			}
			
		}
		
		a {
			transition: color .2s linear, border-color .2s linear;
			&:hover {
				text-decoration: none;
				color: white; 
			}
		}
	}
	
	.row > div:last-of-type {
		width: 100%;
	}
	
	#jarallax-container-1 {
		div {
			background-position: 10% center !important;
		}
	}
// }

@include media-breakpoint-up(sm) {
	#notfound-content {
		.row {
			flex-direction: row;
			flex-wrap: wrap;
			h3 {
				font-size: 3vw;
// 				float: left;
				margin-right: 1.2em;
				min-width: 36vw;
				max-width: 36vw;
			}
			> div, > div div {
				display: flex;
				justify-content: center;
				align-content: center;
			}
			> div div {
				flex-wrap: wrap;
				p {
					width: 100%;
					font-size: 2.3vw;
				}
			}
			div#helpfullinks, div#helpfullinks ul, > div:last-of-type {
				text-align: center;
				justify-content: center;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
			}
			div#helpfullinks ul {
				list-style: none;
				margin: 1.2em auto;
				padding: 0;
				li {
					min-width: 20%;
					margin: .5em;
					a {
						display: flex;
						width: 100%;
						height: 3.5em;
						justify-content: center;
						align-content: center;
						align-items: center;
						border: 2px solid $white;
						background: black;
						border-radius: 3px;
						font-size: 1.2em;
						text-transform: uppercase;
					}
				}
				
			}
			
			> div:last-of-type {
				margin-top: 2em;
			}
		}
	}
}
	
@include media-breakpoint-up(md) {
	#notfound-content {
		flex-direction: row;
		.row {
			
// 			.about-text {
			flex-grow: 3;
			margin: 0rem 1rem 0rem;
			padding: 1.5rem 30px 4rem;
			font-size: 1.8vw;
			h3 {
				font-size: 4vw;
// 				margin: 2rem auto .7em;
			}
			
			p {
				font-size: 1.2em;
// 				margin: 0px .7em 1em;
				width: 100%;
			}
			
			> div div {
				p {
					font-size: 3.1vw;
				}
			}
// 			}
		}
		
	}
	
}

@include media-breakpoint-up(lg) {
	#notfound-content {
		.row {
			
			
// 			.about-text {
				font-size: 1.8vw;
				text-shadow: $medium-whte-text-shadow;
/*
				h3 {
					font-size: 3vw;
					margin: 1em auto .7em;
				}
*/
				
				p {
// 					font-size: 1em;
// 					margin: 0px .7em 1em;
					font-weight: 300;
					-webkit-font-smoothing: subpixel-antialiased;
				}
// 			}
			div#helpfullinks ul {
				li {
					a {
// 						height: 3.5em;
					}
				}
			}
		}
		
	}
	
}

@include media-breakpoint-up(xl) {
	#notfound-content {
		.row {
			
			
// 			.about-text {
				font-size: 22px;
				h3 {
					font-size: 56px;
					
				min-width: 437px;
				max-width: 437px;
				}
// 			}
			> div div {
				flex-wrap: wrap;
				p {
					width: 100%;
					font-size: 43px;
				}
			}
			
			div#helpfullinks ul {
				li {
					a {
// 						height: 4em;
					}
				}
			}
		}
		
	}
}	