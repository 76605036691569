#minisite-content.devices, #minisite-content.requirements {

	#minisite-view h3 {
		font-size: 1.17rem;
		margin-top: 1.5em;
	}
		
	#minisite-view nav {
		padding: 10px 0 10px;
		text-align: center;
		margin: 1em auto 1em; 
		a {
			padding: 0;
			margin: 7px .5% 5px;
			width: auto;
			position: relative;
			padding: .4em 1.3em .4em;
			display: inline-block;
			font-size: 1.8em;
			line-height: none;
			text-decoration: none;
			transition: color .2s linear, background .2s linear, opacity .2s linear;	
			
			&:hover {
				opacity: 1;
			}
			
			&.active {
				opacity: 1;
			}
			
			&:last-child:first-child {
				display: none;
			}
		}
	}	
	
	#requirements-tabs {
		padding: 0 0;
		#tab-ios {
			.handle {
				display: none;
			}
			.devices_holder {
				padding: 1em;
				font-size: 1.1em;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				.devices {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					flex: 1 1 100%;
					justify-content: space-around;
					
					@media only screen and (min-width: 768px) {
						justify-content: center;
						padding: 0 8%;
					}
					h4 {
						flex: 1 1 100%;
						padding-top: 1em;
						margin-top: 1em;
						span {
							text-transform: lowercase;
						}
					}
					
					.devices_list {
						display: flex;
						flex: 0 1 48%;
						justify-content: space-between;
						align-items: stretch;
						align-content: stretch;
						flex-wrap: wrap;
						flex-direction: column;
						@media only screen and (min-width: 507px) {
							flex: 0 1 32%;
						}
						@media only screen and (min-width: 768px) {
							flex: 0 1 25%;
							margin: 0 1%;
						}
						&.ipad .device-image {
							min-height: 180px;		
							@media only screen and (min-width: 768px) {
								min-height: 170px;
							}				
							img {
								transform: scale(1.4);
							}
						}
						.device-image {
							justify-content: center;
							align-items: flex-end;
							display: flex;
							flex: 0 0 130px;
							min-height: 180px;
							@media only screen and (min-width: 768px) {
								min-height: 170px;
							}
							img {
								transform: scale(1.6);
								transform-origin: center bottom;
								display: flex;
								
								&[src$="67.svg"] {
									width: 47px;
									height: 96px;
								}
								&[src$="65.svg"] {
									width: 44px;
									height: 90px;
								}
								
								&[src$="61.svg"] {
									width: 42px;
									height: 84px;
								}
								
								&[src$="58.svg"] {
									width: 38px;
									height: 81px;
								}
								
								&[src$="54.svg"] {
									width: 35px;
									height: 76px;
								}
								
								&[src$="55.svg"] {
									width: 46px;
									height: 93px;
								}
								
								&[src$="47.svg"] {
									width: 40px;
									height: 82px;
								}
								
								&[src$="40.svg"] {
									width: 38px;
									height: 80px;
								}
								
								&[src$="35.svg"] { // not set, not used for anything SO FAR
									width: 0;
									height: 0;
								}
								
								&[src$="129.svg"] {
									width: 78px;
									height: 111px;
								}
								
								&[src$="new_129.svg"] {
									width: 77px;
									height: 101px;
								}
								
								&[src$="110.svg"] {
									width: 64px;
									height: 89px;
								}
								
								&[src$="105.svg"] {
									width: 62px;
									height: 89px;
								}
								
								&[src$="97.svg"] {
									width: 60px;
									height: 88px;
								}
								
								&[src$="79.svg"] {
									width: 48px;
									height: 71px;
								}
							}
						}
						ul {
							padding: 0;
							list-style: none;
							flex: 1 1 auto;
							align-items: flex-start;
							justify-content: center;
							li {
								text-align: center;
								justify-content: center;
								list-style: none;
								margin: .3em 0 0;
								padding: .5em .7em;
								display: flex;
								flex: 1 1 100%;
								line-height: 1.4em;
								font-size: .8em;
								flex-wrap: wrap;
								span {
									font-size: .8em;
									flex: 1 1 100%;
								}
								
								@media only screen and (min-width: 768px) {
									font-size: 1em;
								}
								&.device-iPad_Pro_11_4gb + li.device-iPad_Pro_11_6gb,
								&.device-iPad_Pro_12_9_3_4gb + li.device-iPad_Pro_12_9_3_6gb {
									display: none;
								}
							}
						}
					}
				}
			}
		}
		#tab-android {
			.handle {
				display: none;
			}
			.devices_holder {
				padding: 1em;
				font-size: 1.1em;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				.devices {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					flex: 1 1 100%;
					justify-content: space-around;
					
					@media only screen and (min-width: 768px) {
						justify-content: center;
						padding: 0 8%;
					}
					
					.devices_list {
						text-align: center;
						list-style: none;
						padding: .5em;
						box-sizing: border-box;	
						line-height: 1em;
						display: inline-block;
						flex: 1 1 90%;
						justify-content: space-between;
						flex-wrap: wrap;
						flex-direction: column;
						margin: 0.5em auto 0em;
						@media only screen and (min-width: 507px) {
							flex: 0 1 48%;
						}
						h5 {
							border: none;
							background: none;
							box-shadow: none;
							width: auto;
							font-size: 1.1em;
							padding: 0em .2em .8em;
							font-weight: bold;
							margin: .4em .5em .7em;
						}
						ul {
							width: 100%;
							margin: 0;
							padding: 0;
							list-style: none;
							li {
								width: 100%;
								display: block;
								font-size: 1em;
								margin: 1em 0;
							}
						}
					}
				}
			}
		}
	}
}